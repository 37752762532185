import { ApiPath } from "enums";
import { Get, Post } from "utils";

export const getNonFuelTransaction = async ({ stationId, ...query }) => {
  const res = await Get(`${ApiPath.NonFuelTransactions}/${stationId}`, { ...query });
  return res.data;
};

export const voidPaymentNonFuel = async ({ ...body }) => {
  const res = await Post(`ms-fleet/merchant/payment/void`, body);
  return res.data;
};
