import React, { useContext } from "react";
import { StationContext } from "../../contexts";
import locale from "../../localization";
import { Intro } from "../../components/commons";
import Title from "../../components/commons/title/title";
import QrGenerator from "../../components/commons/qr-generator/qr-generator";
import QrDocumentGenerator from "../../components/commons/qr-generator/qr-document-generator";

import styles from "./generate-qr.module.scss";

const GenerateQrModule = () => {
  const { station } = useContext(StationContext);
  const availableProducts = Object.entries(station.stationProduct)
    .filter(([, id]) => id === true)
    .map(([key, _]) => key);
  let lubricantStations = [];
  if (station.allMerchants) {
    lubricantStations = station.allMerchants.filter(item => item.businessName === "Lubricants");
  }
  const dataList = availableProducts.filter(item => item !== "lubes").map((data) => {
    const qrObject = {
            fuelType: data,
            stationId: `${station.stationCode}`,
            stationName: station.name,
          };
    let desc;
    if (data === "lubes") {
      desc = "Lubricants";
    } else if (data === "gas91") {
      desc = "Gas 91";
    } else if (data === "gas97") {
      desc = "Gas 97";
    } else if (data === "gas95") {
      desc = "Gas 95";
    } else if (data === "diesel") {
      desc = "Diesel";
    }
    return {
      qr: qrObject,
      description: desc,
    };
  });
  if (station.merchant) {
    let qr = {
      qr: {
        merchantId: lubricantStations.length === 0 ? station.merchant.merchantId : lubricantStations[0].merchantId,
        productType: "lubes"
      },
      description: "Lubricants"
    };
    dataList.push(qr);
  }

  const QrCodeRow = () => {
    return dataList.map((data, index) => (
      <QrGenerator qrData={data.qr} description={data.description} useTemplate={false} />
    ));
  };
  const TemplatedQrCodes = () => {
    return dataList.map((data, index) => (
      <QrGenerator qrData={data.qr} description={station.name} useTemplate={true} />
    ));
  };

  const sectionBuilder = (name, button) => {
    return (
      <div className={styles.intro}>
        <div>
          <Title small className={styles.title} black={true}>
            {name}
          </Title>
        </div>
        <div>{button}</div>
      </div>
    );
  };

  return (
    <>
      <Intro title={locale.generateQrCode} />
      {sectionBuilder(
        locale.generic,
        <QrDocumentGenerator qrDataList={dataList} description={station.name} useTemplate={false} />
      )}
      <div className={styles.generateQrRow}>
        <QrCodeRow />
      </div>
      {sectionBuilder(
        locale.templated,
        <QrDocumentGenerator qrDataList={dataList} description={station.name} useTemplate={true} />
      )}
      <div className={styles.generateQrRow}>
        <TemplatedQrCodes />
      </div>
    </>
  );
};

export default GenerateQrModule;
