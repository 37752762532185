import { Intro } from "components/commons";
import { StationContext } from "contexts";
import { useExport } from "hooks";
import React, { useContext } from "react";
import TransactionHistoryFilter from "./transaction-history-filter";
import { transactionHistoryState } from "./transaction-history-filter.state";
import { getTransactionHistoryAwardPoints, generateStationReportLoyalty } from "apis";
import useDataTable from "hooks/useDataTable";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import { mapDataToList, mapFilterToRequest } from "./transaction-history.mapper";
import { transactionHistoryColumns } from "./transaction-history-columns";
import { SuccessModal } from "components/modals";
const TransactionHistoryModule = ({ title }) => {
  const { station } = useContext(StationContext);
  const { stationId } = station;

  const { filter, search, table } = useDataTable({
    api: { api: getTransactionHistoryAwardPoints, params: { stationId } },
    filter: { initialState: transactionHistoryState(), mapper: mapFilterToRequest },
    table: {
      key: "pointsHistory",
      mapper: (pointsHistory) => mapDataToList({ pointsHistory }),
      columns: transactionHistoryColumns,
    },
  });

  const exports = useExport({
    api: generateStationReportLoyalty,
    reportType: "vip-award-points",
    hasModal: true,
    mappedFilterState: {
      ...filter?.mappedFilterState,
      stationId,
    },
  });

  return (
    <div>
      <div>
        <Intro />
      </div>
      <TransactionHistoryFilter {...filter} {...search} {...exports} />
      <DataTableV2 {...table} paginationV2={true} />
      <SuccessModal {...exports?.successModalComponent} />
    </div>
  );
};

export default TransactionHistoryModule;
