import classNames from "classnames";
import { Modal, ActionButton, TextField, Field } from "components/commons";
import locale from "localization";
import React, { useEffect } from "react";
import styles from "./purchase-order-modal.module.scss";

const PurchaseOrderModal = ({
  close,
  active,
  onSubmit,
  title,
  description,
  fields,
  onChangePurchaseOrderNoCb,
  modifyField,
}) => {
  useEffect(() => {
    if (active) {
      modifyField(fields?.purchaseOrderNo.name, { value: "" });
    }
    //eslint-disable-next-line
  }, [active]);
  return (
    <Modal active={active} title={title} close={close} className={styles.modal}>
      <div
        className={classNames(styles.content, {
          [`${styles.noSpace}`]: !description,
        })}
      >
        {description}
        <Field
          childrenStyle={{
            width: "100%",
            padding: "0 20%",
          }}
          style={{
            display: "flex",
            alignItems: "start",
          }}
        >
          <div
            style={{
              marginTop: "10px",
            }}
          >
            <TextField
              {...fields?.purchaseOrderNo}
              onChange={onChangePurchaseOrderNoCb}
            ></TextField>
          </div>
        </Field>
      </div>
      <ActionButton
        center
        items={[
          {
            text: locale.cancel,
            onClick: () => {
              close();
            },
          },
          {
            onClick: () => {
              onSubmit(fields?.purchaseOrderNo.value);
            },
            text: locale.yesContinue,
            disabled: fields?.purchaseOrderNo?.value?.length === 0,
          },
        ]}
      />
    </Modal>
  );
};

export default PurchaseOrderModal;
