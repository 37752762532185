import { TextField, Dropdown, Field, Title, Text } from "components/commons";
import React, { useMemo, useState, useContext, useCallback, useRef, useEffect } from "react";
import { initialState as formState } from "./vip-award.state";
import { useForm, useModal, useApi } from "hooks";
import { InputAmount } from "components/field";
import styles from "./vip-award.module.scss";
import { ErrorCode, Products } from "enums";
import { prettifyProduct } from "utils/pretty.utils";
import { capitalize, isNumber } from "utils/text.utils";
import VipCardDetails from "../vip-card-details";
import { SuccessActivateAccount } from "images";
import locale from "localization";
import { StationContext } from "contexts";
import { awardPoints, validateAwardPoints } from "apis";
import { formatAmount, roundUpTwoDecimal, parseNumber, roundUp } from "utils";
import SuccessModalForm from "./success-modal/success-modal-form";

const VipAwardPointsModule = (props) => {
  const [vipCampaignDetails, setVIPCampaignDetails] = useState({
    amount: 0,
    points: 0,
    hasVIPCampaign: false,
  });
  const awardPointsRef = useRef();
  const { station } = useContext(StationContext);
  const { stationId } = station;
  const { location } = props;
  const [products] = useState([
    {
      value: Products.Diesel,
      label: prettifyProduct(Products.Diesel),
    },
    {
      value: Products.Gas91,
      label: prettifyProduct(Products.Gas91),
    },
    {
      value: Products.Gas95,
      label: prettifyProduct(Products.Gas95),
    },
    {
      value: Products.Gas97,
      label: prettifyProduct(Products.Gas97),
    },
    {
      value: Products.Lubserv,
      label: prettifyProduct(Products.Lubserv),
    },
    {
      value: Products.Lubricants,
      label: prettifyProduct(Products.Lubricants),
    },
  ]);

  const vipConversionAwardPoints = useApi({
    api: awardPoints,
    handleOwnError: {
      badrequest: true,
    },
    modalError: false,
  });

  const validateVipConversionAwardPoints = useApi({
    api: validateAwardPoints,
    handleOwnError: {
      badrequest: true,
    },
    modalError: false,
  });

  const loadingVIPConversionAwardPoints = vipConversionAwardPoints.loading;
  const loadingVIPValidateConversionPoints = validateVipConversionAwardPoints.loading;

  const form = useMemo(() => {
    let initialState = {};
    return formState(initialState);
  }, []);

  const {
    fields,
    modifyField,
    clearForm,
    applyFieldErrors,
    submitForm,
    getFormValues,
    modifyForm,
  } = useForm({
    initialState: form,
  });

  const successModal = useModal();

  const params = getFormValues();
  const submit = async () => {
    let requestUrl = vipConversionAwardPoints;
    let requestParams = {
      stationId,
      amount: roundUpTwoDecimal(params?.purchaseAmount),
      productCode: params?.productType,
      transactionId: params?.orNumber?.toString(),
      awardType: "station",
      userId: location?.state?.user?.userId ?? "",
    };

    if (
      location?.state?.isMobileNumber &&
      (location?.state?.user?.vipType === "biz" || location?.state?.user?.vipType === "public")
    ) {
      requestParams.mobileNumber = location.state.mobileNumber;
    } else {
      requestParams.cardNumber = location?.state?.cardNumber;
    }

    const applyPurchaseAmountError = (message, error) => {
      applyFieldErrors(
        {
          purchaseAmount: message,
        },
        {
          purchaseAmount: {
            value: params.purchaseAmount,
            error,
          },
        }
      );
    };

    try {
      const result = await requestUrl.request({ ...requestParams });
      if (result) {
        clearForm();
        successModal.show({
          contentHighlight: [
            <b>
              {parseFloat(parseNumber(result?.pointsHistory?.points ?? 0))} {locale.vipPoints}
            </b>,
            <b>{capitalize(location?.state?.vipFullName)}</b>,
            <b>
              {locale.vipTransactionId} {result?.pointsHistory?.pointsHistoryId}
            </b>,
          ],
          otherData: { ...location?.state },
          transactionData: result,
        });
        setVIPCampaignDetails({
          amount: 0,
          points: 0,
          hasVIPCampaign: false,
        });
      }
    } catch (error) {
      if (
        error?.data?.errorCode === ErrorCode.VIPDailyLimit ||
        error?.data?.errorCode === ErrorCode.VIPWeeklyLimit ||
        error?.data?.errorCode === ErrorCode.VIPMonthlyLimit
      ) {
        let errorMessage;
        switch (error?.data?.errorCode) {
          case ErrorCode.VIPDailyLimit:
            errorMessage = "daily";
            break;
          case ErrorCode.VIPWeeklyLimit:
            errorMessage = "weekly";
            break;
          case ErrorCode.VIPMonthlyLimit:
            errorMessage = "monthly";
            break;

          default:
            errorMessage = error?.data?.message;
        }

        const pointsDetails = error?.data?.vipCampaign
          ? {
              points:
                (error?.data.points ? error?.data?.points : error?.data?.equivalentPoints) || 0,
              amount: parseNumber(params.purchaseAmount ?? 0) || 0,
              hasVIPCampaign: true,
            }
          : {
              points: parseNumber(params.purchaseAmount ?? 0) || 0,
              amount: parseNumber(params.purchaseAmount ?? 0) || 0,
              hasVIPCampaign: false,
            };
        setVIPCampaignDetails(pointsDetails);
        modifyForm({
          [fields?.purchaseAmount?.name]: {
            value: params.purchaseAmount,
            error: false,
            message: errorMessage,
          },
        });

        return applyPurchaseAmountError(
          locale.populate(locale.exceedDailyWeeklyMonthly, [errorMessage]),
          false
        );
      } else if (error?.data?.errorCode === ErrorCode.InsufficientPointsBalance) {
        return applyPurchaseAmountError(locale.insufficientBalanceAward, true);
      } else {
        return applyPurchaseAmountError(error?.data?.message, true);
      }
    }
  };
  const handleAwardPoints = () => {
    submitForm(submit);
  };

  const onPurchaseAmount = useCallback(
    async (name, value) => {
      modifyForm({
        [name]: {
          value,
        },
      });
    },
    [modifyForm]
  );

  const onBlurPurchaseAmount = useCallback(
    async (value) => {
      if (!fields?.purchaseAmount?.error) {
        let requestUrl = validateVipConversionAwardPoints;

        const applyPurchaseAmountError = (message, error) => {
          applyFieldErrors(
            {
              purchaseAmount: message,
            },
            {
              purchaseAmount: {
                value: fields?.purchaseAmount?.value || 0,
                error,
              },
            }
          );
        };

        const purchaseAmountValue = roundUp(
          isNumber(fields?.purchaseAmount?.value)
            ? fields?.purchaseAmount?.value
            : parseFloat(fields?.purchaseAmount?.value?.replace(/,/g, "")),
          "0.00"
        );

        try {
          const res = await requestUrl.request({
            amount: purchaseAmountValue,
            // amount: parseFloat(fields?.purchaseAmount?.value || 0),
            userId: location?.state?.user?.userId ?? "",
            cardNumber: location?.state?.cardNumber,
          });

          if (res) {
            const pointsDetails = res?.vipCampaign
              ? {
                  points: res?.points || 0,
                  amount: parseNumber(fields.purchaseAmount.value ?? 0) || 0,
                  hasVIPCampaign: true,
                }
              : {
                  points: parseNumber(fields.purchaseAmount.value ?? 0) || 0,
                  amount: parseNumber(fields.purchaseAmount.value ?? 0) || 0,
                  hasVIPCampaign: false,
                };
            setVIPCampaignDetails(pointsDetails);
          }
        } catch (error) {
          if (
            error?.data?.errorCode === ErrorCode.VIPDailyLimit ||
            error?.data?.errorCode === ErrorCode.VIPWeeklyLimit ||
            error?.data?.errorCode === ErrorCode.VIPMonthlyLimit
          ) {
            let errorMessage;
            switch (error?.data?.errorCode) {
              case ErrorCode.VIPDailyLimit:
                errorMessage = "daily";
                break;
              case ErrorCode.VIPWeeklyLimit:
                errorMessage = "weekly";
                break;
              case ErrorCode.VIPMonthlyLimit:
                errorMessage = "monthly";
                break;

              default:
                errorMessage = error?.data?.message;
            }

            const pointsDetails = error?.data?.vipCampaign
              ? {
                  points:
                    (error?.data.points ? error?.data?.points : error?.data?.equivalentPoints) || 0,
                  amount: parseNumber(fields.purchaseAmount.value ?? 0) || 0,
                  hasVIPCampaign: true,
                }
              : {
                  points: parseNumber(fields.purchaseAmount.value ?? 0) || 0,
                  amount: parseNumber(fields.purchaseAmount.value ?? 0) || 0,
                  hasVIPCampaign: false,
                };
            setVIPCampaignDetails(pointsDetails);

            modifyForm({
              [fields?.purchaseAmount?.name]: {
                value: value,
                error: false,
                message: errorMessage,
              },
            });

            return applyPurchaseAmountError(
              locale.populate(locale.exceedDailyWeeklyMonthly, [errorMessage]),
              false
            );
          } else if (error?.data?.errorCode === ErrorCode.InsufficientPointsBalance) {
            return applyPurchaseAmountError(locale.insufficientBalanceAward, true);
          } else {
            return applyPurchaseAmountError(error?.data?.message, true);
          }
        }
      }
    },
    [
      location?.state?.user?.userId,
      applyFieldErrors,
      fields.purchaseAmount.name,
      fields.purchaseAmount.value,
      validateVipConversionAwardPoints,
      fields.purchaseAmount.error,
      location?.state?.cardNumber,
      modifyForm,
    ]
  );

  useEffect(() => {
    if (fields.productType.value) {
      onBlurPurchaseAmount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.productType]);

  const isPurchaseError = useMemo(() => {
    if (fields?.purchaseAmount?.error) {
      if (
        typeof fields.purchaseAmount.error === "boolean" &&
        fields.purchaseAmount.error === true
      ) {
        // Handle case where error is a boolean and true
        return fields.purchaseAmount.error;
      } else if (typeof fields.purchaseAmount.error === "object") {
        // Handle case where error is an object
        return fields.purchaseAmount.error?.purchaseAmount?.error;
      }
    }
  }, [fields?.purchaseAmount?.error]);

  const cardDetails = {
    vipDetails: location?.state ? { ...location.state } : {},
    disabledAwardPointsButton: fields.purchaseAmount.value === "" ? true : false,
    hideAwardPointsButton: true,
    hidePayWithPointsButton: true,
    hideVIPRedemptionsButton: true,
    isAwardPoints: true,
    isFromVIPHomePage: false,
    submitAwardPoint: handleAwardPoints,
    submitForm,
    loadingVIPConversionAwardPoints,
    loadingVIPValidateConversionPoints,
    disabledGiveVIPPoints:
      isPurchaseError ||
      parseNumber(fields.purchaseAmount.value) === null ||
      parseNumber(fields.purchaseAmount.value) === 0 ||
      fields.purchaseAmount.value === "" || // Check if purchaseAmount is empty
      fields.orNumber.value === "" || // Check if orNumber is empty
      fields.productType.value === "",
    eligibleAmount: parseFloat(parseNumber(fields.purchaseAmount.value ?? 0)) || 0,
    vipPointsEquivalent: parseFloat(parseNumber(vipCampaignDetails.points)) || 0,
  };

  return (
    <div className={styles.container}>
      <SuccessModalForm
        image={SuccessActivateAccount}
        title={locale.exclamatedSuccess}
        content={locale.awardPointsSuccessMessage}
        {...successModal}
      />
      <div className={styles.leftCardsContainer}>
        <div className={styles.intro}>
          <div>
            <Title className={styles.title}>{"Award VIP Points"}</Title>
            <Text style={{ marginTop: "10px" }} strong>
              {"Fill in the fields below."}
            </Text>
          </div>
        </div>
        <div className={styles.purchaseAmount}>
          <Field {...fields.purchaseAmount} className={styles.fieldLabel}>
            <InputAmount
              innerLabel
              {...fields.purchaseAmount}
              onChange={(_, { value }) => {
                onPurchaseAmount(_, value);
              }}
              onBlur={(_, { value }) => {
                onBlurPurchaseAmount(value);
              }}
              onBlurPurchaseAmount={onBlurPurchaseAmount}
            />
            {!fields?.purchaseAmount?.error && (
              <Text style={{ float: "right", marginTop: "5px", color: "#a6aab3 !important" }} label>
                {`${locale.max}. ${formatAmount(fields.purchaseAmount.max)}`}
              </Text>
            )}
          </Field>
        </div>
        <div className={styles.inputPointRedeem}>
          <Field className={styles.fieldLabel} {...fields.orNumber}>
            <TextField
              {...fields.orNumber}
              onChange={modifyField}
              onBlur={() => onBlurPurchaseAmount(fields?.purchaseAmount?.value || 0)}
            />
          </Field>
        </div>
        <div className={styles.productType}>
          <Field {...fields.productType} className={styles.fieldLabel} style={{ width: "40%" }}>
            <Dropdown
              options={products}
              onChange={(value) => {
                modifyField(fields.productType.name, { value: value });
              }}
              {...fields.productType}
            />
          </Field>
        </div>
      </div>
      <div className={styles.rightCardsContainer}>
        <div className={styles.rightCardsSection}>
          <VipCardDetails {...cardDetails} ref={awardPointsRef} />
        </div>
      </div>
    </div>
  );
};

export default VipAwardPointsModule;
