const Products = {
  Gas91: "gas91",
  Gas95: "gas95",
  Gas97: "gas97",
  Diesel: "diesel",
  Lubes: "lubes",
  Lubricants: "lubes",
  Lubserv: "lubeserv",
};

export default Products;
