import { Products } from "enums";
import RedemptionStatus from "enums/redemption-status";
import { SettlementStatus } from "enums";

export const paymentHistoryState = () => ({
  startDate: null,
  endDate: null,
  productCodes: [Products.Diesel, Products.Gas91, Products.Gas95, Products.Gas97],
  page: 1,
  perPage: 10,
  redemptionStatuses: {
    isSelectedAll: true,
    value: [RedemptionStatus.Success, RedemptionStatus.Voided],
  },
  settlementStatuses: {
    isSelectedAll: true,
    value: [
      SettlementStatus.ForSettlement,
      SettlementStatus.ForProcessing,
      SettlementStatus.Processed,
    ],
  },
  searchKey: "",
});
