import React from "react";
import styles from "./forgot-password.module.scss";
import { Path } from "enums";
import { Button, Text } from "components/commons";
import locale from "localization";
import { useHistory } from "react-router-dom";

const ForgotPasswordSuccess = ({ ...props }) => {
  const history = useHistory();
  const { successEmailSubmit } = props;

  return (
    <>
      <Text>
        <locale.Populate
          text={locale.passwordInstructionHaveSent}
          items={[<b>{successEmailSubmit}</b>]}
        />
      </Text>
      <div className={styles.buttonGroup}>
        <Button
          primary
          className={styles.redirectLogin}
          onClick={() => {
            history.push(Path.Auth);
          }}
        >
          {locale.goToLoginPage}
        </Button>
      </div>
    </>
  );
};

export default ForgotPasswordSuccess;
