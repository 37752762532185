import { InnerLabelTextfield, TextField } from "components/commons";
import React from "react";
import { isLiterValid, formatNumber, parseNumber, isAmountValid } from "utils";

const InputAmount = ({
  className,
  onChange,
  onBlur,
  innerLabel,
  disabled,
  onBlurPurchaseAmount,
  ...props
}) => {
  const handleEnter = (value) => {
    if (value && onBlurPurchaseAmount) {
      onBlurPurchaseAmount(value);
    }
  };

  const componentProps = {
    ...props,
    className,
    onChange: (name, { value }) => {
      if (innerLabel) {
        if (isAmountValid(value) || !value) {
          onChange(name, { value });
        }
      } else {
        if (isLiterValid(value) || !value) {
          onChange(name, { value });
        }
      }
    },
    onBlur: (name, { value }) => {
      if (value) {
        onChange(name, { value: `${formatNumber(value, 2)}${!innerLabel ? " L" : ""}` });
      }
      if (onBlur) {
        onBlur(name, { value });
      }
    },
    onFocus: () => {
      if (props.value) {
        const num = Number(parseNumber(props.value));
        onChange(props.name, { value: num || "" });
      }
    },
    onEnter: handleEnter, // Use the onEnter prop
  };

  if (innerLabel) {
    return <InnerLabelTextfield disabled={disabled} {...componentProps} prefix="₱" />;
  }
  return <TextField disabled={disabled} {...componentProps} />;
};

export default InputAmount;
