import { forwardRef, useImperativeHandle } from "react";
import styles from "./vip-card-details.module.scss";
import { Text, Image, Button } from "components/commons";
import { LinkVIP, VIPPoints, VIPPointsActive, TrophyBlue, CashBlue, EqualSign } from "images";
import { CircularProgress } from "@material-ui/core";
import locale from "localization";
import { useRouter } from "hooks";
import { Path } from "enums";
import { formatAmount, roundUp } from "utils";

const VipCardDetails = forwardRef(
  (
    {
      loadingValidateVIP,
      vipDetails,
      disabledAwardPointsButton,
      disabledPayWithPointsButton,
      disabledVIPRedemptionsButton,
      hideAwardPointsButton,
      hidePayWithPointsButton,
      hideVIPRedemptionsButton,
      submitPayWithPoint,
      submitAwardPoint,
      isFromVIPHomePage,
      isAwardPoints,
      loadingSendOTP,
      disabledGiveVIPPoints,
      eligibleAmount,
      vipPointsEquivalent,
      loadingVIPConversionAwardPoints,
      loadingVIPValidateConversionPoints,
    },
    ref
  ) => {
    const history = useRouter();
    const onClickPayWithPoints = () => {
      submitPayWithPoint();
    };

    const onClickAwardPoints = () => {
      submitAwardPoint();
    };

    useImperativeHandle(ref, () => ({
      // Expose parent function to parent component
      onClickPayWithPoints,
      onClickAwardPoints,
    }));

    return (
      <div className={styles.vipAccount}>
        <Image src={LinkVIP} className={styles.vipLinkImage} />
        {loadingValidateVIP ? (
          <div className={styles.circular}>
            <CircularProgress className={styles.circular} />
          </div>
        ) : (
          <div className={styles.viplinkContent}>
            <div className={styles.vipAccountDetails}>
              <Text className={vipDetails ? styles.titleActive : styles.titleInActive}>
                {vipDetails ? vipDetails?.vipFullName : locale.name}
              </Text>
              <Text
                className={vipDetails ? styles.vipAccountWithData : styles.vipAccountWithoutData}
              >
                {vipDetails ? vipDetails?.accountType : locale.vipAccountType}
              </Text>
              <Text
                className={vipDetails ? styles.vipAccountWithData : styles.vipAccountWithoutData}
              >
                {vipDetails &&
                vipDetails?.hasOwnProperty("isMobileNumber") &&
                (vipDetails?.user?.vipType === "biz" || vipDetails?.user?.vipType === "public")
                  ? ""
                  : vipDetails?.cardNumber || locale.cardNumberVIP}
              </Text>
              <Text
                className={vipDetails ? styles.vipAccountWithData : styles.vipAccountWithoutData}
              >
                {vipDetails ? vipDetails?.mobileNumber : locale.cpNumber}
              </Text>
            </div>
            <div className={styles.vipAccountPointsContainer}>
              <div className={styles.vipAccountPoints}>
                <div className={styles.imageWrapper}>
                  <Image
                    src={vipDetails ? VIPPointsActive : VIPPoints}
                    className={styles.imgPoint}
                  />
                </div>
                <div className={styles.textWrapper}>
                  <p className={vipDetails ? styles.textPointsActive : styles.textPointsInActive}>
                    <Text className={vipDetails ? styles.pointsActive : styles.pointsInActive}>
                      {vipDetails ? vipDetails?.points : 0}
                    </Text>
                    <Text
                      className={vipDetails ? styles.pointsActiveText : styles.pointsInActiveText}
                    >
                      {locale.points}
                    </Text>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {vipDetails && !vipDetails?.isRegistered ? (
          <div className={styles.vipButtons}>
            <Button
              primary
              full
              className={styles.registerVIPCardButton}
              onClick={() => {
                history.push({
                  pathname: Path.VIPRegistration,
                  state: { cardNumber: vipDetails?.cardNumber ?? "" },
                });
              }}
              loading={loadingValidateVIP}
            >
              {locale.registerVIPCard}
            </Button>
          </div>
        ) : (
          ""
        )}
        <hr className={styles.vipBrokenLine} />
        {isAwardPoints && (
          <>
            <Text className={styles.vipPointsConversion} strong>
              {locale.vipPointsConversion}
            </Text>

            <div className={styles.calculatorContainer}>
              <div className={styles.eligibleAmount}>
                <Image src={CashBlue} className={styles.eligibleAmountImage} />
                <Text
                  style={
                    disabledGiveVIPPoints
                      ? { fontSize: "12px", color: "#a7aab2" }
                      : { fontSize: "12px", color: "#000" }
                  }
                >
                  {locale.eligibleAmount}
                </Text>
                <Text
                  style={
                    disabledGiveVIPPoints
                      ? {
                          fontSize: "14px",
                          fontWeight: 900,
                          color: "#a7aab2",
                        }
                      : {
                          fontSize: "14px",
                          fontWeight: 900,
                        }
                  }
                >
                  {formatAmount(eligibleAmount)}
                </Text>
              </div>
              <div className={styles.equal}>
                <Image src={EqualSign} />
              </div>
              <div className={styles.vipPointsEquivalent}>
                <Image src={TrophyBlue} className={styles.vipPointsEquivalentImage} />
                <Text
                  className={
                    disabledGiveVIPPoints
                      ? styles.disabledGiveVIPPointsEquivalentLabel
                      : styles.enabledGiveVIPPointsEquivalentLabel
                  }
                >
                  {locale.vipPointsEquivalent}
                </Text>
                <Text
                  className={
                    disabledGiveVIPPoints
                      ? styles.disabledGiveVIPPointsAmount
                      : styles.enabledGiveVIPPointsAmount
                  }
                >
                  {loadingVIPValidateConversionPoints === undefined ? (
                    <>
                      {roundUp(0)} {locale.points}
                    </>
                  ) : loadingVIPValidateConversionPoints ? (
                    <div className={styles.circular}>
                      <CircularProgress className={styles.circular} />
                    </div>
                  ) : (
                    <>
                      {roundUp(vipPointsEquivalent || 0)} {locale.points}
                    </>
                  )}
                </Text>
              </div>
            </div>
            <div className={styles.vipButtons}>
              <Button
                primary
                className={styles.giveVIPPointsButton}
                disabled={disabledGiveVIPPoints}
                onClick={() => {
                  onClickAwardPoints();
                }}
                loading={loadingVIPConversionAwardPoints || loadingVIPValidateConversionPoints}
              >
                {locale.giveVIPPoints}
              </Button>
            </div>
          </>
        )}

        <div className={styles.vipButtons}>
          {hideAwardPointsButton ? (
            ""
          ) : (
            <Button
              outlineSquare
              className={styles.awardPointsButton}
              disabled={disabledAwardPointsButton}
              onClick={() => {
                history.push({
                  pathname: Path.VIPAwardPoints,
                  state: {
                    ...vipDetails,
                  },
                });
              }}
              loading={loadingValidateVIP}
            >
              {locale.awardPoints}
            </Button>
          )}
          {hidePayWithPointsButton ? (
            ""
          ) : (
            <Button
              className={
                vipDetails && vipDetails?.isRegistered
                  ? styles.payWithPointsButtonWithData
                  : styles.payWithPointsButtonWithoutData
              }
              disabled={disabledPayWithPointsButton}
              loading={isFromVIPHomePage ? loadingValidateVIP : loadingSendOTP}
              onClick={() => {
                isFromVIPHomePage
                  ? history.push({
                      pathname: Path.VIPPayWithPoints,
                      state: {
                        ...vipDetails,
                      },
                    })
                  : onClickPayWithPoints();
              }}
            >
              {locale.payWithPoints}
            </Button>
          )}
          {hideVIPRedemptionsButton ? (
            ""
          ) : (
            <Button
              primary={disabledVIPRedemptionsButton ? false : true}
              full
              className={styles.vipRedemptionsButton}
              disabled={disabledVIPRedemptionsButton}
              loading={loadingValidateVIP}
              onClick={() => {
                history.push({
                  pathname: Path.VIPRedemption,
                  state: {
                    ...vipDetails,
                    loadingValidateVIP,
                  },
                });
              }}
            >
              {locale.vipRedemptions}
            </Button>
          )}
        </div>
      </div>
    );
  }
);
export default VipCardDetails;
