import { Modal } from "components/commons";
import React from "react";
import { locale } from "localization/en";
import { formatDate } from "utils";
import { DateTime } from "enums";
import { prettifyPayWithPesoStatus } from "utils/pretty.utils";
import styles from "./pay-with-locqpay-actions-history-modal.module.scss";

const PayWithLOCQPayActionsHistoryModal = ({
  close,
  active,
  title,
  voidedAt,
  voidedByEmail,
  status,
}) => {
  return (
    <Modal active={active} title={title} close={close} className={styles.modal}>
      <div className={styles.container}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>{locale.action}</th>
              <th>{locale.modifiedBy}</th>
              <th>{locale.date}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{prettifyPayWithPesoStatus(status)}</td>
              <td>{voidedByEmail}</td>
              <td>{formatDate(voidedAt, DateTime.G)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default PayWithLOCQPayActionsHistoryModal;
