import QRCode from "react-qr-code";
import './qr-generator.scss';

function QrGenerator({qrData, description, useTemplate}) {
  const descriptionStyle = useTemplate ? "qrDescription" : "productName";
  const containerStyle = useTemplate ? "templateComponent" : "qrComponent";
  const productType = useTemplate ? qrData.fuelType || qrData.productType || null : null;
  const isLubes = (qrData.productType ?? qrData.fuelType) === "lubes";
  let contentStyle = isLubes ? "longLubeText" : "longText";
  if (description.length <= 29 ) {
    contentStyle = isLubes ? 'shortLubeText' : `shortText`;
  }

  return (
    <div className="qrOuterRing">
      <div className={[containerStyle, productType].join(" ")}>
        <div className="qrImage">
          <QRCode size={149.76} value={JSON.stringify(qrData)} />
        </div>
        {<div className={descriptionStyle}>
          {useTemplate ? <p className={contentStyle}>{description}</p> : description}
        </div>}
      </div>
    </div>
  )
  // return (
  //   <div className={"qrComponentContainer"}>
  //     <div className={[containerStyle, productType].join(" ")}>
  //       <div className={descriptionStyle + "Qr"}>
  //
  //       </div>
  //       {descriptionComponent}
  //     </div>
  //   </div>
  // )
}

export default QrGenerator;