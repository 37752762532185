import React, { useState } from "react";
import styles from "./forgot-password.module.scss";
import { Title, Highlight, Text } from "components/commons";
import locale from "localization";
import { initialState } from "./forgot-password.state";
import { useForm, useApi, useMount } from "hooks";
import { forgotPassword } from "apis";
import { handleRequest } from "utils";
import ForgotPasswordForm from "./forgot-password-form";
import ForgotPasswordSuccess from "./forgot-password-success";
import * as queryString from "query-string";
import { useHistory } from "react-router-dom";
import { ErrorCode } from "enums";

const ForgotPasswordModule = () => {
  const [successEmailSubmit, setSuccessEmailSubmit] = useState("");
  const history = useHistory();
  const parse = queryString.parse(history.location.search);
  const [errorCode] = useState(parse.code || null);
  const emailAddress = history.location.state?.email;

  const { request: forgotPasswordRequest, loading: submittingForgotPassword } = useApi({
    api: forgotPassword,
    handleOwnError: {
      badrequest: true,
    },
    modalError: false,
  });
  const { fields, modifyField, submitForm, getFormValues, applyFieldErrors, isFormSubmittable } =
    useForm({
      initialState,
    });
  const handleSubmit = () => {
    const { email } = getFormValues();
    if (!email) {
      applyFieldErrors({
        email: !email ? "" : null,
      });
      return;
    }
    handleRequest(
      async () => {
        const res = await forgotPasswordRequest({ email: email }, () => handleSubmit());
        if (res.message) {
          setSuccessEmailSubmit(email);
        }
      },
      null,
      (err) => {
        if (
          err.data.errorCode === ErrorCode.UserNotFound ||
          err.data.errorCode === ErrorCode.UserDontBelongToAStation
        ) {
          return applyFieldErrors({
            email: locale.sorryWeDontRecognizeThisAccount,
          });
        } else if (err.data.errorCode === ErrorCode.UserInviteStillPending) {
          return applyFieldErrors({
            email: locale.thisEmailHasNotYetBeenActivated,
          });
        }
      }
    );
  };

  useMount(() => {
    if (emailAddress) {
      modifyField(fields.email.name, { value: emailAddress });
    }
  });

  const state = {
    handleSubmit,
    submittingForgotPassword,
    isFormSubmittable,
    fields,
    modifyField,
    submitForm,
    successEmailSubmit,
  };

  return (
    <div className={styles.forgotContainer}>
      <Title className={styles.title}>{locale.passwordReset}</Title>
      {(errorCode === ErrorCode.InvalidForgotPasswordToken ||
        errorCode === ErrorCode.ExpiredForgotPasswordToken) && (
        <Highlight warning>
          <Text>{locale.passwordRecoveryLinkInvalid}</Text>
        </Highlight>
      )}
      {successEmailSubmit === "" ? (
        <ForgotPasswordForm {...state} />
      ) : (
        <ForgotPasswordSuccess {...state} />
      )}
    </div>
  );
};

export default ForgotPasswordModule;
