import React, { useCallback, useContext, useState } from "react";
import locale from "localization";
import { getRedemptions, generateStationReport } from "apis";
import { useApi, useModal, useExport } from "hooks";
import useDataTable from "hooks/useDataTable";
import { formatVolume } from "utils";
import { prettifyVoidTransactionValue } from "utils/pretty.utils";
import { SettlementStatus } from "enums";
import { Text, Intro } from "components/commons";
import { paymentHistoryBusinessState } from "./payment-history-business-filter.state";
import { StationContext } from "contexts";
import ConfirmModal from "components/modals/confirm-modal/confirm-modal";
import SuccessModal from "components/modals/success-modal/success-modal";
import VoidTransactionModal from "./void-transaction-modal";
import ReceiptGuideModal from "./receipt-guide-modal";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import { updateRedemptionRemarks, voidRedemption } from "apis/redemption.api";
import { mapDataToList, mapFilterToRequest } from "./payment-history-business.mapper";
import { paymentHistoryColumns } from "./payment-history-business-columns";
import PaymentHistoryBusinessFilter from "./payment-history-business-filter";

const PaymentHistoryForBusiness = () => {
  const [remarks, setRemarks] = useState();
  const [value, setValue] = useState("");

  const { request: voidRequest, loading: voidingRequest } = useApi({
    api: voidRedemption,
    modalError: true,
    pageError: false,
  });

  const updateRemarksApi = useApi({
    api: updateRedemptionRemarks,
    modalError: true,
    pageError: false,
  });

  const voidTransactionModal = useModal();
  const receiptGuideModal = useModal();
  const confirmModal = useModal();

  const { station } = useContext(StationContext);
  const { stationId } = station;

  const handleVoidPayment = ({ redemptions }) => {
    setRemarks("");
    voidTransactionModal.show({
      redemptionItem: redemptions,
      title: `${locale.voidTransaction}?`,
      description: (
        <Text align="center">
          <locale.Populate
            text={
              redemptions?.redemption?.settlementId
                ? redemptions?.redemption?.settlementStatus === SettlementStatus.ForProcessing
                  ? locale.voidingThisTransactionSettlementProcessing
                  : locale.voidingThisTransactionSettlementProcessed
                : locale.voidingThisTransaction
            }
            items={[<b>{formatVolume(redemptions?.redemption?.literVolume)}</b>]}
          />
        </Text>
      ),
      label: `${locale.reason}/${locale.remarks}`,
      actionText: locale.voidTransaction,
      submit: (value) => {
        onVoidTransactionCb({
          redemptionItem: redemptions,
          value,
        });
      },
    });
  };

  const handleEdit = ({ redemptions }) => {
    setRemarks(redemptions?.redemption?.remarks);
    setValue(prettifyVoidTransactionValue(redemptions?.redemption?.remarks));
    voidTransactionModal.show({
      redemptionItem: redemptions,
      title: locale.editRemarks,
      description: null,
      label: `${locale.reason}/${locale.remarks}`,
      actionText: locale.save,
      submit: async (value) => {
        onHandleEdit({
          redemptionItem: redemptions,
          value,
        });
      },
    });
  };

  const handleReceiptGuide = ({ redemptions }) => {
    setRemarks("");
    receiptGuideModal.show({
      redemptionItem: redemptions,
      title: `${locale.receiptGuide}`,
      description: "",
      label: `${locale.reason}/${locale.remarks}`,
    });
  };

  const { filter, search, table } = useDataTable({
    api: { api: getRedemptions, params: { stationId, platformType: "plb" } },
    filter: { initialState: paymentHistoryBusinessState(), mapper: mapFilterToRequest },
    table: {
      key: "redemptions",
      mapper: (redemptions) =>
        mapDataToList({ redemptions, handleVoidPayment, handleReceiptGuide, handleEdit }),
      columns: paymentHistoryColumns,
    },
  });

  const exports = useExport({
    api: generateStationReport,
    reportType: "plb-redemption",
    hasModal: true,
    mappedFilterState: {
      ...filter?.mappedFilterState,
      platformType: "plb",
      redemptionStationId: stationId,
      searchKey: search?.searchKey,
    },
  });

  const onVoidTransactionCb = useCallback(
    async ({ redemptionItem, value }) => {
      await voidRequest({ redemptionId: redemptionItem.redemptionId, remarks: value });
      voidTransactionModal.close();
      table.fetch({});
      confirmModal.show({
        title: locale.transactionVoided,
        content: (
          <locale.Populate
            text={locale.thisTransactionHasBeenVoided}
            items={[
              <b>{formatVolume(redemptionItem?.redemption?.literVolume)}</b>,
              <b>{redemptionItem?.fleet?.businessName}</b>,
            ]}
          />
        ),
        loading: voidingRequest,
        redemptionItem,
      });
      setRemarks("");
    },
    [voidRequest, voidTransactionModal, voidingRequest, confirmModal, table]
  );

  const onHandleEdit = useCallback(
    async ({ redemptionItem, value }) => {
      await updateRemarksApi.request({
        id: redemptionItem?.redemptionId,
        remarks: value,
      });
      voidTransactionModal.close();
      setRemarks("");
      table.fetch({});
    },
    [updateRemarksApi, voidTransactionModal, table]
  );

  return (
    <div>
      <VoidTransactionModal
        {...voidTransactionModal}
        remarks={remarks}
        onChangeRemarks={(v) => {
          setRemarks(v);
        }}
        loading={voidingRequest || updateRemarksApi.loading}
        onSubmit={voidTransactionModal.submit}
        value={value}
        setValue={setValue}
      />

      <ReceiptGuideModal
        {...receiptGuideModal}
        remarks={remarks}
        value={value}
        setValue={setValue}
      />
      <SuccessModal {...exports?.successModalComponent} />
      <ConfirmModal {...confirmModal} />
      <div>
        <Intro title={locale.populate(locale.paymentHistoryTitle, [locale.business])} />
      </div>
      <PaymentHistoryBusinessFilter {...filter} {...search} {...exports} />
      <DataTableV2 {...table} paginationV2={true} />
    </div>
  );
};

export default PaymentHistoryForBusiness;
