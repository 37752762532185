import React from "react";
import moment from "moment";
import { Field, Pill, Text, Title } from "components/commons";

import { Products, SettlementStatus } from "enums";
import locale from "localization";
import { prettifySettlementStatus, dateTimeFormat, dateFormat, formatAmount } from "utils";

import styles from "./view-settlement-history.module.scss";
import { Skeleton } from "@material-ui/lab";
import { prettifyProduct } from "utils/pretty.utils";

const ViewSettlementHistoryForm = ({
  viewMode,
  settlementDetails: {
    settlementId = "",
    discountVoucherStatus = "",
    createdAt = "",
    station,
    periodStartDate,
    periodEndDate,
    discountVoucherRemarks,
    dealerSettlementRemarks,
    dealerSettlementStatus,
    totalDealerSettlement,
    totalDiscountVoucher,
    totalDiscountVoucherDiesel,
    totalDiscountVoucherGas91,
    totalDiscountVoucherGas95,
    totalDiscountVoucherGas97,
  },
  settlementLoading
}) => {

  const tillDate = "2022-08";

  return (
    <div>
      <Title black xsmall>
        {locale.basicInformation}
      </Title>
      <div
        style={{
          width: "700px",
        }}
      >
        <Field
          className={styles.fieldLabel}
          labelClassName={styles.fieldLabelInfo}
          label={locale.dateProcessed}
          labelPosition="left"
          childrenClassName={styles.fieldContent}
        >
          {settlementLoading ? (
            <Skeleton
              style={{
                width: "200px",
              }}
            />
          ) : (
            dateTimeFormat(createdAt)
          )}
        </Field>
        <Field
          className={styles.fieldLabel}
          labelClassName={styles.fieldLabelInfo}
          label={locale.settlementId}
          labelPosition="left"
          childrenClassName={styles.fieldContent}
        >
          {settlementLoading ? (
            <Skeleton
              style={{
                width: "200px",
              }}
            />
          ) : (
            settlementId
          )}
        </Field>
        <Field
          className={styles.fieldLabel}
          labelClassName={styles.fieldLabelInfo}
          label={locale.stationBusinessName}
          labelPosition="left"
          childrenClassName={styles.fieldContent}
        >
          {settlementLoading ? (
            <Skeleton
              style={{
                width: "200px",
              }}
            />
          ) : (
            station?.businessName
          )}
        </Field>
        <Field
          className={styles.fieldLabel}
          labelClassName={styles.fieldLabelInfo}
          label={locale.redemptionStation}
          labelPosition="left"
          childrenClassName={styles.fieldContent}
        >
          {settlementLoading ? (
            <Skeleton
              style={{
                width: "200px",
              }}
            />
          ) : (
            station?.name
          )}
        </Field>
        <Field
          className={styles.fieldLabel}
          labelClassName={styles.fieldLabelInfo}
          label={locale.periodOfStatement}
          labelPosition="left"
          childrenClassName={styles.fieldContent}
        >
          {settlementLoading ? (
            <Skeleton
              style={{
                width: "200px",
              }}
            />
          ) : (
            <>
              {dateFormat(periodStartDate)} <span style={{ fontWeight: 300 }}>to</span>{" "}
              {dateFormat(periodEndDate)}
            </>
          )}
        </Field>
      </div>
      <div
        style={{
          display: "flex",
          width: 900,
        }}
      >
        <Field
          className={styles.fieldContainer}
          label={locale.dealerSettlementPayoutStatus}
          labelClassName={styles.fieldLabelInfo}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
        >
          {settlementLoading ? (
            <Skeleton
              style={{
                width: "150px",
              }}
            />
          ) : (
            <Pill
              sky={
                dealerSettlementStatus === SettlementStatus.ForProcessing ||
                dealerSettlementStatus === SettlementStatus.ForSettlement
              }
              grass={dealerSettlementStatus === SettlementStatus.Processed}
            >
              {prettifySettlementStatus(dealerSettlementStatus)}
            </Pill>
          )}
        </Field>
        <Field
          className={styles.fieldContainer}
          labelClassName={styles.fieldLabelRemarks}
          label={locale.remarks}
          labelPosition={"left"}
          childrenClassName={viewMode ? styles.fieldInput : styles.fieldContent}
        >
          {settlementLoading ? (
            <Skeleton
              style={{
                width: "200px",
              }}
            />
          ) : (
            <Text>{dealerSettlementRemarks || "--"}</Text>
          )}
        </Field>
      </div>
      <div
        style={{
          display: "flex",
          width: 900,
        }}
      >
        <Field
          className={styles.fieldContainer}
          label={locale.totalDealerSettlementPayout}
          labelClassName={styles.fieldLabelInfo}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
        >
          {settlementLoading ? (
            <Skeleton
              style={{
                width: "200px",
              }}
            />
          ) : (<Text strong>{totalDealerSettlement ? formatAmount(totalDealerSettlement) : "--"}</Text>)}
        </Field>
      </div>
      {
        moment(createdAt).isSameOrBefore(tillDate, "month") &&
        <>
          <div
            style={{
              display: "flex",
              width: 900,
            }}
          >
            <Field
              className={styles.fieldContainer}
              label={locale.discountVoucherStatus}
              labelClassName={styles.fieldLabelInfo}
              labelPosition={"left"}
              childrenClassName={styles.fieldContent}
            >
              {settlementLoading ? (
                <Skeleton
                  style={{
                    width: "150px",
                  }}
                />
              ) : (
                <Pill
                  sky={
                    discountVoucherStatus === SettlementStatus.ForProcessing ||
                    discountVoucherStatus === SettlementStatus.ForSettlement
                  }
                  grass={discountVoucherStatus === SettlementStatus.Processed}
                >
                  {prettifySettlementStatus(discountVoucherStatus)}
                </Pill>
              )}
            </Field>
            <Field
              className={styles.fieldContainer}
              labelClassName={styles.fieldLabelRemarks}
              label={locale.remarks}
              labelPosition={"left"}
              childrenClassName={viewMode ? styles.fieldInput : styles.fieldContent}
            >
              {settlementLoading ? (
                <Skeleton
                  style={{
                    width: "200px",
                  }}
                />
              ) : (
                <Text>{discountVoucherRemarks || "--"}</Text>
              )}
            </Field>
          </div>
          <div
            style={{
              display: "flex",
              width: 900,
            }}
          >
            <Field
              className={styles.fieldContainer}
              label={locale.totalDiscountVoucher}
              labelClassName={styles.fieldLabelInfo}
              labelPosition={"left"}
              childrenClassName={styles.fieldContent}
            >
              {settlementLoading ? (
                <Skeleton
                  style={{
                    width: "200px",
                  }}
                />
              ) : (
                <div>
                  <Text strong>
                    {totalDiscountVoucher ? formatAmount(totalDiscountVoucher) : "0.00"}
                  </Text>
                  <Field
                    className={styles.fieldContainer}
                    label={prettifyProduct(Products.Diesel)}
                    labelPosition={"left"}
                    labelClassName={styles.fieldLabelProductBreakdown}
                  >
                    <Text>
                      {formatAmount(totalDiscountVoucherDiesel ? totalDiscountVoucherDiesel : 0)}
                    </Text>
                  </Field>
                  <Field
                    className={styles.fieldContainer}
                    label={prettifyProduct(Products.Gas91)}
                    labelPosition={"left"}
                    labelClassName={styles.fieldLabelProductBreakdown}
                  >
                    <Text>
                      {formatAmount(totalDiscountVoucherGas91 ? totalDiscountVoucherGas91 : 0)}
                    </Text>
                  </Field>
                  <Field
                    className={styles.fieldContainer}
                    label={prettifyProduct(Products.Gas95)}
                    labelPosition={"left"}
                    labelClassName={styles.fieldLabelProductBreakdown}
                  >
                    <Text>
                      {formatAmount(totalDiscountVoucherGas95 ? totalDiscountVoucherGas95 : 0)}
                    </Text>
                  </Field>
                  <Field
                    className={styles.fieldContainer}
                    label={prettifyProduct(Products.Gas97)}
                    labelPosition={"left"}
                    labelClassName={styles.fieldLabelProductBreakdown}
                  >
                    <Text>
                      {formatAmount(totalDiscountVoucherGas97 ? totalDiscountVoucherGas97 : 0)}
                    </Text>
                  </Field>
                </div>
              )}
            </Field>
          </div>
        </>
      }
      
    </div>
  );
};

export default ViewSettlementHistoryForm;
