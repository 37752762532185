import React from "react";
import MaterialModal from "@material-ui/core/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "./loader.module.scss";
import classNames from "classnames";

const Loader = ({ open, centerCircle }) => {
  return (
    <MaterialModal
      className={classNames(styles.loader, { [styles.centerCircle]: centerCircle })}
      open={open || false}
    >
      <div className={styles.circleContainer}>
        <CircularProgress className={styles.circle} />
      </div>
    </MaterialModal>
  );
};

export default Loader;
