import React, { useMemo } from "react";
import classNames from "classnames";
import { Image } from "components/commons";
import { Products } from "enums";
import { Gas91 } from "images";
import { Gas95 } from "images";
import { Gas97 } from "images";
import { Diesel } from "images";
import { Lubricants } from "images";
import styles from "./fuel-icon.module.scss";

const FuelIcon = ({ className, type, disabled }) => {
  const imageType = useMemo(() => {
    return (
      {
        [Products.Lubes]: Lubricants,
        [Products.Diesel]: Diesel,
        [Products.Gas91]: Gas91,
        [Products.Gas95]: Gas95,
        [Products.Gas97]: Gas97,
      }[type] || Diesel
    );
  }, [type]);

  return (
    <div className={classNames(className, styles.image, { [`${styles.disabled}`]: disabled })}>
      <Image src={imageType} />
    </div>
  );
};

export default FuelIcon;
