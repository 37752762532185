import React from "react";
import classNames from "classnames";
import { Image } from "components/commons";
import { CardInfo } from "images";
import styles from "./card-icon.module.scss";

const CardIcon = ({ className, disabled }) => {
  return (
    <div className={classNames(className, styles.image, { [`${styles.disabled}`]: disabled })}>
      <Image src={CardInfo} />
    </div>
  );
};

export default CardIcon;
