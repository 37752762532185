import { Dropdown, Field, Title, Text } from "components/commons";
import React, { useMemo, useState, useRef, useContext, useEffect } from "react";
import { initialState as formState } from "./vip-pay.state";
import { useForm, useModal, useApi } from "hooks";
import { InputAmount, InputPoint } from "components/field";
import styles from "./vip-pay.module.scss";
import { Products } from "enums";
import { prettifyProduct } from "utils/pretty.utils";
import { formatAmount, parseNumber } from "utils";
import VipCardDetails from "../vip-card-details";
import locale from "localization";
import VipOTP from "./otp/vip-otp";
import { validatePayWithPoints } from "apis";
import { StationContext } from "contexts";
import { ErrorCode } from "enums";
import Big from "big.js";

const VipPayWithPointsModule = (props) => {
  const { station } = useContext(StationContext);
  const { stationId } = station;
  const { location } = props;
  const paywithPointsRef = useRef();
  const payWithPointOTPRef = useRef();
  const payWithPointsOTPModal = useModal();
  const [products] = useState([
    {
      value: Products.Diesel,
      label: prettifyProduct(Products.Diesel),
    },
    {
      value: Products.Gas91,
      label: prettifyProduct(Products.Gas91),
    },
    {
      value: Products.Gas95,
      label: prettifyProduct(Products.Gas95),
    },
    {
      value: Products.Gas97,
      label: prettifyProduct(Products.Gas97),
    },
    {
      value: Products.Lubserv,
      label: prettifyProduct(Products.Lubserv),
    },
    {
      value: Products.Lubricants,
      label: prettifyProduct(Products.Lubricants),
    },
  ]);
  const form = useMemo(() => {
    let initialState = {};
    return formState(initialState);
  }, []);

  const {
    fields,
    modifyField,
    getFormValues,
    applyFieldErrors,
    clearForm,
    submitForm,
    isFormSubmittable,
  } = useForm({
    initialState: form,
  });

  const amountDue =
    !isNaN(parseFloat(parseNumber(fields?.purchaseAmount?.value))) &&
    !isNaN(parseFloat(parseNumber(fields?.pointsToRedeem?.value)))
      ? Number(
          Big(parseFloat(parseNumber(fields?.purchaseAmount?.value || 0))).minus(
            parseFloat(parseNumber(fields?.pointsToRedeem?.value || 0))
          )
        )
      : 0;

  const payWithPointsSendOTP = useApi({
    api: validatePayWithPoints,
    handleOwnError: {
      badrequest: true,
    },
    modalError: false,
  });

  const loadingSendOTP = payWithPointsSendOTP.loading;

  const params = getFormValues();
  const submit = async () => {
    let requestUrl = payWithPointsSendOTP;
    let requestParams = {
      stationId,
      amount: parseFloat(parseNumber(params?.purchaseAmount)),
      points: parseFloat(parseNumber(params?.pointsToRedeem)),
      productCode: params.productType,
      userId: location?.state?.user?.userId ?? "",
    };

    const applyPurchaseAmountError = (message) => {
      applyFieldErrors(
        {
          purchaseAmount: message,
        },
        {
          mobileNumber: {
            value: params.purchaseAmount,
          },
        }
      );
    };
    try {
      await requestUrl.request({ ...requestParams });

      payWithPointOTPRef.current.handleStartTimer();
      payWithPointsOTPModal.show({
        actionText: locale.proceed,
        initialState: {
          otp: {
            value: "",
          },
        },
        amountDue,
        otherData: {
          ...params,
          ...location?.state,
          userId: location?.state?.user?.userId ?? "",
          details: cardDetails?.vipDetails,
        },
        stationId,
        payWithPointsSendOTP,
        clearFormPayWithPoints: clearForm,
        resendOTPVIP: payWithPointsSendOTP,
      });
    } catch (error) {
      switch (error?.data?.errorCode) {
        case ErrorCode.OTPOngoing:
          payWithPointsOTPModal.show({
            actionText: locale.proceed,
            initialState: {
              otp: {
                value: "",
              },
            },
            amountDue,
            otherData: {
              ...params,
              ...location?.state,
              userId: location?.state?.user?.userId ?? "",
              details: cardDetails?.vipDetails,
            },
            stationId,
            payWithPointsSendOTP,
            clearFormPayWithPoints: clearForm,
            resendOTPVIP: payWithPointsSendOTP,
          });
          break;
        default:
          payWithPointsOTPModal.close();
          return applyPurchaseAmountError(error?.data?.message);
      }
    }
  };
  const handlePayWithPoints = () => {
    submitForm(submit);
  };

  const cardDetails = {
    vipDetails: location?.state ? { ...location.state } : {},
    disabledPayWithPointsButton: fields?.purchaseAmount?.value === "" ? true : false,
    disabledAwardPointsButton: true,
    disabledVIPRedemptionsButton: true,
    hideAwardPointsButton: true,
    hidePayWithPointsButton: false,
    hideVIPRedemptionsButton: true,
    isFromVIPHomePage: false,
    submitPayWithPoint: handlePayWithPoints,
    submitForm,
    loadingSendOTP,
  };

  useEffect(() => {
    if (fields.productType.value) {
      onValidatePayWithPoints();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.productType]);

  const onValidatePayWithPoints = () => {
    const purchaseAmountFloat = parseFloat(parseNumber(fields.purchaseAmount.value ?? 0)) || 0;
    const pointsToRedeemFloat = parseFloat(parseNumber(fields.pointsToRedeem.value ?? 0)) || 0;
    const { points } = cardDetails.vipDetails;

    if (Big(Number(pointsToRedeemFloat)).gt(purchaseAmountFloat)) {
      applyFieldErrors({
        pointsToRedeem: locale.pointsToRedeemShouldBeLessThan,
      });
    }

    if (Big(points).lt(pointsToRedeemFloat)) {
      applyFieldErrors({
        pointsToRedeem: locale.insufficientVIPPointsBalance,
      });
    }

    return true;
  };

  return (
    <div className={styles.container}>
      <VipOTP {...payWithPointsOTPModal} ref={payWithPointOTPRef} />
      <div className={styles.leftCardsContainer}>
        <div className={styles.intro}>
          <div>
            <Title className={styles.title}>{locale.payWithPoints}</Title>
            <Text style={{ marginTop: "10px" }} strong>
              {`${locale.fillInTheFieldsBelow}.`}
            </Text>
          </div>
        </div>
        <div className={styles.purchaseAmount}>
          <Field {...fields.purchaseAmount} className={styles.fieldLabel}>
            <InputAmount
              innerLabel
              {...fields.purchaseAmount}
              onChange={modifyField}
              disabled={payWithPointsSendOTP.loading}
            />
            {!fields?.purchaseAmount?.error && (
              <Text style={{ float: "right", marginTop: "5px", color: "#a6aab3 !important" }} label>
                {`${locale.max}. ${formatAmount(fields.purchaseAmount.max)}`}
              </Text>
            )}
          </Field>
        </div>
        <div className={styles.inputPointRedeem}>
          <Field className={styles.fieldLabel} {...fields.pointsToRedeem}>
            <InputPoint
              innerLabel
              {...fields.pointsToRedeem}
              onChange={modifyField}
              onBlur={onValidatePayWithPoints}
              disabled={!fields.purchaseAmount.value || payWithPointsSendOTP.loading}
            />
          </Field>
        </div>
        <div className={styles.productType}>
          <Field {...fields.productType} className={styles.fieldLabel} style={{ width: "40%" }}>
            <Dropdown
              options={products}
              {...fields.productType}
              onChange={(value) => {
                modifyField(fields.productType.name, { value: value });
              }}
              disabled={payWithPointsSendOTP.loading}
            />
          </Field>
        </div>

        <Text>
          {`${locale.remainingAmountDue}`}
          {formatAmount(amountDue)}
        </Text>
      </div>
      <div className={styles.rightCardsContainer}>
        <div className={styles.rightCardsSection}>
          <VipCardDetails
            {...cardDetails}
            ref={paywithPointsRef}
            loadingValidateVIP={payWithPointsSendOTP.loading}
            disabledPayWithPointsButton={!isFormSubmittable}
          />
        </div>
      </div>
    </div>
  );
};

export default VipPayWithPointsModule;
