const VehicleType = {
  Sedan: "sedan",
  Suv: "suv",
  Vanmpv: "van/mpv",
  Hatchback: "hatchback",
  Motorcycle: "motorcycle",
  Tricycle: "tricycle",
  Truck: "truck",
  Jeepney: "jeepney",
  Bus: "Bus",
  Others: "Others",
};

export default VehicleType;
