const LubeServPreferredTime = {
  eightAM: "08:00:00",
  nineAM: "09:00:00",
  tenAM: "10:00:00",
  elevenAM: "11:00:00",
  onePM: "13:00:00",
  twoPM: "14:00:00",
  threePM: "15:00:00",
  fourPM: "16:00:00",
};

export default LubeServPreferredTime;
