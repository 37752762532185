import React from "react";

import styles from "./user-access-filter.module.scss";

import { Autocomplete, Field, SearchField, Filter } from "components/commons";
import locale from "localization";
import { prettifyRole } from "utils/pretty.utils";
import { Role } from "enums";

const UserAccessFilter = ({
  filterState,
  applyFilter,
  applyClearFilter,
  resetFilter,
  modifyFilter,
  searchKey,
  modifySearch,
  applySearch,
  applyClearSearch,
}) => {
  return (
    <div className={styles?.container}>
      <div className={styles?.filter}>
        <Filter submit={applyFilter} close={resetFilter} clear={applyClearFilter}>
          <Field
            className={styles.date}
            label={locale.accessType}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name="role"
              value={filterState.role}
              options={[
                {
                  label: prettifyRole(Role.PortalAdmin),
                  value: Role.PortalAdmin,
                },
                {
                  label: prettifyRole(Role.Accounting),
                  value: Role.Accounting,
                },
                {
                  label: prettifyRole(Role.StationCashier),
                  value: Role.StationCashier,
                },
                {
                  label: prettifyRole(Role.LubeServ),
                  value: Role.LubeServ,
                },
              ]}
              onChange={(name, { value }) => modifyFilter({ [name]: value })}
            />
          </Field>
        </Filter>
        <Field className={styles.search}>
          <SearchField
            restrict={false}
            searchWithSymbols
            placeholder={[locale.emailAddress].join(", ")}
            value={searchKey}
            onChange={(_, { value }) => {
              modifySearch(value);
            }}
            onEnter={applySearch}
            onClear={applyClearSearch}
          />
        </Field>
      </div>
    </div>
  );
};

export default UserAccessFilter;
