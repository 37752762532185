const DateTime = {
  A: "D MMM YYYY",
  B: "h:mma",
  C: "YYYY-MM-DDT00:00:00ZZ",
  D: "YYYY-MM-DDT23:59:59ZZ",
  E: "MMM DD, YYYY",
  F: "MMMM YYYY",
  G: "ddd MM/DD/YYYY h:mm A",
  H: "YYYY",
  I: "MMMM",
  J: "D MMM YYYY h:mm A",
  K: "MMMM DD, YYYY hh:mmA",
  L: "DD MMM YYYY hh:mm A",
};

export default DateTime;
