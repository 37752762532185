import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "./pop-over-menu.module.scss";
import PopOver from "../pop-over/pop-over";

const PopOverMenu = ({ options = [], children, position, disabledClick = false, style }) => {
  const handleClose = (e, option = null) => {
    if (option?.onClick) {
      option.onClick(e, option);
    }
  };

  return (
    <PopOver
      contentStyle={styles.content}
      backgroundStyle={styles.background}
      className={styles.container}
      arrowStyle={styles.arrow}
      closeOnClick
      content={
        <div
          style={{
            with: "100%",
          }}
        >
          <div onClick={handleClose}>
            {options.map((option, i) => {
              if (option.removable && option.disabled) {
                return "";
              } else {
                return (
                  <MenuItem
                    key={i}
                    onClick={(e) => handleClose(e, option)}
                    className={styles.item}
                    disabled={option.disabled}
                  >
                    {option.content}
                  </MenuItem>
                );
              }
            })}
          </div>
        </div>
      }
    >
      {children}
    </PopOver>
  );
};

export default PopOverMenu;
