import { DateTime } from "enums";
import moment from "moment";

export const dateFormat = (date) => {
  return moment(date).format("DD MMM yyyy");
};

export const dateTimeFormat = (dateTime) => {
  return moment(dateTime).format("DD MMM yyyy hh:mma");
};

export const formatDate = (date, format = DateTime.A) => {
  return moment(date).format(format);
};

export const shortDateTime = (dateTime) => {
  const dateTimeMoment = moment(dateTime);
  const isToday = moment().format("MMDDYYYY") === dateTimeMoment.format("MMDDYYYY");
  const isSameYear = moment().format("YYYY") === dateTimeMoment.format("YYYY");
  if (isToday) {
    return dateTimeMoment.format("hh:mm A");
  }
  if (isSameYear) {
    return moment(dateTime).format("DD MMM");
  }
  return moment(dateTime).format("DD MMM YYYY");
};
