import classNames from "classnames";
import React from "react";
import { useMemo } from "react";
import Text from "../text/text";
import styles from "./table.module.scss";

const Table = ({ columns, data }) => {
  const renderColumns = useMemo(() => {
    return columns.map((c, i) => (
      <th key={i} style={{ textAlign: c.align || "left", width: c.width }} className={classNames()}>
        {c.text}
      </th>
    ));
  }, [columns]);

  const renderRows = useMemo(() => {
    const rowContent = [];
    data.forEach((row, i) => {
      rowContent.push(
        <tr key={i}>
          {columns.map((c, j) => {
            const content = row.get(c.key);
            return (
              <td key={j}>
                <div style={{ textAlign: c.align }}>
                  {typeof content === "string" ? <Text>{content}</Text> : content}
                </div>
              </td>
            );
          })}
        </tr>
      );
    });
    return rowContent;
  }, [columns, data]);

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr>{renderColumns}</tr>
        </thead>
        <tbody>{renderRows}</tbody>
      </table>
    </div>
  );
};

export default Table;
