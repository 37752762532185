import locale from "localization";

export const payHistoryColumns = [
  { key: "vipTransactionId", text: locale.vipTransactionId, style: { minWidth: "8%" } },
  { key: "vipCardNumber", text: locale.vipCardNumber, style: { minWidth: "10%" } },
  { key: "mobileWithHash", text: locale.mobileWithHash, style: { minWidth: "6%" } },
  { key: "transactionAmount", text: locale.transactionAmount, style: { minWidth: "6%" } },
  { key: "pointsRedeemed", text: locale.pointsRedeemed, style: { minWidth: "6%" } },
  {
    key: "productTypeCapitalize",
    text: locale.productTypeCapitalize,
    style: { minWidth: "10%" },
  },
  { key: "finalAmountPaid", text: locale.finalAmountPaid, style: { minWidth: "10%" } },
  {
    key: "orNumber",
    text: locale.orNumber,
    style: { minWidth: "6%" },
  },
  {
    key: "dateOfTransaction",
    text: locale.dateOfTransaction,
    style: { minWidth: "20%" },
  },
];
