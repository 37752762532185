import {
  Image,
  TextField,
  Dropdown,
  Field,
  Title,
  Text,
  BackLinkV2,
  Button,
} from "components/commons";
import React, { useMemo, useState, useCallback, useContext, useRef } from "react";
import { initialState as formState } from "./vip-registration.state";
import { useForm, useApi, useModal, useMount } from "hooks";
import styles from "./vip-registration.module.scss";
import { PhilippinesFlag } from "images";
import { ConsumerType, VehicleType, Path, ErrorCode } from "enums";
import { prettifyConsumerType, prettifyVehicleType } from "utils/pretty.utils";
import locale from "localization";
import { validateVIP, registerValidateSendOTP, validateMobileNumber } from "apis";
import { handleRequest } from "utils";
import { ConfirmModal, NotifModal } from "components/modals";
import { capitalize } from "utils/text.utils";
import VipRegistrationOTP from "./vip-registration-otp";
import { CarWithLady } from "images";
import { StationContext } from "contexts";
import { getUserValidatEmail } from "apis/profile.api";

const VipRegistrationModule = (props) => {
  const vipOTPRegistrationRef = useRef();
  const { location } = props;
  const { station } = useContext(StationContext);
  const { stationId } = station;
  const registerVIPModal = useModal();
  const confirmModal = useModal();
  const vipRegistrationOTPModal = useModal();
  const [mobileNumberError, setMobileNumberError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const { request: requestValidateVip } = useApi({
    api: validateVIP,
    handleOwnError: {
      badrequest: true,
    },
    modalError: false,
  });

  const { request: requestValidateEmail } = useApi({
    api: getUserValidatEmail,
    handleOwnError: {
      badrequest: true,
    },
    modalError: false,
  });

  const { request: requestValidateMobileNumber } = useApi({
    api: validateMobileNumber,
    handleOwnError: {
      badrequest: true,
    },
    modalError: false,
  });

  const registerVIPSendOTP = useApi({
    api: registerValidateSendOTP,
    handleOwnError: {
      badrequest: true,
    },
    modalError: false,
  });

  const loadingSendOTP = registerVIPSendOTP.loading;

  const [consumerTypes] = useState([
    {
      value: ConsumerType.CommercialIndustrial,
      label: prettifyConsumerType(ConsumerType.CommercialIndustrial),
    },
    {
      value: ConsumerType.Private,
      label: prettifyConsumerType(ConsumerType.Private),
    },
    {
      value: ConsumerType.Public,
      label: prettifyConsumerType(ConsumerType.Public),
    },
    {
      value: ConsumerType.TNVS,
      label: prettifyConsumerType(ConsumerType.TNVS),
    },
  ]);
  const [vehicleTypes] = useState([
    {
      value: VehicleType.Sedan,
      label: prettifyVehicleType(VehicleType.Sedan),
    },
    {
      value: VehicleType.Suv,
      label: prettifyVehicleType(VehicleType.Suv),
    },
    {
      value: VehicleType.Vanmpv,
      label: prettifyVehicleType(VehicleType.Vanmpv),
    },
    {
      value: VehicleType.Hatchback,
      label: prettifyVehicleType(VehicleType.Hatchback),
    },
    {
      value: VehicleType.Motorcycle,
      label: prettifyVehicleType(VehicleType.Motorcycle),
    },
    {
      value: VehicleType.Tricycle,
      label: prettifyVehicleType(VehicleType.Tricycle),
    },
    {
      value: VehicleType.Truck,
      label: prettifyVehicleType(VehicleType.Truck),
    },
    {
      value: VehicleType.Jeepney,
      label: prettifyVehicleType(VehicleType.Jeepney),
    },
    {
      value: VehicleType.Bus,
      label: prettifyVehicleType(VehicleType.Bus),
    },
    {
      value: VehicleType.Others,
      label: prettifyVehicleType(VehicleType.Others),
    },
  ]);
  const form = useMemo(() => {
    let initialState = {};
    return formState(initialState);
  }, []);

  const {
    fields,
    modifyField,
    isFormSubmittable,
    applyFieldErrors,
    validatedField,
    submitForm,
    getFormValues,
    clearForm,
  } = useForm({
    initialState: form,
  });

  useMount(() => {
    if (location?.state) {
      onChangeCardNumberCb(fields?.cardNumber?.name, { value: location?.state?.cardNumber });
    }
  });

  const onChangeCardNumberCb = useCallback(
    (name, value) => {
      modifyField(name, value);

      const cardNumber = value.value;

      const applyCardNumberNoError = (message) => {
        applyFieldErrors(
          {
            cardNumber: message,
          },
          {
            cardNumber: {
              value: cardNumber,
            },
          }
        );
      };
      if (cardNumber && cardNumber.length === 12) {
        handleRequest(
          async () => {
            const res = await requestValidateVip({
              cardNumber,
            });
            if (res && res?.userId) {
              applyCardNumberNoError(locale.errorMessageMobileValidation);
            }
          },
          {},
          async (err) => {
            switch (err?.data?.errorCode) {
              case ErrorCode.LoyaltyCardNotFound:
                applyCardNumberNoError(locale.cardNumberNotFound);
                break;
              case ErrorCode.LoyaltyCardIsExpired:
                applyCardNumberNoError(locale.cardNumberExpired);
                break;
              case ErrorCode.LoyaltyCardIsInActive:
                applyCardNumberNoError(locale.cardNumberIsInActive);
                break;
              case ErrorCode.LoyalCardAlreadyLinked:
                applyCardNumberNoError(err?.data?.message);
                break;
              case ErrorCode.LoyaltyCardDeleted:
                applyCardNumberNoError(locale.cardNumberDeactivated);
                break;
              case ErrorCode.LoyaltyCardBlocked:
                applyCardNumberNoError(locale.cardNumberDeactivated);
                break;
              case ErrorCode.LoyaltyCardDeactivated:
                applyCardNumberNoError(locale.cardNumberDeactivated);
                break;
              default:
                applyCardNumberNoError(err?.data?.message);
                return err.showError(true);
            }
          }
        );
      }
    },
    [modifyField, applyFieldErrors, requestValidateVip]
  );

  const onChangeMobileNumberCb = useCallback(
    async (name, value) => {
      modifyField(name, value);

      const mobileNumber = value.value;

      if (mobileNumber && mobileNumber.length === 10) {
        try {
          const res = await requestValidateMobileNumber({
            mobileNumber,
          });
          if (res && res?.userId) {
            setMobileNumberError(true);
            applyFieldErrors(
              {
                mobileNumber: locale.errorMessageMobileValidation,
              },
              {
                mobileNumber: {
                  value: mobileNumber,
                },
              }
            );
          } else {
            setMobileNumberError(false);
          }
        } catch (err) {
          switch (err?.data?.errorCode) {
            case ErrorCode.LoyaltyCardDeactivated:
              applyFieldErrors(
                {
                  mobileNumber: err?.data?.message,
                },
                {
                  mobileNumber: {
                    value: mobileNumber,
                  },
                }
              );
              break;
            case ErrorCode.LoyaltyCardBlocked:
              applyFieldErrors(
                {
                  mobileNumber: err?.data?.message,
                },
                {
                  mobileNumber: {
                    value: mobileNumber,
                  },
                }
              );
              break;
            case ErrorCode.UserNotFound:
              setMobileNumberError(false);
              break;
            default:
              return err.showError(true);
          }
          // Handle specific error cases
        }
      }
    },
    [modifyField, applyFieldErrors, requestValidateMobileNumber]
  );

  const params = getFormValues();

  const submitOTP = async () => {
    let requestParams = { cardNumber: params.cardNumber, mobileNumber: `0${params.mobileNumber}` };
    const applyMobileNumberNoError = (message) => {
      applyFieldErrors(
        {
          mobileNumber: message,
        },
        {
          mobileNumber: {
            value: params.mobileNumber,
          },
        }
      );
    };

    try {
      const res = await registerVIPSendOTP.request({
        ...requestParams,
      });
      if (res) {
        // vipOTPRegistrationRef.current.handleResetTimer();
        vipOTPRegistrationRef.current.handleStartTimer();
      }
      vipRegistrationOTPModal.show({
        actionText: locale.proceed,
        initialState: {
          otp: {
            value: "",
          },
        },
        otherData: {
          ...params,
        },
        stationId,
        registerVIPSendOTP,
        clearFormRegistration: clearForm,
        resendOTPVIP: registerVIPSendOTP,
      });

      registerVIPModal.close();
    } catch (error) {
      switch (error?.data?.errorCode) {
        case ErrorCode.OTPOngoing:
          vipRegistrationOTPModal.show({
            actionText: locale.proceed,
            initialState: {
              otp: {
                value: "",
              },
            },
            otherData: {
              ...params,
            },
            stationId,
            registerVIPSendOTP,
            clearFormRegistration: clearForm,
            resendOTPVIP: registerVIPSendOTP,
          });

          registerVIPModal.close();

          break;
        case ErrorCode.OtpLimitReached:
          vipRegistrationOTPModal.show({
            actionText: locale.proceed,
            initialState: {
              otp: {
                value: "",
              },
            },
            otherData: {
              ...params,
            },
            stationId,
            registerVIPSendOTP,
            clearFormRegistration: clearForm,
            resendOTPVIP: registerVIPSendOTP,
          });

          registerVIPModal.close();

          break;
        case ErrorCode.LoyaltyCardDeactivated:
          applyFieldErrors(
            {
              mobileNumber: error?.data?.message,
            },
            {
              mobileNumber: {
                value: params?.mobileNumber,
              },
            }
          );
          registerVIPModal.close();
          break;

        case ErrorCode.LoyaltyCardDeleted:
          vipRegistrationOTPModal.show({
            actionText: locale.proceed,
            initialState: {
              otp: {
                value: "",
              },
            },
            otherData: {
              ...params,
            },
            stationId,
            registerVIPSendOTP,
            clearFormRegistration: clearForm,
            resendOTPVIP: registerVIPSendOTP,
          });
          registerVIPModal.close();
          break;
        case ErrorCode.LoyaltyCardBlocked:
          applyFieldErrors(
            {
              mobileNumber: error?.data?.message,
            },
            {
              mobileNumber: {
                value: params?.mobileNumber,
              },
            }
          );
          registerVIPModal.close();
          break;
        case ErrorCode.MobileNumberAlreadyRegistered:
          applyFieldErrors(
            {
              mobileNumber: error?.data?.message,
            },
            {
              mobileNumber: {
                value: params?.mobileNumber,
              },
            }
          );
          registerVIPModal.close();
          break;
        default:
          applyMobileNumberNoError(error?.data?.message);
          return error.showError(true);
      }
    }
  };

  const submit = async () => {
    let modalContent = {
      image: CarWithLady,
      title: locale.registerVIPCardQuestion,
      content: locale.registerVIPCardDescrption,
      contentHighlight: [
        <b>
          {locale.vipCard} {params.cardNumber}
        </b>,
        <b>
          {capitalize(params.firstName)} {capitalize(params.lastName)}
        </b>,
        <b>0{params.mobileNumber}</b>,
      ],
    };

    let requestEmail = params?.email;

    registerVIPModal.show({
      ...modalContent,
      loading: loadingSendOTP,
      primary: {
        text: locale.yesProceed,
        onClick: async () => {
          try {
            await requestValidateEmail({
              email: requestEmail,
              isPlc: true,
            });
            setEmailError(false);
            submitOTP();
          } catch (error) {
            if (error) {
              setEmailError(true);
              return applyFieldErrors(
                {
                  email: locale.emailAlreadyUsed,
                },
                {
                  email: {
                    value: requestEmail,
                  },
                }
              );
            }
          }
        },
      },
      secondary: {
        text: locale.cancel,
        disabled: loadingSendOTP,
        onClick: async () => {
          registerVIPModal.close();
        },
      },
    });
  };

  const onBlurEmail = async () => {
    const { email } = getFormValues();

    if (email) {
      try {
        await requestValidateEmail({
          email,
          isPlc: true,
        });
        setEmailError(false);
      } catch (error) {
        setEmailError(true);
        return applyFieldErrors(
          {
            email: locale.emailAlreadyUsed,
          },
          {
            email: {
              value: email,
            },
          }
        );
      }
    } else {
      setEmailError(false);
    }
  };

  return (
    <div className={styles.container}>
      <ConfirmModal {...confirmModal} />
      <NotifModal {...registerVIPModal} loading={loadingSendOTP} />
      <VipRegistrationOTP {...vipRegistrationOTPModal} ref={vipOTPRegistrationRef} />
      <div className={styles.leftCardsContainer}>
        <BackLinkV2 text={locale.loginVipAccount} path={Path.VIPAccount} />
        <div className={styles.intro}>
          <div>
            <Title className={styles.title}>{locale.registerVIPCard}</Title>
          </div>
        </div>
        <div>
          <Field className={styles.purchaseAmount} {...fields.cardNumber}>
            <TextField
              {...fields.cardNumber}
              disabled
              onBlur={validatedField}
              onChange={onChangeCardNumberCb}
            />
          </Field>
        </div>
        <div className={styles.inputPointRedeem}>
          <Field className={styles.fieldLabel} {...fields?.mobileNumber}>
            <TextField
              prefix={
                <div className={styles.flagAndMobileInitial}>
                  <Image src={PhilippinesFlag} className={styles.flagPhilippines} />
                  <Text
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    +63
                  </Text>
                </div>
              }
              {...fields?.mobileNumber}
              onChange={onChangeMobileNumberCb}
            />
          </Field>
        </div>
        <div>
          <Field className={styles.purchaseAmount} {...fields.firstName}>
            <TextField {...fields.firstName} onChange={modifyField} />
          </Field>
        </div>
        <div>
          <Field className={styles.purchaseAmount} {...fields.lastName}>
            <TextField {...fields.lastName} onChange={modifyField} />
          </Field>
        </div>
        <div>
          <Field className={styles.purchaseAmount} {...fields.email}>
            <TextField {...fields.email} onChange={modifyField} onBlur={onBlurEmail} />
          </Field>
        </div>
        <Field {...fields.consumerType} style={{ width: "40%" }}>
          <Dropdown
            options={consumerTypes}
            onChange={(value) => {
              modifyField(fields.consumerType.name, { value: value });
            }}
            {...fields.consumerType}
          />
        </Field>
        <Field {...fields.vehicleType} style={{ width: "40%" }}>
          <Dropdown
            options={vehicleTypes}
            onChange={(value) => {
              modifyField(fields.vehicleType.name, { value: value });
            }}
            {...fields.vehicleType}
          />
        </Field>
        <Button
          primary
          className={styles.doneButton}
          disabled={!isFormSubmittable || mobileNumberError || emailError}
          onClick={() => {
            submitForm(submit);
          }}
        >
          {locale.register}
        </Button>
      </div>
      <div className={styles.rightCardsContainer}></div>
    </div>
  );
};

export default VipRegistrationModule;
