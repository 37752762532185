import React from "react";
import { Text } from "..";
import Button from "../button/button";
import Title from "../title/title";
import styles from "./intro.module.scss";

const Intro = ({
  style,
  title,
  subtitle,
  actionText,
  actionOnClick,
  actionOutlined,
  actionStartIcon,
  containerStyle,
  small,
  actionStyle,
}) => {
  return (
    <div className={styles.intro} style={containerStyle}>
      <div style={style}>
        <Title className={styles.title} small={small}>
          {title}
        </Title>
        <Text subtitle>{subtitle}</Text>
      </div>
      {actionText && (
        <div>
          <Button
            startIcon={actionStartIcon}
            primary={!actionOutlined}
            outline={actionOutlined}
            onClick={actionOnClick}
            style={actionStyle}
          >
            {actionText}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Intro;
