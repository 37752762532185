import { locale } from "localization/en";

export const VoidPaymentNonFuelValue = {
  // CustomerPaidExcessAmount: locale.michargedCustomerPaidExcessAmount,
  // CustomerPaidWrongProduct: locale.michargedCustomerPaidWrongProduct,
  // CustomerWasDoubleCharged: locale.michargedCustomerWasDoubleCharged,
  CustomerPaidWronngAmountInput: locale.mischargeCustomerWrongAmountInput,
  Others: locale.others,
};

export default VoidPaymentNonFuelValue;
