import React, { useMemo, useCallback, useState, useEffect } from "react";
import { Pagination as MaterialPagination } from "@material-ui/lab";
import styles from "./pagination.module.scss";
import Dropdown from "../dropdown/dropdown";
import classNames from "classnames";

const Pagination = ({ page, perPage = 10, dataCount, onChangePage, onChangePageSize, className }) => {
  const [pageSize, setPageSize] = useState(perPage);

  useEffect(() => {
    setPageSize(perPage);
  }, [perPage])

  const onChangePageCb = useCallback(
    (e, val) => {
      if (onChangePage) {
        return onChangePage(val);
      }
    },
    [onChangePage]
  );

  const onChangePageSizeCb = useCallback(
    (val) => {
      setPageSize(val);
      if (onChangePageSize) {
        return onChangePageSize(val);
      }
    },
    [onChangePageSize]
  );

  const count = useMemo(() => {
    return dataCount ? Math.ceil(dataCount / pageSize) : 1;
  }, [dataCount, pageSize]);

  return (
    <div className={classNames(styles.container, className)}>
      <MaterialPagination
        page={page}
        count={count}
        className={styles.pagination}
        variant="outlined"
        shape="rounded"
        onChange={onChangePageCb}
      />
      <Dropdown
        className={styles.perPage}
        value={pageSize}
        dropdownClassName={styles.dropdown}
        onChange={onChangePageSizeCb}
        options={[
          { label: "10 / page", value: 10 },
          { label: "20 / page", value: 20 },
          { label: "30 / page", value: 30 },
          { label: "40 / page", value: 40 },
          { label: "50 / page", value: 50 },
        ]}
      />
    </div>
  );
};

export default Pagination;
