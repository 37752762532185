import { InnerLabelTextfield, TextField, Image } from "components/commons";
import React from "react";
import { Trophy } from "images";
import { formatNumber, parseNumber, isAmountValid } from "utils";

const InputPoint = ({ className, onChange, onBlur, innerLabel, disabled, ...props }) => {
  const componentProps = {
    ...props,
    className,
    onChange: (name, { value }) => {
      if (innerLabel) {
        if (isAmountValid(value) || !value) {
          onChange(name, { value });
        }
      }
    },
    onBlur: (name, { value }) => {
      if (value) {
        onChange(name, { value: `${formatNumber(value, 2)}` });
        onBlur();
      }
    },
    onFocus: () => {
      if (props.value) {
        const num = Number(parseNumber(props.value));
        onChange(props.name, { value: num || "" });
      }
    },
  };

  if (innerLabel) {
    return (
      <InnerLabelTextfield
        disabled={disabled}
        {...componentProps}
        prefix={<Image src={Trophy} style={{ width: "16px", height: "60px" }} />}
      />
    );
  }
  return <TextField disabled={disabled} {...componentProps} />;
};

export default InputPoint;
