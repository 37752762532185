import { getUserStation } from "apis";
import { Text, Title } from "components/commons";
import Header from "components/commons/header/header";
import { AppContext } from "contexts";
import { Path } from "enums";
import { useApi, useMount } from "hooks";
import { useCallback, useContext, useState } from "react";
import styles from "./select-station.module.scss";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { useHistory } from "react-router-dom";
import AppLoader from "components/commons/app-loader/app-loader";
import { Role } from "enums";
const SelectStationModule = () => {
  const [userStations, setUserStations] = useState([]);

  const history = useHistory();

  const { setPageError } = useContext(AppContext);

  const { request: getUserStationRequest, loading: fetchingUserStations } = useApi({
    api: getUserStation,
    pageError: true,
  });

  useMount(() => {
    fetchUserStations();
  });

  const fetchUserStations = useCallback(async () => {
    const res = await getUserStationRequest();
    if (res.length === 0) {
      setPageError(true);
    } else if (res.length === 1) {
      localStorage.setItem("stationId", res[0].stationId);
      const role = localStorage.getItem("role");
      const userLandingPage = [
        { role: Role.PortalAdmin, page: Path.Slash },
        { role: Role.StationCashier, page: Path.Slash },
        { role: Role.Accounting, page: Path.PaymentHistoryBusiness },
        { role: Role.LubeServ, page: Path.LubeServOnSiteBookings },
      ];
      const userPage = userLandingPage.filter((item) => item.role === role);

      history.push(userPage[0].page);
    } else {
      setUserStations(res);
    }
  }, [getUserStationRequest, setPageError, history]);

  const selectStation = useCallback(
    (stationId) => {
      localStorage.setItem("stationId", stationId);
      const role = localStorage.getItem("role");
      const userLandingPage = [
        { role: Role.PortalAdmin, page: Path.Slash },
        { role: Role.StationCashier, page: Path.Slash },
        { role: Role.Accounting, page: Path.PaymentHistoryBusiness },
        { role: Role.LubeServ, page: Path.LubeServOnSiteBookings },
      ];
      const userPage = userLandingPage.filter((item) => item.role === role);

      history.push(userPage[0].page);
    },
    [history]
  );

  return fetchingUserStations ? (
    <AppLoader />
  ) : (
    userStations.length > 1 && (
      <>
        <Header />
        <div
          style={{
            marginTop: "60px",
            padding: "27px 36px",
            minWidth: "1170px",
            overflowX: "auto",
            minHeight: "100vh",
            backgroundColor: "#ffffff",
          }}
        >
          <Title className={styles.title}>Select Station</Title>
          <div className={styles.container}>
            {userStations.map((us, i) => (
              <div key={i} className={styles.station} onClick={() => selectStation(us.stationId)}>
                <Text>{us.station?.name}</Text> <KeyboardArrowRightIcon className={styles.icon} />
              </div>
            ))}
          </div>
        </div>
      </>
    )
  );
};

export default SelectStationModule;
