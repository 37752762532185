import Validation from "utils/validation.utils";
import locale from "localization";
export const initialState = (state = {}) => {
  const {
    purchaseAmount = {
      value: "",
      disabled: false,
      error: false,
    },
    orNumber = {
      value: "",
      name: "orNumber",
      label: locale.orNumber,
      disabled: false,
      error: false,
    },
    productType = {
      value: "",
      disabled: false,
      error: false,
    },
  } = state;

  return {
    purchaseAmount: {
      name: "purchaseAmount",
      placeholder: "0.00",
      label: "Purchase Amount",
      required: true,
      max: 20000,
      validations: [
        Validation.required(),
        Validation.max(20000, {
          customMessage: locale.maxPurchasePayPoints,
          isFormatted: true,
        }),
      ],
      forceShowError: true,
      ...purchaseAmount,
    },
    orNumber: {
      validations: [
        Validation.required({ noMessage: true }),
        Validation.numeric(),
        Validation.maxlength(15),
      ],
      placeholder: "Enter OR No.",
      forceShowError: true,
      maxLength: 15,
      upperCase: true,
      required: true,
      ...orNumber,
    },
    productType: {
      name: "productType",
      label: locale.productTypeCapitalize,
      required: true,
      placeholder: "Select product",
      validations: [Validation.required({ noMessage: true })],
      ...productType,
    },
  };
};
