import locale from "localization";

export const transactionHistoryColumns = [
  { key: "vipTransactionId", text: locale.vipTransactionId, width: "8%" },
  { key: "vipCardNumber", text: locale.vipCardNumber, width: "12%" },
  { key: "mobileWithHash", text: locale.mobileWithHash, width: "15%" },
  { key: "transactionAmount", text: locale.transactionAmount, width: "8%" },
  { key: "productTypeCapitalize", text: locale.productTypeCapitalize, width: "8%" },
  { key: "pointsEarned", text: locale.pointsEarned, width: "6%" },
  { key: "orNumber", text: locale.orNumber, width: "6%" },
  {
    key: "dateOfTransaction",
    text: locale.dateOfTransaction,
    style: { minWidth: "12%" },
  },
];
