import { Modal, ActionButton, Image, Title, Text } from "components/commons";
import { useApi, useForm, useModal } from "hooks";
import React, {
  useMemo,
  useState,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import { initialState as formState } from "./vip-otp.state";
import styles from "./vip-otp.module.scss";
import { ConfirmModal } from "components/modals";
import locale from "localization";
import { useEffect } from "react";
import { SuccessActivateAccount } from "images";
import { vipPayWithPoints } from "apis/vip-points.api";
import { ErrorCode } from "enums";
import { parseNumber } from "utils";
import SuccessModalForm from "../success-modal/success-modal-form";

const VipOTP = (
  {
    initialState,
    actionText,
    otherData,
    isEdit,
    refreshPage,
    stationId,
    clearFormPayWithPoints,
    resendOTPVIP,
    amountDue,
    ...state
  },
  ref
) => {
  const { close } = state;
  const [countInvalidOTP, setCountInvalidOTP] = useState(0);
  const [resendLimit, setResendLimit] = useState(0);
  const [otpError, setOTPError] = useState("");
  const [otp, setOTP] = useState(["", "", "", ""]);
  const otpInputs = useRef([]);
  const [timer, setTimer] = useState(60); // Initial timer value (in seconds)
  const [timerFiveTimes, setTimerFiveTimes] = useState(300); // Initial timer value (in seconds)
  const [isTimerRunning, setIsTimerRunning] = useState(false); // Flag to track if timer is running
  const timerRef = useRef(null); // Ref for the timer interval
  const timerRefFiveTimes = useRef(null); // Ref for the timer interval
  const [isTimerRunningFiveTimes, setIsTimerRunningFiveTimes] = useState(false); // Flag to track if timer is running

  const successModal = useModal();
  const confirmModal = useModal();
  const form = useMemo(() => {
    return formState(initialState);
  }, [initialState]);

  const { fields, modifyField, submitForm, getFormValues, clearForm } = useForm({
    initialState: form,
  });

  useEffect(() => {
    if (state.active) {
      clearForm();
      setOTP(["", "", "", ""]);
      setOTPError("");
      setResendLimit(0);
      setCountInvalidOTP(0);
    }
    //eslint-disable-next-line
  }, [state.active]);

  const createPayWithPoints = useApi({
    api: vipPayWithPoints,
    handleOwnError: {
      badrequest: true,
    },
  });

  const loading = createPayWithPoints.loading;
  const params = getFormValues();

  const submit = async () => {
    try {
      let requestParams = {
        stationId,
        amount: parseFloat(parseNumber(otherData?.purchaseAmount)),
        productCode: otherData?.productType,
        userId: otherData?.userId,
        otp: Number(params.otp),
        points: parseFloat(parseNumber(otherData?.pointsToRedeem)),
      };

      if (
        otherData?.details?.isMobileNumber &&
        (otherData?.user?.vipType === "biz" || otherData?.user?.vipType === "public")
      ) {
        requestParams.mobileNumber = otherData?.mobileNumber?.substring(3);
      } else {
        requestParams.cardNumber = otherData?.cardNumber;
      }
      const res = await createPayWithPoints.request({
        ...requestParams,
      });

      if (res) {
        successModal.show({
          contentHighlight: [
            <b>{otherData?.pointsToRedeem} points</b>,
            <b>
              {locale.vipTransactionId} {res.pointsHistory.pointsHistoryId}
            </b>,
          ],
          transactionData: res,
          amountDue,
          otherData,
        });
        setOTPError("");
        close();
        clearForm();
        clearFormPayWithPoints();
        setCountInvalidOTP(0);
        setResendLimit(0);
      }
    } catch (error) {
      switch (error?.data?.errorCode) {
        case ErrorCode.InvalidOTPCode:
          if (countInvalidOTP === 5) {
            if (timerFiveTimes === 300) {
              handleReset();
              setTimer(60);
              startTimerFiveTimes();
            }

            setOTPError(locale.enteredInvalidOTPFiveTimes);
          } else {
            setCountInvalidOTP(countInvalidOTP + 1);
            setOTPError(locale.invalidOTP);
          }

          break;
        case ErrorCode.ExpiredOTP:
          setOTPError(locale.expiredOTP);
          break;
        case ErrorCode.InsufficientPointsBalance:
          setOTPError(error?.data?.message);
          break;
        default:
          setOTPError(error?.data?.message);
          break;
      }
    }
  };

  const handleInputChange = (e, index) => {
    setOTPError("");
    const { value } = e.target;
    if (/^\d$/.test(value) || value === "") {
      const newOTP = [...otp];
      newOTP[index] = value;
      setOTP(newOTP);

      // Move focus to the next input field if there's a value
      if (index < otp.length - 1 && value) {
        otpInputs.current[index + 1].focus();
      }

      const otpToString = newOTP?.toString();
      const data = otpToString
        .split(",")
        .map((s) => s.trim())
        .join("");
      modifyField("otp", {
        value: data,
      });
    }
  };

  // Function to handle key press (optional)
  const handleKeyPress = (e, index) => {
    // Move to the previous input field if backspace is pressed and the field is empty
    setOTPError("");
    if (e.key === "Backspace" && index > 0 && !otp[index]) {
      otpInputs.current[index - 1].focus();
    }
  };

  const isOTPComplete = useMemo(() => {
    const otpToString = fields?.otp?.value;
    if (otpToString.length === 4) {
      return true;
    }
    return false;
  }, [fields?.otp?.value]);

  // Function to start the timer
  const startTimer = useCallback(() => {
    setIsTimerRunning(true);
    timerRef.current = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          clearInterval(timerRef.current);
          setIsTimerRunning(false);
          return 0;
        }
      });
    }, 1000);
  }, []);
  //for invalid five times
  const startTimerFiveTimes = useCallback(() => {
    setIsTimerRunningFiveTimes(true);
    timerRefFiveTimes.current = setInterval(() => {
      setTimerFiveTimes((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          clearInterval(timerRefFiveTimes.current);
          setIsTimerRunningFiveTimes(false);
          return 0;
        }
      });
    }, 1000);
  }, []);
  // Function to handle timer expiration
  useEffect(() => {
    if (timer === 0) {
      clearInterval(timerRef.current);
      setIsTimerRunning(false);
      setTimer(60);
    }
  }, [timer]);

  //for five times invalid
  useEffect(() => {
    if (timerFiveTimes === 0) {
      clearInterval(timerRefFiveTimes.current);
      setIsTimerRunningFiveTimes(false);
      setCountInvalidOTP(0);
      setTimerFiveTimes(300);
    }
  }, [timerFiveTimes]);

  useEffect(() => {
    return () => {
      clearInterval(timerRef.current);
    };
  }, [timerRef]);

  useImperativeHandle(ref, () => ({
    handleStartTimer() {
      handleResetFive();
      if (!isTimerRunning) {
        startTimer();
      } else {
        handleReset();
        setTimer(60);
        startTimer();
      }
    },
    handleResetTimer() {
      handleReset();
    },
  }));

  const handleReset = useCallback(() => {
    clearInterval(timerRef.current);
    setTimer(0);
    setIsTimerRunning(false);
  }, [timerRef]);

  const handleResetFive = useCallback(() => {
    clearInterval(timerRefFiveTimes.current);
    setTimer(0);
    setIsTimerRunningFiveTimes(false);
  }, [timerRefFiveTimes]);

  const resendOTP = useCallback(async () => {
    let requestParams = {
      cardNumber: otherData.cardNumber,
      mobileNumber: `${otherData.mobileNumber}`,
      stationId,
      amount: parseFloat(parseNumber(otherData?.purchaseAmount)),
      points: parseFloat(parseNumber(otherData?.pointsToRedeem)),
      productCode: otherData.productType,
      userId: otherData?.userId ?? "",
    };

    setOTP(["", "", "", ""]);
    modifyField("otp", {
      value: "",
    });

    try {
      setResendLimit(resendLimit + 1);
      setOTPError("");
      handleReset();
      setTimer(60);
      startTimer();
      await resendOTPVIP.request({
        ...requestParams,
      });
    } catch (error) {
      switch (error?.data?.errorCode) {
        case ErrorCode.OtpLimitReached:
          clearInterval(timerRef.current);
          setIsTimerRunning(false);
          setTimer(180);
          setResendLimit(0);
          setOTPError(locale.reachedResendLimit);
          startTimer();
          break;
        default:
          setOTPError(error?.data?.message);
          return error.showError(true);
      }
    }
  }, [modifyField, otherData, resendLimit, startTimer, handleReset, resendOTPVIP, stationId]);

  return (
    <div>
      <ConfirmModal {...confirmModal} loading={loading} />
      <SuccessModalForm
        image={SuccessActivateAccount}
        title={locale.paymentSuccessful}
        content={locale.successfulPaidPointVIP}
        {...successModal}
      />
      <Modal close={loading ? false : close} {...state} className={styles.modalContent}>
        <div className={styles.formContent}>
          <Image src={SuccessActivateAccount} className={styles.image} />
          <Title small className={styles.title}>
            {locale.otpVerification}
          </Title>
          <div className={styles.content}>
            <div className={styles.bodyOTP}>
              <locale.Populate
                text={locale.enterTheOTPSentToMobile}
                items={[<b>{otherData?.mobileNumber}</b>]}
              />
              <div className={styles.otpInputContainer}>
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    value={digit}
                    onChange={(e) => handleInputChange(e, index)}
                    maxLength="1"
                    className={styles.otpInput}
                    onKeyDown={(e) => handleKeyPress(e, index)}
                    ref={(input) => (otpInputs.current[index] = input)}
                  />
                ))}
              </div>
              {otpError && (
                <Text style={{ color: "red " }} className={styles.didNotReceive}>
                  {otpError}
                </Text>
              )}
              <Text className={styles.didNotReceive}>
                {locale.didNotReceiveOTP}
                <div
                  className={
                    isTimerRunning
                      ? styles.resendLinkDisabled
                      : isTimerRunningFiveTimes
                      ? styles.resendLinkDisabled
                      : styles.resendLink
                  }
                >
                  {isTimerRunning ? (
                    <span>
                      {<locale.Populate text={locale.resendCodeIn} items={[<b>{timer} </b>]} />}
                    </span>
                  ) : (
                    <>
                      {isTimerRunningFiveTimes ? (
                        <span>
                          {
                            <locale.Populate
                              text={locale.resendCodeIn}
                              items={[<b>{timerFiveTimes} </b>]}
                            />
                          }
                        </span>
                      ) : (
                        <span
                          onClick={() => {
                            resendOTP();
                          }}
                        >
                          {locale.resend}
                        </span>
                      )}
                    </>
                  )}
                </div>
              </Text>
            </div>
          </div>
        </div>
        <ActionButton
          center
          items={[
            {
              primary: true,
              onClick: () => {
                submitForm(submit);
              },
              disabled: !isOTPComplete,
              loading,
              text: actionText,
            },
          ]}
        />
      </Modal>
    </div>
  );
};

export default forwardRef(VipOTP);
