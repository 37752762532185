import { CircularProgress } from "@material-ui/core";
import { LogoIcon } from "images";
import React from "react";
import Image from "../image/image";

const AppLoader = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        background: "#0d10314c",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          alignSelf: "center",
          margin: "auto",
          width: "50px",
          justifyContent: "space-around",
        }}
      >
        <Image
          src={LogoIcon}
          style={{
            margin: "auto",
            textAlign: "center",
            width: "25px",
            height: "25px",
            position: "absolute",
            opacity: 0.8,
          }}
        />
        <CircularProgress style={{ margin: "auto", color: "#1373d1" }} />
      </div>
    </div>
  );
};

export default AppLoader;
