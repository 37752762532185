import { ApiPath } from "enums";
import { Get, Post, Put } from "utils";

export const getStationPayments = async ({ stationId, ...query }) => {
  const res = await Get(`${ApiPath.StationPayments(stationId)}`, query);
  return res.data;
};

export const getMerchantStationPayments = async ({ stationId, ...query }) => {
  const res = await Get(`${ApiPath.MerchantStationPayments(stationId)}`, query);
  return res.data;
};

export const voidPayment = async ({ ...body }) => {
  const res = await Post(`ms-fleet/payment/void`, body);
  return res.data;
};

export const createPayment = async ({ ...body }) => {
  const res = await Post(`ms-fleet/payment`, body);
  return res.data;
};

export const createPaymentLubes = async ({ ...body }) => {
  const res = await Post(`ms-fleet/merchant/payment`, body);
  return res.data;
};

export const updatePayment = async ({ paymentId, body }) => {
  const res = await Put(`ms-fleet/payment/${paymentId}`, body);
  return res.data;
};

export const updatePaymentNonFuel = async ({ merchantPaymentId, body }) => {
  const res = await Put(`ms-fleet/merchant/payment/${merchantPaymentId}`, body);
  return res.data;
};
