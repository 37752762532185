import React from "react";
import moment from "moment";

import { Button, Title, PopOverMenu, Image, Text } from "components/commons";
import locale from "localization";
import { useExport } from "hooks";
import { generatePricingReport } from "apis";
import { PdfPlaceHolder } from "images";
import { DateTime } from "enums";

import styles from "./view-premium-payout.module.scss";

const ViewPremiumPayoutTable = ({ premiumPayoutDetails, downloadUrl }) => {
  const { periodDate, station } = premiumPayoutDetails;

  const newPeriodDate = periodDate?.split("-") ?? [];

  const exportFile = useExport({
    api: generatePricingReport,
    reportType: "redemptions-settlement",
    mappedFilterState: {
      redemptionStationId: station?.stationId,
      startDate: new Date(
        moment(`${newPeriodDate[0]} ${newPeriodDate[1]}`).startOf("month").format(DateTime.C)
      ),
      endDate: new Date(
        moment(`${newPeriodDate[0]} ${newPeriodDate[1]}`).endOf("month").format(DateTime.D)
      ),
      settlementType: "premium-payout",
    },
  });

  return (
    <>
      <div className={styles.reportContainer}>
        <Title black xsmall>
          {locale.report}
        </Title>
        <PopOverMenu
          options={[
            {
              content: locale.asExcel,
              onClick: () =>
                exportFile.exportReport({
                  fileType: "xlsx",
                }),
            },
            {
              content: locale.asPdf,
              onClick: () =>
                exportFile.exportReport({
                  fileType: "pdf",
                }),
            },
          ]}
        >
          <Button
            startIcon={<span className="icon-download" />}
            exportButton
            loading={exportFile.exporting}
          >
            {locale.export}
          </Button>
        </PopOverMenu>
      </div>
      <div className={styles.pdfContainer}>
        {downloadUrl ? (
          <embed
            style={{
              width: "100%",
              height: 673,
            }}
            src={`${downloadUrl}#toolbar=0&navpanes=0&scrollbar=0&pagemode=none`}
          />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Image src={PdfPlaceHolder} />
            <Text align={"center"} strong>
              {locale.noDataYet}
            </Text>
          </div>
        )}
      </div>
    </>
  );
};

export default ViewPremiumPayoutTable;
