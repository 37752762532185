import React, { Fragment, useCallback, useContext, useState, useEffect } from "react";
import { useApi, useMount, useWebSocket } from "hooks";
import { getStationById, getUserV2 } from "apis";
import { AppContext, StationContext, UserContext } from "contexts";
import { Redirect, Route, Switch } from "react-router";
import { Path, StationType } from "enums";
import Header from "components/commons/header/header";
import Sidebar from "components/commons/sidebar/sidebar";
import PaymentPage from "./payment.page";
import PaymentHistoryPage from "./payment-history.page";
import env from "environments/env";
import { locale } from "localization/en";
import AppLoader from "components/commons/app-loader/app-loader";
import SettlementHistoryPage from "./settlement-history.page";
import MessagesPage from "./messages.page";
import InboxPage from "./inbox.page";
import DownloadModule from "modules/download/downloads.module";
import PremiumPayoutPage from "./premium-payment.page";
import AccessAuthorizationPage from "./access-authorization.page";
import LubeServOnSiteBookingsPage from "./lubeserv-on-site-bookings.page";
import VipPage from "./vip.page";
import GenerateQrPage from "./generate-qr.page";
// import TransactionHistoryPage from "./vip-transaction-history.page";
// import DownloadDrawer from "components/commons/download-drawer/download-drawer";

const AuthorizedPage = () => {
  const { appState } = useContext(AppContext);
  const [user, setUser] = useState();
  const [newNotification, setNewNotification] = useState(false);
  const [station, setStation] = useState();
  const { message } = useWebSocket({ URL: env?.WEB_SOCKET_URL });

  const { request: getUserRequest, loading: fetchingUser } = useApi({
    api: getUserV2,
    pageError: true,
  });
  const { request: getStationRequest, loading: fetchingStation } = useApi({
    api: getStationById,
    pageError: true,
  });
  // const { request: getStationRequest, loading: fetchingStation } = useApi({
  //   api: getUserStation,
  //   pageError: true,
  // });

  useEffect(() => {
    if (message.length !== 0) {
      setNewNotification(true);
    }
  }, [message]);

  const testTitle = env.ID !== "prod" ? `${locale.testingMode}` : "";

  useMount(() => {
    fetchUser();
    fetchStation();
  });

  const fetchUser = useCallback(async () => {
    setUser(await getUserRequest());
  }, [getUserRequest]);

  // const fetchStation = useCallback(async () => {
  //   const stationId = localStorage.getItem("stationId");
  //   const res = await getStationRequest({ stationId });
  //   setStation(res);
  // }, [getStationRequest]);

  const fetchStation = useCallback(async () => {
    const station = await getStationRequest({ stationId: localStorage.getItem("stationId") });
    setStation(station);
  }, [getStationRequest]);

  const loading = fetchingStation || fetchingUser || appState?.loading;
  return (
    <UserContext.Provider value={{ user, fetchUser, loading: fetchingUser }}>
      <StationContext.Provider value={{ station, fetchStation, loading: fetchingStation }}>
        {loading ? (
          <AppLoader />
        ) : user && station ? (
          <MessagesPage>
            <Fragment>
              <Header
                setNewNotification={() => setNewNotification(false)}
                newNotification={newNotification}
                title={`${testTitle} - ${station?.name}`}
              />
              <Sidebar />
              {/* <DownloadDrawer
                isOpen={isDrawerOpen}
                drawerData={message}
                setIsOpen={setIsDrawerOpen}
              /> */}
              <div
                style={{
                  paddingLeft: "196px",
                  marginTop: "60px",
                  minWidth: "1170px",
                  minHeight: "calc(100vh - 70px)",
                  backgroundColor: "#ffffff",
                }}
              >
                <div
                  style={{
                    overflowX: "auto",
                    padding: "27px 36px",
                  }}
                >
                  <Switch>
                    <Route path={Path.Payment} component={PaymentPage} />
                    <Route path={Path.PaymentHistory} component={PaymentHistoryPage} />
                    <Route path={Path.SettlementHistory} component={SettlementHistoryPage} />
                    <Route path={Path.GenerateQr} component={GenerateQrPage} />
                    {StationType?.includes(station?.stationType) && (
                      <Route path={Path.PremiumPayout} component={PremiumPayoutPage} />
                    )}
                    <Route path={Path.Inbox} component={InboxPage} />
                    <Route path={Path.Download} component={DownloadModule} />
                    <Route path={Path.AccessAuthorization} component={AccessAuthorizationPage} />
                    <Route
                      path={Path.LubeServOnSiteBookings}
                      component={LubeServOnSiteBookingsPage}
                    />
                    <Route path={Path.VIPAccount} component={VipPage} />
                    <Redirect to={station?.isPlbOnboarded ? Path.Payment : Path.PaymentHistory} />
                  </Switch>
                </div>
              </div>
            </Fragment>
          </MessagesPage>
        ) : null}
      </StationContext.Provider>
    </UserContext.Provider>
  );
};

export default AuthorizedPage;
