import { ApiPath } from "enums";
import { Get } from "utils";

export const validateFleetCard = async ({ fleetCardNo, ...query }) => {
  const res = await Get(`${ApiPath.FleetCard}/card/${fleetCardNo}`, query);
  return res.data;
};

export const getFleetCard = async ({ fleetId }) => {
  const res = await Get(`${ApiPath.FleetCard}/fleet/${fleetId}`);
  return res.data;
};

export const getFuelCards = async ({ stationId, ...query }) => {
  const res = await Get(`${ApiPath.FleetCard}/station/${stationId}`, query);
  return res.data;
};
