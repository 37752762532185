import Validation from "utils/validation.utils";
import locale from "localization";

export const initialState = (state = {}) => {
  const {
    email = {
      value: "",
      disabled: false,
    },
    role = {
      value: "",
      disabled: false,
    },
    userAccessId = {
      value: "",
      disabled: false,
    }
  } = state;
  return {
    email: {
      name: "email",
      placeholder: locale.enterEmailAddressHere,
      validations: [Validation.required({ noMessage: true }), Validation.emailAddress()],
      maxLength: 75,
      label: locale.emailAddress,
      required: true,
      ...email,
    },
    role: {
      name: "role",
      validations: [Validation.required({ noMessage: true })],
      label: locale.accessType,
      required: true,
      ...role,
    },
    userAccessId: {
      ...userAccessId
    }
  };
};
