import React from "react";
import styles from "./reset-password.module.scss";
import { Field, Button, PasswordField } from "components/commons";
import locale from "localization";

const ResetPasswordForm = ({ ...props }) => {
  const {
    fields,
    modifyField,
    submitForm,
    handleSubmit,
    resettingPassword,
    isFormSubmittable,
    validatingToken,
    onBlurPasswordField,
  } = props;

  return (
    <>
      <Field {...fields.newPassword}>
        <PasswordField
          {...fields.newPassword}
          onChange={modifyField}
          onBlur={onBlurPasswordField}
          disabled={validatingToken}
        />
      </Field>
      <Field {...fields.confirmNewPassword}>
        <PasswordField
          {...fields.confirmNewPassword}
          onChange={modifyField}
          onBlur={onBlurPasswordField}
          disabled={validatingToken}
        />
      </Field>

      <div className={styles.buttonGroup}>
        <Button
          primary
          className={styles.cancelButton}
          loading={resettingPassword}
          onClick={() => {
            submitForm(handleSubmit);
          }}
          disabled={!isFormSubmittable || validatingToken}
        >
          {locale.continue}
        </Button>
      </div>
    </>
  );
};

export default ResetPasswordForm;
