import React from "react";
import { Path } from "enums";
import { Route, Switch, Redirect } from "react-router-dom";
import VipAccountModule from "modules/vip-account/vip-account.module";
import PanelButtons from "components/commons/panel-button/panel-buttons";
import locale from "localization";
import { BackLinkV2, Title } from "components/commons";
import styles from "../modules/vip-transaction-history/transaction-history/transaction-history.module.scss";
import TransactionHistoryModule from "modules/vip-transaction-history/transaction-history/transaction-history.module";
import RedeemHistoryModule from "modules/vip-transaction-history/redeem-points/redeem-history.module";
import PaymentHistoryForConsumer from "modules/vip-transaction-history/pay-with-points/pay-history.module";
import VipPayWithPointsModule from "modules/vip-account/pay-with-points/vip-pay.module";
import VipAwardPointsModule from "modules/vip-account/award-points/vip-award.module";
import VipRegistrationModule from "modules/vip-account/registration/vip-registration.module";
import VipRedemptionModule from "modules/vip-account/redemption/vip-redemption.module";
const TransactionHistoryPage = () => {
  const items = [
    {
      text: locale.awardPoints,
      path: Path.VIPHistoryAwardPoints,
    },
    {
      text: locale.payWithPoints,
      path: Path.VIPHistoryPayWithPoints,
    },
    {
      text: locale.redeemPoints,
      path: Path.VIPHistoryRedeemPoints,
    },
  ];

  return (
    <>
      <BackLinkV2 text={locale.backToVIPLogin} path={Path.VIPAccount} />
      <div className={styles.intro}>
        <div>
          <Title className={styles.title}>{locale.transactionHistory}</Title>
        </div>
        <div>
          <PanelButtons items={items} />
        </div>
      </div>

      <div>
        <Switch>
          <Route path={Path.VIPHistoryAwardPoints} component={TransactionHistoryModule} />
          <Route path={Path.VIPHistoryPayWithPoints} component={PaymentHistoryForConsumer} />
          <Route path={Path.VIPHistoryRedeemPoints} component={RedeemHistoryModule} />
          <Redirect to={Path.VIPHistoryAwardPoints} />
        </Switch>
      </div>
    </>
  );
};

const PointsPage = () => {
  return (
    <>
      <BackLinkV2 text={locale.backToVIPLogin} path={Path.VIPAccount} />
      <div>
        <Switch>
          <Route path={Path.VIPPayWithPoints} component={VipPayWithPointsModule} />
          <Route path={Path.VIPAwardPoints} component={VipAwardPointsModule} />
          <Route path={Path.VIPRedemption} component={VipRedemptionModule} />
          <Redirect to={Path.VIPPayWithPoints} />
        </Switch>
      </div>
    </>
  );
};

const VipPage = () => {
  return (
    <Switch>
      <Route exact path={Path.VIPAccount} component={VipAccountModule} />
      <Route path={Path.VIPTransactionHistory} component={TransactionHistoryPage} />
      <Route path={Path.VIPRegistration} component={VipRegistrationModule} />
      <Route path={Path.VIPPoints} component={PointsPage} />
      <Redirect to={Path.VIPAccount} />
    </Switch>
  );
};

export default VipPage;
