import { Button, Image, Modal, Text, Title } from "components/commons";
import React, { useContext } from "react";
import { locale } from "localization/en";
import { VoidTransactionImage } from "images";
import { MessagesContext } from "contexts";

const AnnounceVoidTransaction = () => {
  const { announcements, openMessage } = useContext(MessagesContext);
  const { voidTransaction = {} } = announcements;

  return (
    <Modal {...voidTransaction} closable={false}>
      <div
        style={{
          margin: "0px auto 10px auto",
          textAlign: "center",
        }}
      >
        <Title
          small
          style={{
            margin: "0px auto 10px auto",
            textAlign: "center",
          }}
        >
          {locale.youCanNowVoidTransactions}
        </Title>
        <Text>{locale.withNewVoidTransactionFeature}</Text>
      </div>
      <div
        style={{
          padding: "20px 50px",
        }}
      >
        <Image
          src={VoidTransactionImage}
          style={{
            width: "100%",
          }}
        />
      </div>
      <div
        style={{
          padding: "20px 50px",
        }}
      >
        <Button
          primary
          full
          style={{
            width: "100%",
          }}
          onClick={() => {
            voidTransaction.onClose();
            openMessage(voidTransaction.messageId);
          }}
        >
          {locale.learnMore}
        </Button>
        <Button
          textStyle
          variant="text"
          style={{
            width: "100%",
            margin: "20px 0px 0px 0px",
          }}
          onClick={() => {
            voidTransaction.onClose();
          }}
        >
          {locale.maybeLater}
        </Button>
      </div>
    </Modal>
  );
};

export default AnnounceVoidTransaction;
