import { ApiPath } from "enums";
import { Get, Post, Put } from "utils";

export const getRedemptions = async ({ stationId, ...query }) => {
  const res = await Get(`${ApiPath.Redemption}/station/${stationId}`, query);
  return res.data;
};

export const redeemFuelCode = async ({ ...query }) => {
  const res = await Post(`${ApiPath.Redemption}`, query);
  return res.data;
};

export const updateRedemption = async ({ redemptionId, body }) => {
  const res = await Put(`${ApiPath.Redemption}/${redemptionId}`, body);
  return res.data;
};

export const voidRedemption = async ({ ...body }) => {
  const res = await Post(`ms-fleet/redemption/void`, body);
  return res.data;
};

export const updateRedemptionRemarks = async ({ id, ...body }) => {
  const res = await Put(`${ApiPath.FleetRedemption}/${id}/remarks`, body);
  return res.data;
};
