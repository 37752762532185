import React, { useContext, useState, useCallback } from "react";

import { Intro, Text } from "components/commons";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import useDataTable from "hooks/useDataTable";
import locale from "localization";
import styles from "./non-fuel-business.module.scss";
import { mapFilterToRequest, mapDataToList } from "./non-fuel-business.mapper";
import { columns } from "./non-fuel-business-column";
import { initialFilterState } from "./non-fuel-business-filter.state";
import { useModal, useApi } from "hooks";
import NonFuelFilter from "./non-fuel-business-filter";
import { getNonFuelTransaction } from "apis/non-fuel.api";
import { ConfirmModal } from "components/modals";
import { StationContext } from "contexts";
import NonFuelBusinessVoidModal from "./non-fuel-business-void-modal";
import { voidPaymentNonFuel } from "apis/non-fuel.api";
import { formatAmount } from "utils";

const NonFuelBusinessModule = () => {
  const confirmModal = useModal();
  const voidPaymentModal = useModal();
  const [remarks, setRemarks] = useState("");
  const [value, setValue] = useState("");
  const { station } = useContext(StationContext);
  const { stationId } = station;

  const { request: voidRequest, loading: voidingRequest } = useApi({
    api: voidPaymentNonFuel,
    pageError: false,
    modalError: true,
  });

  const handleVoidPayment = ({ merchantPaymentId, amount }) => {
    setRemarks("");
    voidPaymentModal.show({
      title: `${locale.voidTransaction}?`,
      description: (
        <Text align="center">
          <locale.Populate
            text={locale.voidingThisNonFuel}
            items={[<b>{formatAmount(amount)}</b>]}
          />
        </Text>
      ),
      label: locale.reasonRemarks,
      actionText: locale.voidTransaction,
      submit: (value) => {
        onVoidPaymentCb({
          merchantPaymentId,
          value,
          amount,
        });
      },
    });
  };

  const onVoidPaymentCb = useCallback(
    async ({ merchantPaymentId, value, amount }) => {
      await voidRequest({ merchantPaymentId, remarks: value });
      voidPaymentModal.close();
      table.fetch({});

      confirmModal.show({
        title: locale.transactionVoided,
        content: (
          <locale.Populate
            text={locale.thisTransactionHasBeenVoidedNonFuel}
            items={[<b>{formatAmount(amount)}</b>]}
          />
        ),
        loading: voidingRequest,
        primary: {
          text: locale.gotIt,
          onClick: () => {
            confirmModal.close();
          },
        },
      });
      setRemarks("");
    },
    //eslint-disable-next-line
    [voidRequest, voidPaymentModal, confirmModal, voidingRequest]
  );

  const { filter, search, table } = useDataTable({
    api: {
      api: getNonFuelTransaction,
      params: { stationId, platformType: "plb" },
      mapper: (param) => {
        return param;
      },
    },
    filter: { initialState: initialFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "merchantPayments",
      mapper: (merchantPayments) =>
        mapDataToList({
          merchantPayments,
          handleVoidPayment,
        }),
      columns,
    },
  });

  return (
    <>
      <ConfirmModal {...confirmModal} />
      <Intro title={locale.plbLOCQPayNonFuel} />
      <NonFuelFilter {...filter} {...search} />
      <NonFuelBusinessVoidModal
        {...voidPaymentModal}
        remarks={remarks}
        onChangeRemarks={(v) => {
          setRemarks(v);
        }}
        loading={voidingRequest}
        onSubmit={voidPaymentModal.submit}
        value={value}
        setValue={setValue}
      />
      <div className={styles.table}>
        <DataTableV2 {...table} />
      </div>
    </>
  );
};

export default NonFuelBusinessModule;
