import {
  prettifyPayWithLOCQPayStatus,
  prettifyProduct,
  prettifySettlementStatus,
} from "utils/pretty.utils";
import { formatAmount, formatDate, maskCardNumber } from "utils";
import { DateTime, Products, SettlementStatus } from "enums";
import PayWithLOCQPayStatus from "enums/pay-with-locqpay-status";
import LabelAndCircle from "components/commons/label-and-circle/label-and-circle";
import LabelAndPill from "components/commons/label-and-pill/label-and-pill";
import { locale } from "localization/en";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { PopOverMenu, PopOver, Text, Pill } from "components/commons";
import * as moment from "moment";
import styles from "./pay-with-locqpay.module.scss";

const getProductColor = (product) => {
  return (
    {
      [Products.Diesel]: "#ffaa01",
      [Products.Gas91]: "#019f3e",
      [Products.Gas95]: "#ee3b34",
      [Products.Gas97]: "#2e4491",
    }[product] || null
  );
};

export const mapDataToList = ({
  payments,
  handleVoidPayment,
  handleReceiptGuide,
  handleActionsHistory,
}) => {
  const {
    paymentId,
    paidAt,
    productCode,
    amount,
    status = PayWithLOCQPayStatus.Success,
    settlementStatus,
    settlementId,
    voidedAt,
    vehicle,
    driver,
    fleet,
    finalAmount,
    stationBusinessName,
    stationName,
    poNumber,
    orNumber,
    pumpPrice,
    discount,
    cardNumber,
    totalDiscount,
    literVolume,
    remarks,
  } = payments;
  const options = [];

  if (
    status === PayWithLOCQPayStatus.Success &&
    moment().isBefore(moment(paidAt).add(10, "minutes"))
  ) {
    options.push({
      content: locale.voidTransaction,
      onClick: () =>
        handleVoidPayment({
          paymentId,
          finalAmount,
          settlementStatus,
          settlementId,
          businessName: fleet?.businessName,
          totalDiscount,
          amount,
        }),
    });
  }
  // else if (status === PayWithLOCQPayStatus.Voided) {
  //   options.push({
  //     content: locale.actionsHistory,
  //     onClick: () => handleActionsHistory({ voidedByEmail, status, voidedAt }),
  //   });
  // }

  options.push({
    content: locale.viewReceiptGuide,
    onClick: () => handleReceiptGuide(payments),
  });

  return {
    paymentId: <div>{paymentId}</div>,
    businessNameId: fleet ? (
      <div>
        <Text>{fleet.businessName}</Text>
        <Text className={styles.subBusinessId}>{fleet.shortName}</Text>
      </div>
    ) : (
      <></>
    ),
    paymentStationAndStationBusinessName: (
      <div>
        <Text>{stationName}</Text>
        <Text className={styles.subBusinessId}>{stationBusinessName}</Text>
      </div>
    ),
    cardNumber: (
      <div>
        <Text>{cardNumber ? maskCardNumber(cardNumber) : locale.any}</Text>
      </div>
    ),
    driversNameandId: (
      <div>
        {driver ? (
          <Text>{`${driver?.firstName} ${driver?.lastName}`} </Text>
        ) : (
          <Text>
            <i>{locale.any}</i>
          </Text>
        )}

        <Text className={styles.subBusinessId}>{driver?.driverLicenseId}</Text>
      </div>
    ),
    plateNumber: (
      <div>
        <Text> {vehicle ? vehicle.plateNumber : locale.any}</Text>
      </div>
    ),
    product: (
      <div className="min-70">
        {
          <LabelAndCircle
            label={prettifyProduct(productCode)}
            color={getProductColor(productCode)}
          />
        }
      </div>
    ),
    volume: (
      <div>
        <Text> {literVolume ?? ""}</Text>
      </div>
    ),
    paidAt: (
      <div>
        <Text className={styles.subBusinessId}>{moment(paidAt).format(DateTime.K)}</Text>
      </div>
    ),
    amount: <div className="min-100">{formatAmount(amount)}</div>,
    pumpPrice: <div className="min-100">{formatAmount(pumpPrice)}</div>,
    discount: <div className="min-100">{formatAmount(totalDiscount)}</div>,
    discountedVolume: <div className="min-100">{formatAmount(discount)}</div>,
    finalAmount: <div className="min-100">{formatAmount(finalAmount)}</div>,
    poNumber: poNumber,
    orNumber: orNumber,
    status: (
      <LabelAndPill
        label={voidedAt ? formatDate(voidedAt, DateTime.G) : ""}
        pillProps={{
          sky: status === PayWithLOCQPayStatus.Pending,
          deepRed: status === PayWithLOCQPayStatus.Failed,
          grass: status === PayWithLOCQPayStatus.Success,
          cement: status === PayWithLOCQPayStatus.Voided,
        }}
        pillLabel={prettifyPayWithLOCQPayStatus(status)}
      />
    ),
    remarks: (
      <div>
        {remarks !== null ? (
          <PopOver content={<div className={styles.remarks}>{remarks}</div>}>
            <div className="link">View</div>
          </PopOver>
        ) : (
          ""
        )}
      </div>
    ),
    settlementStatus: (
      <>
        <Pill
          grass={settlementStatus === SettlementStatus.Processed}
          cyan={settlementStatus === SettlementStatus.ForSettlement}
          sky={settlementStatus === SettlementStatus.ForProcessing}
        >
          {prettifySettlementStatus(settlementStatus)}
        </Pill>
        {status !== PayWithLOCQPayStatus.Voided &&
        (settlementStatus === SettlementStatus.ForProcessing ||
          settlementStatus === SettlementStatus.Processed) ? (
          <Text className={styles.subBusinessId}>{settlementId}</Text>
        ) : (
          <Text className={styles.subBusinessId}>--</Text>
        )}
      </>
    ),
    options: (
      <PopOverMenu options={options}>
        <MoreVertIcon className={styles.icon} />
      </PopOverMenu>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { productCodes, settlementStatuses, status, ...fs } = filterState;
  return {
    ...fs,
    productCodes: productCodes.length < 4 ? productCodes.join(",") : null,
    settlementStatuses: !settlementStatuses.isSelectedAll
      ? settlementStatuses.value.join(",")
      : null,
    paymentStatus: !status.isSelectedAll ? status.value.join(",") : null,
    status: !status.isSelectedAll ? status.value.join(",") : null,
  };
};
