import { Card, IconButton, Tooltip } from "@material-ui/core";
import { Image, Text } from "components/commons";
import style from "./vip-account.module.scss";
import classNames from "classnames";

const ValidationCard = ({ label, icon, iconSelected, onClick, isSelected, disabled }) => {
  return (
    <>
      <Tooltip title={disabled ? "Not available this time." : ""} followCursor>
        <Card
          style={
            disabled
              ? { cursor: "not-allowed", backgroundColor: "#0000001f", filter: "grayscale(80%)" }
              : { cursor: "pointer" }
          }
          className={classNames([style.card, isSelected ? style.cardActive : null])}
          variant="outlined"
          onClick={disabled ? "" : onClick}
        >
          <IconButton style={disabled ? { cursor: "not-allowed" } : { cursor: "pointer" }}>
            <Image className={style.icon} src={isSelected ? iconSelected : icon}></Image>
          </IconButton>
          <Text small className={isSelected ? style.labelSelected : style.label}>
            {label}
          </Text>
        </Card>
      </Tooltip>
    </>
  );
};

export default ValidationCard;
