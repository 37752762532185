import React from "react";
import { Field, Text, Title } from "components/commons";

import { DateTime, Products } from "enums";
import locale from "localization";
import { formatDate, formatAmount, formatVolume } from "utils";

import styles from "./view-premium-payout.module.scss";
import { Skeleton } from "@material-ui/lab";
import { prettifyProduct } from "utils/pretty.utils";

const ViewPremiumPayoutForm = ({
  premiumPayoutDetails: {
    periodDate,
    station,
    totalPayWithPeso,
    totalLiterRedemption,
    totalLiterRedemptionDiesel,
    totalLiterRedemptionGas91,
    totalLiterRedemptionGas95,
    totalLiterRedemptionGas97,
    totalPremiumPayout,
    totalPremiumPayoutDiesel,
    totalPremiumPayoutGas91,
    totalPremiumPayoutGas95,
    totalPremiumPayoutGas97,
  },
  premiumPayoutLoading
}) => {

  return (
    <div>
      <Title black xsmall>
        {locale.basicInformation}
      </Title>
      <div
        style={{
          width: "700px",
        }}
      >
        <Field
          className={styles.fieldLabel}
          labelClassName={styles.fieldLabelInfo}
          label={locale.month}
          labelPosition="left"
          childrenClassName={styles.fieldContent}
        >
          {premiumPayoutLoading ? (
            <Skeleton
              style={{
                width: "200px",
              }}
            />
          ) : (
            formatDate(periodDate?.split("-")[1], DateTime.I)
          )}
        </Field>
        <Field
          className={styles.fieldLabel}
          labelClassName={styles.fieldLabelInfo}
          label={locale.year}
          labelPosition="left"
          childrenClassName={styles.fieldContent}
        >
          {premiumPayoutLoading ? (
            <Skeleton
              style={{
                width: "200px",
              }}
            />
          ) : (
            formatDate(periodDate?.split("-")[0], DateTime.H)
          )}
        </Field>
        <Field
          className={styles.fieldLabel}
          labelClassName={styles.fieldLabelInfo}
          label={locale.redemptionStation}
          labelPosition="left"
          childrenClassName={styles.fieldContent}
        >
          {premiumPayoutLoading ? (
            <Skeleton
              style={{
                width: "200px",
              }}
            />
          ) : (
            station?.name
          )}
        </Field>
        <Field
          className={styles.fieldLabel}
          labelClassName={styles.fieldLabelInfo}
          label={locale.stationBusinessName}
          labelPosition="left"
          childrenClassName={styles.fieldContent}
        >
          {premiumPayoutLoading ? (
            <Skeleton
              style={{
                width: "200px",
              }}
            />
          ) : (
            station?.businessName
          )}
        </Field>
      </div>
      <div
        style={{
          display: "flex",
          width: 900,
        }}
      >
        <Field
          className={styles.fieldContainer}
          label={locale.totalLiters}
          labelClassName={styles.fieldLabelInfo}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
        >
          {premiumPayoutLoading ? (
            <Skeleton
              style={{
                width: "200px",
              }}
            />
          ) : (
            <div>
              <Text strong>
                {totalLiterRedemption ? formatVolume(totalLiterRedemption) : "0.00"}
              </Text>
              <Field
                className={styles.fieldContainer}
                label={prettifyProduct(Products.Diesel)}
                labelPosition={"left"}
                labelClassName={styles.fieldLabelProductBreakdown}
              >
                <Text>
                  {formatVolume(totalLiterRedemptionDiesel ? totalLiterRedemptionDiesel : 0)}
                </Text>
              </Field>
              <Field
                className={styles.fieldContainer}
                label={prettifyProduct(Products.Gas91)}
                labelPosition={"left"}
                labelClassName={styles.fieldLabelProductBreakdown}
              >
                <Text>
                  {formatVolume(totalLiterRedemptionGas91 ? totalLiterRedemptionGas91 : 0)}
                </Text>
              </Field>
              <Field
                className={styles.fieldContainer}
                label={prettifyProduct(Products.Gas95)}
                labelPosition={"left"}
                labelClassName={styles.fieldLabelProductBreakdown}
              >
                <Text>
                  {formatVolume(totalLiterRedemptionGas95 ? totalLiterRedemptionGas95 : 0)}
                </Text>
              </Field>
              <Field
                className={styles.fieldContainer}
                label={prettifyProduct(Products.Gas97)}
                labelPosition={"left"}
                labelClassName={styles.fieldLabelProductBreakdown}
              >
                <Text>
                  {formatVolume(totalLiterRedemptionGas97 ? totalLiterRedemptionGas97 : 0)}
                </Text>
              </Field>
            </div>
          )}
        </Field>
      </div>
      <div
        style={{
          display: "flex",
          width: 900,
        }}
      >
        <Field
          className={styles.fieldContainer}
          label={locale.totalPremium}
          labelClassName={styles.fieldLabelInfo}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
        >
          {premiumPayoutLoading ? (
            <Skeleton
              style={{
                width: "200px",
              }}
            />
          ) : (
            <div>
              <Text strong>
                {totalPremiumPayout ? formatAmount(totalPremiumPayout) : "0.00"}
              </Text>
              <Field
                className={styles.fieldContainer}
                label={prettifyProduct(Products.Diesel)}
                labelPosition={"left"}
                labelClassName={styles.fieldLabelProductBreakdown}
              >
                <Text>
                  {formatAmount(totalPremiumPayoutDiesel ? totalPremiumPayoutDiesel : 0)}
                </Text>
              </Field>
              <Field
                className={styles.fieldContainer}
                label={prettifyProduct(Products.Gas91)}
                labelPosition={"left"}
                labelClassName={styles.fieldLabelProductBreakdown}
              >
                <Text>
                  {formatAmount(totalPremiumPayoutGas91 ? totalPremiumPayoutGas91 : 0)}
                </Text>
              </Field>
              <Field
                className={styles.fieldContainer}
                label={prettifyProduct(Products.Gas95)}
                labelPosition={"left"}
                labelClassName={styles.fieldLabelProductBreakdown}
              >
                <Text>
                  {formatAmount(totalPremiumPayoutGas95 ? totalPremiumPayoutGas95 : 0)}
                </Text>
              </Field>
              <Field
                className={styles.fieldContainer}
                label={prettifyProduct(Products.Gas97)}
                labelPosition={"left"}
                labelClassName={styles.fieldLabelProductBreakdown}
              >
                <Text>
                  {formatAmount(totalPremiumPayoutGas97 ? totalPremiumPayoutGas97 : 0)}
                </Text>
              </Field>
            </div>
          )}
        </Field>
      </div>
      <div style={{width: "700px"}}>
        <Field
            className={styles.fieldLabel}
            labelClassName={styles.fieldLabelInfo}
            label={locale.totalLocqpay}
            labelPosition="left"
            childrenClassName={styles.fieldContent}
          >
            {premiumPayoutLoading ? (
              <Skeleton
                style={{
                  width: "200px",
                }}
              />
            ) : (
              formatAmount(totalPayWithPeso)
            )}
        </Field>
      </div>
    </div>
  );
};

export default ViewPremiumPayoutForm;
