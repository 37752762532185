import React from "react";

import {
  Button, 
  Title, 
  PopOverMenu
} from "components/commons";

import locale from "localization";

import styles from "./view-settlement-history.module.scss"
import { useExport } from "hooks";
import { generatePricingReport } from "apis";

const ViewSettlementHistoryTable = ({ settlementDetails: { downloadUrl }, settlementId}) => {
  
  const exportFile = useExport({
    api: generatePricingReport,
    reportType: "redemptions-settlement",
    mappedFilterState: { settlementId, settlementType: "dealer" },
  });
  
  return (
    <>
      <div className={styles.reportContainer}>
        <Title black xsmall>
          {locale.report}
        </Title>
        <PopOverMenu
          options={[
            {
              content: locale.asExcel,
              onClick: () => exportFile.exportReport({ fileType: "xlsx" }),
            },
            {
              content: locale.asPdf,
              onClick: () => exportFile.exportReport({ fileType: "pdf" }),
            },
          ]}
        >
          <Button 
            startIcon={<span className="icon-download" />} 
            exportButton
            loading={exportFile.exporting}
          >
            {locale.export}
          </Button>
        </PopOverMenu>
      </div>
      {
        downloadUrl &&
        <embed style={{
            width:"100%", 
            height: 673
          }}
          src={`${downloadUrl}#toolbar=0&navpanes=0&scrollbar=0&pagemode=none`}
        />
      }
    </>
  )
}

export default ViewSettlementHistoryTable 