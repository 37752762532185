import { ApiPath } from "enums";
import { Get, Post } from "utils";

export const getStationInvite = async ({ token }) => {
  const res = await Get(`${ApiPath.Station}/token/${token}`);
  return res.data;
};

export const acceptInvite = async ({ ...body }) => {
  const res = await Post(`${ApiPath.Station}/invite/accept`, body);
  return res.data;
};

export const getStationById = async ({ stationId }) => {
  const res = await Get(`${ApiPath.Station}/${stationId}`);
  return res.data;
};
