import classNames from "classnames";
import { Text, FuelIcon } from "components/commons";
import React from "react";
import { prettifyProduct } from "utils/pretty.utils";
import styles from "./gas-fuel-card.module.scss";

const GasFuelCard = ({ type, active, onChange, disabled, ...props }) => {
  const { name } = props;
  return (
    <div
      className={classNames(styles.fuelCard, {
        [`${styles.active}`]: active,
        [`${styles.disabled}`]: disabled,
      })}
      onClick={() => {
        onChange(name, {
          value: type,
        });
      }}
    >
      <div className={styles.nameBalance}>
        <FuelIcon type={type} disabled={disabled} />
        <div>
          <Text className={classNames(styles.title, { [`${styles.disabled}`]: disabled })}>
            {prettifyProduct(type)}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default GasFuelCard;
