import React from "react";
import PaymentHistory from "../payment-history/payment-history";
import locale from "localization";

const PaymentHistoryForConsumer = () => {
  const paymentHistoryColumns = [
    { key: "redemptionId", text: locale.redemptionId, style: { minWidth: "8%" } },
    { key: "redeemedAt", text: locale.redemptionDate, style: { minWidth: "10%" } },
    { key: "product", text: locale.product, style: { minWidth: "6%" } },
    { key: "volume", text: locale.volume, style: { minWidth: "6%" } },
    { key: "averageCreditPrice", text: locale.averageCreditPrice, style: { minWidth: "6%" } },
    { key: "totalCreditAmount", text: locale.totalCreditAmount, style: { minWidth: "10%" } },
    { key: "status", text: locale.status, style: { minWidth: "10%" } },
    {
      key: "settlementStatus",
      text: `${locale.settlementStatus}/${locale.settlementId}`,
      style: { minWidth: "10%" },
    },
    { key: "remarks", text: locale.remarks, style: { minWidth: "10%" } },
    { key: "options", text: locale.actions, style: { minWidth: "5%" } },
  ];

  return <PaymentHistory title={locale.consumer} columns={paymentHistoryColumns} />;
};

export default PaymentHistoryForConsumer;
