import React from "react";
import { Button as MaterialButton, CircularProgress } from "@material-ui/core";
import classNames from "classnames";
import styles from "./button.module.scss";

const Button = ({
  className,
  variant = "contained",
  loading,
  disabled,
  children,
  onClick,
  primary,
  link,
  outline,
  startIcon,
  outlineSquare,
  textStyle,
  exportButton,
  importButton,
  style,
}) => {
  return (
    <div>
      <MaterialButton
        startIcon={startIcon}
        style={style}
        className={classNames(className, styles.button, {
          [`${styles.disabled}`]: disabled,
          [`${styles.primary}`]: primary,
          [`${styles.link}`]: link,
          [`${styles.outline}`]: outline,
          [`${styles.outlineSquare}`]: outlineSquare,
          [`${styles.text}`]: textStyle,
          [`${styles.exportbutton}`]: exportButton || importButton,
        })}
        variant={variant}
        disabled={disabled}
        onClick={disabled || loading ? null : onClick}
      >
        {loading ? (
          <CircularProgress
            className={classNames(styles.circular, { [`${styles.primary}`]: primary })}
          />
        ) : (
          children
        )}
      </MaterialButton>
    </div>
  );
};

export default Button;
