import React from "react";

import styles from "./non-fuel-business-filter.module.scss";
import { Autocomplete, Field, SearchField, Filter, DateRange } from "components/commons";
import locale from "localization";
import { prettifySettlementStatus } from "utils/pretty.utils";
import { SettlementStatus } from "enums";
import { redemptionStatusOptions } from "data/select-options";

const NonFuelBusinessFilter = ({
  filterState,
  applyFilter,
  applyClearFilter,
  resetFilter,
  modifyFilter,
  searchKey,
  modifySearch,
  applySearch,
  applyClearSearch,
  filterCount,
}) => {
  return (
    <div className={styles?.container}>
      <div className={styles?.filter}>
        <Filter
          submit={applyFilter}
          clear={applyClearFilter}
          close={resetFilter}
          filterCount={filterCount}
        >
          <Field
            className={styles.date}
            labelPosition={"left"}
            label={locale.transactionDate}
            childrenClassName={styles.fieldContent}
          >
            <DateRange
              value={{
                startDate: filterState.startDate,
                endDate: filterState.endDate,
              }}
              onChange={(_, { value }) => {
                const { startDate, endDate } = value;
                modifyFilter({
                  startDate,
                  endDate,
                });
              }}
            />
          </Field>
          <Field
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            className={styles.fieldSelect}
            label={locale.status}
          >
            <Autocomplete
              capitalize={false}
              name={"status"}
              hasAll
              multiple
              placeholder="Select Status"
              value={filterState.status.value}
              options={redemptionStatusOptions}
              onChange={(name, { value, isSelectedAll }) => {
                modifyFilter({ [name]: { value, isSelectedAll } });
              }}
            />
          </Field>
          <Field
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            className={styles.fieldSelect}
            label={locale.settlementStatus}
          >
            <Autocomplete
              name={"settlementStatuses"}
              hasAll
              multiple
              placeholder="Select Settlement Status"
              value={filterState?.settlementStatuses.value}
              options={[
                {
                  label: prettifySettlementStatus(SettlementStatus.ForSettlement),
                  value: SettlementStatus.ForSettlement,
                },
                {
                  label: prettifySettlementStatus(SettlementStatus.ForProcessing),
                  value: SettlementStatus.ForProcessing,
                },
                {
                  label: prettifySettlementStatus(SettlementStatus.Processed),
                  value: SettlementStatus.Processed,
                },
              ]}
              onChange={(name, { value, isSelectedAll }) => {
                modifyFilter({ [name]: { value, isSelectedAll } });
              }}
            />
          </Field>
        </Filter>
        <Field className={styles.search}>
          <SearchField
            restrict={false}
            searchWithSymbols
            placeholder={locale.nonFuelSearch}
            value={searchKey}
            onChange={(_, { value }) => {
              modifySearch(value);
            }}
            onEnter={applySearch}
            onClear={applyClearSearch}
          />
        </Field>
      </div>
    </div>
  );
};

export default NonFuelBusinessFilter;
