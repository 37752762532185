import {
  Autocomplete,
  DateRange,
  Field,
  Filter,
  GroupCheckbox,
  SearchField,
  Button,
} from "components/commons";
import { Products, SettlementStatus } from "enums";
import React from "react";
import { prettifyProduct, prettifySettlementStatus } from "utils/pretty.utils";
import styles from "./payment-history-filter.module.scss";
import locale from "localization";
import classNames from "classnames";
import { redemptionStatusOptions } from "data/select-options";

const PaymentHistoryFilter = ({
  filterState,
  applyFilter,
  applyClearFilter,
  applyResetFilter,
  modifyFilter,
  modifySearch,
  applySearch,
  applyClearSearch,
  searchKey,
  filterCount,
  exportReport,
  exporting,
}) => {
  const { productCodes } = filterState;
  return (
    <div className={styles.filter}>
      <Filter
        submit={applyFilter}
        clear={applyClearFilter}
        close={applyResetFilter}
        filterCount={filterCount}
        disabled={!productCodes.length}
      >
        <Field className={styles.date} label={locale.date} labelPosition="left">
          <DateRange
            className={styles.dateRange}
            value={{
              startDate: filterState.startDate,
              endDate: filterState.endDate,
            }}
            onChange={(_, { value }) => {
              const { startDate, endDate } = value;
              modifyFilter({
                startDate,
                endDate,
              });
            }}
          />
        </Field>
        <Field
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
          className={styles.fieldSelect}
          label={locale.status}
        >
          <Autocomplete
            capitalize={false}
            name={"redemptionStatuses"}
            hasAll
            multiple
            placeholder="Select Status"
            value={filterState.redemptionStatuses.value}
            options={redemptionStatusOptions}
            onChange={(_, { value, isSelectedAll }) => {
              modifyFilter({ redemptionStatuses: { value, isSelectedAll } });
            }}
          />
        </Field>
        <Field
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
          className={styles.fieldSelect}
          label={locale.settlementStatus}
        >
          <Autocomplete
            name={"settlementStatuses"}
            hasAll
            multiple
            placeholder="Select Settlement Status"
            value={filterState?.settlementStatuses.value}
            options={[
              {
                label: prettifySettlementStatus(SettlementStatus.ForSettlement),
                value: SettlementStatus.ForSettlement,
              },
              {
                label: prettifySettlementStatus(SettlementStatus.ForProcessing),
                value: SettlementStatus.ForProcessing,
              },
              {
                label: prettifySettlementStatus(SettlementStatus.Processed),
                value: SettlementStatus.Processed,
              },
            ]}
            onChange={(_, { value, isSelectedAll }) => {
              modifyFilter({ settlementStatuses: { value, isSelectedAll } });
            }}
          />
        </Field>
        <GroupCheckbox
          className={styles.productCode}
          title={locale.availableProducts}
          name={"productCodes"}
          options={[
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.diesel)}></div>
                  {prettifyProduct(Products.Diesel)}
                </div>
              ),
              value: Products.Diesel,
            },
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.gas91)}></div>
                  {prettifyProduct(Products.Gas91)}
                </div>
              ),
              value: Products.Gas91,
            },
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.gas95)}></div>
                  {prettifyProduct(Products.Gas95)}
                </div>
              ),
              value: Products.Gas95,
            },
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.gas97)}></div>
                  {prettifyProduct(Products.Gas97)}
                </div>
              ),
              value: Products.Gas97,
            },
          ]}
          value={productCodes}
          onChange={(name, { value }) => {
            modifyFilter({ [name]: value });
          }}
        />
      </Filter>

      <Field className={styles.search}>
        <SearchField
          restrict={false}
          placeholder={[locale.redemptionId]}
          value={searchKey}
          onChange={(_, { value }) => {
            return modifySearch(value);
          }}
          onEnter={applySearch}
          onClear={applyClearSearch}
        />
      </Field>
      <Field className={styles.downloadButton}>
        <Button
          startIcon={<span className="icon-download" />}
          exportButton
          onClick={exportReport}
          disabled={exporting}
          loading={exporting}
        >
          {locale.export}
        </Button>
      </Field>
    </div>
  );
};

export default PaymentHistoryFilter;
