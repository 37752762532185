import { formatNumber } from "utils";
import { capitalize } from "utils/text.utils";
import moment from "moment";
import { DateTime } from "enums";
export const mapDataToList = ({ conversionHistory }) => {
  const { pointsHistoryId, cardNumber, points, mobileNumber, rewardRedeemed, createdAt } =
    conversionHistory;
  return {
    vipTransactionId: <div className="min-60 nowrap">{pointsHistoryId}</div>,
    vipCardNumber: <div>{cardNumber || "-"}</div>,
    mobileWithHash: <div>{mobileNumber || "-"}</div>,
    pointsRedeemed: <div>{formatNumber(points, 2)} pts</div>,
    dateOfTransaction: <div className="min-60 nowrap">{moment(createdAt).format(DateTime.L)}</div>,
    rewardsRedeemed: <div>{rewardRedeemed ? capitalize(rewardRedeemed) : "--"}</div>,
  };
};

export const mapFilterToRequest = (filterState) => {
  const { ...fs } = filterState;
  return {
    ...fs,
  };
};
