import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import { getSettlementHistory, generateStationPricingReport } from "apis";
import { StationContext } from "contexts";
import { Intro } from "components/commons";
import useDataTable from "hooks/useDataTable";
import locale from "localization";
import { useExport } from "hooks";

import { settlementHistoryFilterState } from "./settlement-history-filter.state";
import { mapFilterToRequest, mapDataToList } from "./settlement-history.mapper";
import { settlementHistoryColumns } from "./settlement-history-columns";
import SettlementHistoryFilter from "./settlement-history-filter";
import SettlementHistoryList from "./settlement-history.list";
import styles from "./settlement-history.module.scss";

const SettlementHistoryModule = () => {
  const history = useHistory();

  const { station } = useContext(StationContext);
  const { stationId } = station;

  const { filter, search, table } = useDataTable({
    api: { api: getSettlementHistory, params: { redemptionStationId: stationId } },
    filter: { initialState: settlementHistoryFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "settlements",
      mapper: (settlements) =>
        mapDataToList({
          settlements,
          history,
        }),
      columns: settlementHistoryColumns,
    },
  });

  const exportReport = useExport({
    api: generateStationPricingReport,
    reportType: "settlements",
    mappedFilterState: {
      ...filter.mappedFilterState,
      redemptionStationId: stationId,
      searchKey: search.searchKey,
      settlementType: "dealer",
    },
  });

  return (
    <>
      <Intro title={locale.settlementHistory} subtitle={locale.viewTrackSettlement} />
      <SettlementHistoryFilter {...filter} {...search} {...exportReport} />
      <div className={styles.table}>
        <SettlementHistoryList table={table} />
      </div>
    </>
  );
};

export default SettlementHistoryModule;
