import Validation from "utils/validation.utils";

export const initialState = (state = {}) => {
  const {
    cardNumber = {
      value: "",
      disabled: false,
    },
  } = state;

  return {
    cardNumber: {
      name: "cardNumber",
      placeholder: "0000000000000000",
      maxLength: 12,
      minLength: 12,
      required: true,
      validations: [Validation.required({ noMessage: true }), Validation.alphaNumeric()],
      ...cardNumber,
    },
  };
};
