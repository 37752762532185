import { SettlementStatus } from "enums";
export const settlementHistoryFilterState = () => ({
  searchKey: "",
  startDate: null,
  endDate: null,
  discountVoucherStatuses: {
    isSelectedAll: true,
    value: [SettlementStatus.ForProcessing, SettlementStatus.Processed],
  },
  dealerSettlementStatuses: {
    isSelectedAll: true,
    value: [SettlementStatus.ForProcessing, SettlementStatus.Processed],
  },
  perPage: 10,
  page: 1,
});
