import React from "react";
import { Route, Redirect } from "react-router-dom";
import Path from "../enums/path";

const AuthorizedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (localStorage.getItem("accessToken")) {
          if (localStorage.getItem("stationId")) {
            return <Component {...props} />;
          } else {
            return <Redirect to={`${Path.SelectStation}`} />;
          }
        } else {
          return <Redirect to={`${Path.Auth}`} />;
        }
      }}
    />
  );
};

export default AuthorizedRoute;
