import RedemptionStatus from "enums/redemption-status";
import { prettifyRedemptionStatus } from "utils/pretty.utils";

export const mapOptions = (values = [], prettifier = () => {}) => {
  return [
    ...values.map((value) => ({
      label: prettifier(value),
      value,
    })),
  ];
};

export const redemptionStatusOptions = mapOptions(
  [RedemptionStatus.Success, RedemptionStatus.Voided],
  prettifyRedemptionStatus
);
