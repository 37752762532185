import Validation from "utils/validation.utils";
import locale from "localization";
export const initialState = (state = {}) => {
  const {
    purchaseAmount = {
      value: "",
      error: false,
      forceShowError: true,
    },
    pointsToRedeem = {
      value: "",
      error: true,
      forceShowError: true,
    },
    productType = {
      value: "",
      error: false,
    },
  } = state;

  return {
    purchaseAmount: {
      name: "purchaseAmount",
      placeholder: "0.00",
      label: "Purchase Amount",
      required: true,
      max: 20000,
      validations: [
        Validation.required(),
        Validation.min(1, {
          customMessage: locale.minPurchasePayPoints,
          isFormatted: true,
        }),
        Validation.max(20000, {
          customMessage: locale.maxPurchasePayPoints,
          isFormatted: true,
        }),
      ],
      forceShowError: true,
      ...purchaseAmount,
    },
    pointsToRedeem: {
      name: "pointsToRedeem",
      placeholder: "0.00",
      label: "Points to Redeem",
      required: true,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.min(1, {
          customMessage: locale.minPointsToRedeem,
          isFormatted: true,
        }),
      ],
      forceShowError: true,
      ...pointsToRedeem,
    },
    productType: {
      name: "productType",
      label: locale.productType,
      required: true,
      placeholder: "Select product",
      validations: [Validation.required({ noMessage: true })],
      ...productType,
    },
  };
};
