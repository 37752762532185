import { Button, FuelIcon, Text, Title } from "components/commons";
import React from "react";
import locale from "localization";
import styles from "./insufficient-error-page.module.scss";
import { prettifyProduct } from "utils/pretty.utils";

const InsufficientErrorPage = ({
  loading,
  details,
  ...modal
}) => {

  return (
    <div>
      <div className={styles.failed}>
        <div className={styles.content}>
          <Title medium className={styles.title}>{locale.insufficientLiterBalance}</Title>
          <Text>
            <locale.Populate
              text={locale.forText}
              className={styles.fuel}
              items={[
                <span className={styles.fuel}>
                  <FuelIcon className={styles.icon} type={details.productCode} />
                  {prettifyProduct(details.productCode)}
                </span>,
              ]}
            />
          </Text>
          <Text className={styles.text}>{locale.pleaseContactYourFleetAdministrator}</Text>
          <Button
            primary
            className={styles.gotItButton}
            onClick={() => {
              modal.close()
            }}>
            {locale.gotIt}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InsufficientErrorPage;
