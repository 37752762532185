import { Button, Image, Text, Title } from "components/commons";
import React from "react";
import locale from "localization";
import styles from "./payment-error-page.module.scss";
import { PaymentFailed } from "images";

const PaymentErrorPage = ({ loading, handleBackToValidatePayment }) => {
  return (
    <div>
      <div className={styles.failed}>
        <Title className={styles.subtitle}>{locale.paymentFailed}</Title>
        <Image src={PaymentFailed} className={styles.failedIcon} />
        <Title className={styles.title}>{locale.unableToProcessPayment}</Title>
        <Text className={styles.text}>{locale.anErrorEncountered}</Text>
        <Text className={styles.text}>{locale.pleaseTryAgain}</Text>
        <Button primary className={styles.gotItButton} onClick={handleBackToValidatePayment}>
          {locale.processNewFuelCode}
        </Button>
      </div>
    </div>
  );
};

export default PaymentErrorPage;
