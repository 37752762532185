import locale from "localization";

export const initialState = {
  newPassword: {
    name: "newPassword",
    placeholder: "",
    value: "",
    maxLength: 75,
    label: locale.pleaseEnterNewPassword,
    required: true,
  },
  confirmNewPassword: {
    name: "confirmNewPassword",
    placeholder: "",
    value: "",
    maxLength: 75,
    label: locale.confirmNewPassword,
    required: true,
  },
};
