import Validation from "utils/validation.utils";
import locale from "localization";

export const initialState = {
  fuelCode: {
    name: "fuelCode",
    placeholder: "",
    value: "",
    validations: [
      Validation.required({ noMessage: true }),
      Validation.alphaNumeric({ removeLast: true }),
      Validation.minlength({ length: 10, noMessage: true }),
      Validation.maxlength({ length: 10, noMessage: true }),
    ],
    maxLength: 10,
    label: locale.fuelCode,
    upperCase: true,
    required: false,
  },
  fleetCardNo: {
    name: "fleetCardNo",
    placeholder: "",
    value: "",
    validations: [
      Validation.required({ noMessage: true }),
      Validation.alphaNumeric({ removeLast: true }),
      Validation.minlength({ length: 16, noMessage: true }),
      Validation.maxlength({ length: 16, noMessage: true }),
    ],
    maxLength: 16,
    label: locale.fleetCardNo,
    upperCase: true,
    required: false,
  },
  plbFleetCardNo: {
    name: "plbFleetCardNo",
    placeholder: locale.plbFleetCardNo,
    value: "",
    validations: [Validation.required({ customMessage: locale.plbFleetCardNoMessage })],
    maxLength: 16,
    label: locale.fleetCardNo,
    upperCase: true,
    required: false,
  },
  purchaseOrderNo: {
    name: "purchaseOrderNo",
    label: locale.purchaseOrderNo,
    required: false,
    value: "",
    validations: [Validation.required({ noMessage: true })],
  },
  paymentMethod: {
    name: "paymentMethod",
    placeholder: "",
    value: "",
    validations: [Validation.required({ noMessage: true })],
    label: locale.selectPaymentMethod,
    required: true,
  },
};
