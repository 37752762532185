export const initialState = (state = {}) => {
  const {
    points = {
      value: "",
      disabled: false,
      error: false,
    },
  } = state;

  return {
    points: {
      name: "points",

      required: true,
      forceShowError: true,
      ...points,
    },
  };
};
