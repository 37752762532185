import { formatAmount, formatNumber } from "utils";
import { prettifyProduct } from "utils/pretty.utils";
import moment from "moment";
import { DateTime } from "enums";
export const mapDataToList = ({ payHistory }) => {
  const {
    pointsHistoryId,
    cardNumber,
    points,
    mobileNumber,
    amount,
    productCode,
    transactionId,
    finalAmount,
    createdAt,
  } = payHistory;

  return {
    vipTransactionId: <div className="min-60 nowrap">{pointsHistoryId}</div>,
    vipCardNumber: <div>{cardNumber || "-"}</div>,
    mobileWithHash: <div>{mobileNumber || "-"}</div>,
    transactionAmount: <div>{formatAmount(amount)}</div>,
    pointsRedeemed: <div>{formatNumber(points, 2)} pts</div>,
    productTypeCapitalize: <div>{prettifyProduct(productCode)}</div>,
    finalAmountPaid: <div>{formatAmount(finalAmount)}</div>,
    orNumber: <div>{transactionId}</div>,
    dateOfTransaction: <div className="min-60 nowrap">{moment(createdAt).format(DateTime.L)}</div>,
  };
};

export const mapFilterToRequest = (filterState) => {
  const { ...fs } = filterState;
  return {
    ...fs,
  };
};
