import React from "react";

import { Intro } from "components/commons";
import useDataTable from "hooks/useDataTable";
import locale from "localization";

import { mapDataToList, mapFilterToRequest } from "./lubeserv-bookings.mapper";
import { lubeServOnSiteBookingsFilterState } from "./lubeserv-on-site-bookings-filter.state";
import { getLubeServBookings } from "apis/booking.api";
import LubeServBookingsFilter from "./lubeserv-bookings-filter";
import DataTableV2 from "components/commons/data-table/data-table-v2";

import { SuccessModal } from "components/modals";
import useExport from "hooks/useExport";
import { lubeServOnSiteBookingsColumns } from "./lubeserv-on-site-bookings-columns";
import { generateBookingReport } from "apis/generate-report.api";
import { useContext } from "react";
import { StationContext } from "contexts";
import { LubeServSite } from "enums";

const LubeServBookingsModule = () => {
  const { station } = useContext(StationContext);
  const { stationId, stationCode } = station;

  const { filter, search, table } = useDataTable({
    api: {
      api: getLubeServBookings,
      params: {
        bookingTypes: "lubeserv-on-site",
        stationId,
        lubeServSite:
          stationCode === 4377
            ? LubeServSite.LubeServTelabastaganPampanga
            : stationCode === 4506
            ? LubeServSite.LubeServAnabuImusCavite
            : null,
      },
    },
    filter: {
      initialState: lubeServOnSiteBookingsFilterState(stationCode),
      mapper: mapFilterToRequest,
    },
    table: {
      key: "bookings",
      mapper: mapDataToList,
      columns: lubeServOnSiteBookingsColumns,
    },
  });

  const exports = useExport({
    api: generateBookingReport,
    hasModal: true,
    reportType: "lubeserv-on-site",
    mappedFilterState: {
      ...filter?.mappedFilterState,
      lubeServSite:
        stationCode === 4377
          ? LubeServSite.LubeServTelabastaganPampanga
          : stationCode === 4506
          ? LubeServSite.LubeServAnabuImusCavite
          : null,
      searchKey: search.searchKey,
      bookingTypes: "lubeserv-on-site",
      stationId,
    },
  });

  return (
    <>
      <SuccessModal {...exports?.successModalComponent} />
      <div>
        <Intro
          title={locale.lubeServOnSite}
          subtitle={locale.viewAndTrackLubeServOnSiteBookingsTransaction}
        />
      </div>
      <LubeServBookingsFilter {...filter} {...search} {...exports} />
      <DataTableV2 {...table} />
    </>
  );
};

export default LubeServBookingsModule;
