import { ApiPath } from "enums";
import { Post, Get, Put, Delete } from "utils";

export const generatePricingReport = async (query) => {
  const res = await Post(`${ApiPath.GeneratePricingReport}`, query);
  return res.data;
};

export const generateStationPricingReport = async (query) => {
  const res = await Post(`${ApiPath.GenerateStationPricingReport}`, query);
  return res.data;
};

export const generateReportAdmin = async (query) => {
  const res = await Post(`${ApiPath.GenerateReportAdmin}`, {
    ...query,
    connectionId: localStorage.getItem("connectionId"),
  });
  return res.data;
};

export const generateBookingReport = async (query) => {
  const res = await Post(`${ApiPath.GenerateBookingReport}`, {
    ...query,
    connectionId: localStorage.getItem("connectionId"),
  });
  return res.data;
};

export const generateStationReport = async (query) => {
  const res = await Post(`${ApiPath.GenerateStationReport}`, {
    ...query,
    connectionId: localStorage.getItem("connectionId"),
  });
  return res.data;
};

export const updateReport = async ({ reportId, ...body }) => {
  const res = await Put(`${ApiPath.UpdateReport(reportId)}`, body);
  return res.data;
};

export const deleteReport = async ({ reportId }) => {
  const res = await Delete(`${ApiPath.UpdateReport(reportId)}`);
  return res;
};

export const generateReportList = async (query) => {
  const res = await Get(`${ApiPath.GenerateReportList}`, query);
  return res.data;
};

export const generateStationReportLoyalty = async (query) => {
  const res = await Post(`${ApiPath.GenerateStationReportLoyalty}`, {
    ...query,
    connectionId: localStorage.getItem("connectionId"),
  });
  return res.data;
};
