import React from "react";
import { Path } from "enums";
import { Route, Switch, Redirect } from "react-router-dom";
import PremiumPayoutModule from "modules/premium-payout/premium-payout.module";
import ViewPremiumPayout from "modules/premium-payout/view-premium-payout/view-premium-payout.module";
// import ViewSettlementHistory from "modules/settlement/settlement-history/view-settlement-history/view-settlement-history.module";

const PremiumPayoutPage = () => {
  return (
    <Switch>
      <Route exact path={Path.PremiumPayout} component={PremiumPayoutModule} />
      <Route path={Path.ViewPremiumPayout} component={ViewPremiumPayout} />
      <Redirect to={Path.PremiumPayout} />
    </Switch>
  );
};

export default PremiumPayoutPage;