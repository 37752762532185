const ErrorCode = {
  IncorrectCredentials: "P1002",
  DeactivatedFuelCode: "FC1001",
  NotYetValidFuelCode: "FC1002",
  ExpiredFuelCode: "FC1003",
  ReachedLimitFuelCode: "FC1004",
  InvalidFuelCode: "FC1005",
  FuelCodeNotEnoughBalance: "FC1007",
  InsufficientBalance: "W1001",
  NoProductFuelBalance: "FB1002",
  NotActiveDriver: "FC1011",
  NotActiveVehicle: "FC1012",
  InvalidInviteStationToken: "S1001",
  ExpiredInviteStationToken: "S1003",
  InactiveFleet: "F1001",
  UserNotFound: "P1005",
  InvalidForgotPasswordToken: "P1009",
  ExpiredForgotPasswordToken: "P1010",
  UserInviteStillPending: "U1002",
  UserDontBelongToAStation: "U1001",
  FuelCodeDoesNotBelongToAStation: "FC1013",
  FuelCardIsInValid: "FCA1000",
  FuelCardIsDeActivated: "FCA1006",
  FuelCardIsTerminated: "FCA1007",
  FuelCardDoesNotBelongToThisStation: "FCA1003",
  LoyaltyCardNotFound: "L1007",
  LoyaltyCardIsExpired: "L1008",
  LoyaltyCardIsInActive: "L1009",
  LoyaltyCardAlreadyLinkedToYouAccount: "L1010",
  LoyalCardAlreadyLinked: "L1011",
  LoyalCardUnregistered: "L1013",
  LoyaltyCardDeleted: "P1024",
  LoyaltyCardBlocked: "P1025",
  LoyaltyCardDeactivated: "P1013",
  LoyalCardUserNotFound: "L1002",
  InvalidOTPCode: "P1006",
  ExpiredOTP: "P1007",
  OtpLimitReached: "P1026",
  OTPOngoing: "P1008",
  MobileNumberAlreadyRegistered: "P1011",
  InsufficientPointsBalance: "C1000",
  ConversionReachedMaximumLimit: "C1001",
  VIPDailyLimit: "VC001",
  VIPWeeklyLimit: "VC002",
  VIPMonthlyLimit: "VC003",
};

export default ErrorCode;
