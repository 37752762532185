import React, { useState } from "react";
import styles from "./reset-password.module.scss";
import { Title, Loader } from "components/commons";
import locale from "localization";
import { initialState } from "./reset-password.state";
import { useForm, useApi, useMount } from "hooks";
import { resetPassword, validateResetPasswordToken } from "apis";
import { handleRequest, isPasswordValid } from "utils";
import ResetPasswordForm from "./reset-password-form";
import ResetPasswordSuccess from "./reset-password-success";
import * as queryString from "query-string";
import { useHistory } from "react-router-dom";
import { Path, ErrorCode } from "enums";

const ResetPasswordModule = () => {
  const history = useHistory();
  const parse = queryString.parse(history.location.search);
  const [successEmailSubmit, setSuccessEmailSubmit] = useState("");

  const { request: resetPasswordRequest, loading: resettingPassword } = useApi({
    api: resetPassword,
    handleOwnError: {
      badrequest: true,
    },
    modalError: false,
  });
  const { fields, modifyField, submitForm, getFormValues, applyFieldErrors, isFormSubmittable } =
    useForm({
      initialState,
    });
  const handleSubmit = () => {
    const { newPassword } = getFormValues();
    if (!newPassword) {
      applyFieldErrors({
        newPassword: !newPassword ? "" : null,
      });
      return;
    }
    handleRequest(
      async () => {
        const res = await resetPasswordRequest({ password: newPassword, token: parse.token }, () =>
          handleSubmit()
        );
        if (res.email) {
          setSuccessEmailSubmit(res.email);
        }
      },
      null,
      (err) => {
        if (err.data.errorCode === ErrorCode.InvalidForgotPasswordToken) {
          history.push(`${Path.ForgotPassword}?code=${err.data?.errorCode}`);
        }
      }
    );
  };
  const { request: validateResetPasswordTokenRequest, loading: validatingToken } = useApi({
    api: validateResetPasswordToken,
    params: {
      token: parse.token,
    },
    pageError: false,
    modalError: false,
  });
  const onBlurPasswordField = () => {
    const { newPassword, confirmNewPassword } = getFormValues();
    let validations = {};
    if (newPassword) {
      if (!isPasswordValid(newPassword)) {
        Object.assign(validations, {
          newPassword: locale.use8CharWithMix,
        });
      }
      if (newPassword !== confirmNewPassword && newPassword && confirmNewPassword) {
        Object.assign(validations, {
          confirmNewPassword: locale.passwordsDoNotMatch,
        });
      }
      return applyFieldErrors(validations);
    }
  };

  useMount(() => {
    handleRequest(
      async () => {
        await validateResetPasswordTokenRequest();
      },
      null,
      (err) => {
        if (err.data?.errorCode) {
          history.push(`${Path.ForgotPassword}?code=${err.data?.errorCode}`, {
            email: parse.email,
          });
        }
      }
    );
  });

  const state = {
    fields,
    modifyField,
    submitForm,
    isFormSubmittable,
    handleSubmit,
    resettingPassword,
    successEmailSubmit,
    validatingToken,
    onBlurPasswordField,
  };

  return (
    <div className={styles.forgotContainer}>
      <Loader open={validatingToken} centerCircle />
      <Title className={styles.title}>
        {successEmailSubmit === "" ? locale.passwordReset : locale.passwordChanged}
      </Title>
      {successEmailSubmit === "" ? (
        <ResetPasswordForm {...state} />
      ) : (
        <ResetPasswordSuccess {...state} />
      )}
    </div>
  );
};

export default ResetPasswordModule;
