import { Image, Modal, Text, Title, ActionButton } from "components/commons";
import React from "react";
import locale from "localization";
import styles from "./proceed-to-gas-modal.module.scss";
import { ProceedToGas } from "images";

const ProceedToGasModal = ({ loading, handleProceedGasModal, ...modal }) => {
  return (
    <Modal {...modal}>
      <div className={styles.failed}>
        <Image src={ProceedToGas} className={styles.failedIcon} />
        <Title className={styles.title}>{locale.proceedToGasUpQuestion}</Title>
        <Text className={styles.text}>{locale.proceedToGasDescription}</Text>
        <ActionButton
          center
          items={[
            {
              text: locale.cancel,
              onClick: () => {
                modal.close();
              },
            },
            {
              text: locale.yesContinue,
              onClick: () => {
                modal.close();
                handleProceedGasModal(true);
              },
              loading,
            },
          ]}
        />
      </div>
    </Modal>
  );
};

export default ProceedToGasModal;
