import classNames from "classnames";
import React from "react";
import styles from "./text.module.scss";

const Text = ({ style, children, label, className, subtitle, align, strong, italic, strike }) => {
  const classes = classNames(styles.text, className, {
    [`${styles.label}`]: label,
    [`${styles.subtitle}`]: subtitle,
    [`${styles.strong}`]: strong,
    [`${styles.italic}`]: italic,
    [`${styles.strike}`]: strike,
  });

  return (
    <p style={{ textAlign: align, ...style }} className={classes}>
      {children}
    </p>
  );
};

export default Text;
