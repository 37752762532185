import Validation from "utils/validation.utils";
import locale from "localization";
export const initialState = (state = {}) => {
  const {
    cardNumber = {
      value: "",
      disabled: false,
    },
    mobileNumber = {
      value: "",
      disabled: false,
    },
    firstName = {
      value: "",
      disabled: false,
    },
    lastName = {
      value: "",
      disabled: false,
    },
    email = {
      value: "",
      disabled: false,
    },
    consumerType = {
      value: "",
      disabled: false,
    },
    vehicleType = {
      value: "",
      disabled: false,
    },
  } = state;

  return {
    cardNumber: {
      name: "cardNumber",
      label: "VIP Card Number",
      placeholder: "Enter VIP card number",
      maxLength: 20,
      minLength: 12,
      required: true,
      validations: [Validation.required({ noMessage: true }), Validation.alphaNumeric()],
      ...cardNumber,
    },
    mobileNumber: {
      name: "mobileNumber",
      label: locale.mobileNumber,
      placeholder: "9000000000",
      maxLength: 10,
      required: true,
      validations: [Validation.numeric(), Validation.mobileNumberv2()],
      ...mobileNumber,
    },
    firstName: {
      name: "firstName",
      label: "First Name",
      placeholder: "Enter first name",
      required: true,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.alphaNumericWithSymbols(),
        Validation.maxlength(75),
      ],
      ...firstName,
    },
    lastName: {
      name: "lastName",
      label: "Last Name",
      placeholder: "Enter last name",
      required: true,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.alphaNumericWithSymbols(),
        Validation.maxlength(75),
      ],
      ...lastName,
    },
    email: {
      name: "email",
      placeholder: "Enter email address",
      validations: [
        Validation.required({ noMessage: true }),
        Validation.emailAddress({ customMessage: locale.pleaseEnterAValidEmailAddress }),
      ],
      maxLength: 75,
      required: true,
      label: locale.emailAddress,
      ...email,
    },
    consumerType: {
      name: "consumerType",
      label: locale.consumerType,
      required: true,
      placeholder: "Select consumer type",
      validations: [Validation.required({ noMessage: true })],
      ...consumerType,
    },
    vehicleType: {
      name: "vehicleType",
      label: locale.vehicleType,
      required: true,
      placeholder: "Select vehicle type",
      validations: [Validation.required({ noMessage: true })],
      ...vehicleType,
    },
  };
};
