import locale from "localization/en";

export const columns = [
  { key: "locqpayId", text: locale.locqpayId, width: "100px" },
  {
    key: "businessNameID",
    text: locale.businessNameID,
    width: "150px",
  },
  { key: "gasStationAndBusinessName", text: locale.gasStationAndBusinessName, width: "200px" },
  { key: "fleetCardNo", text: `${locale.fleetCardNo}`, width: "80px" },
  {
    key: "driversNameAndId",
    text: `${locale.driversName} / ${locale.driversId}`,
    width: "80px",
  },
  {
    key: "plateNumber",
    text: `${locale.plateNumber}`,
    width: "80px",
  },

  {
    key: "product",
    text: `${locale.product}`,
    width: "100px",
  },
  {
    key: "transactionRemarks",
    text: `${locale.remarks}`,
    width: "100px",
  },
  {
    key: "dateOfTransaction",
    text: `${locale.dateOfTransaction}`,
    width: "150px",
  },
  {
    key: "finalAmountPaid",
    text: `${locale.finalAmountPaid}`,
    width: "100px",
  },
  {
    key: "poNumber",
    text: `${locale.poNumber}`,
    width: "80px",
  },
  {
    key: "orNumber",
    text: `${locale.orNumber}`,
    width: "80px",
  },
  {
    key: "status",
    text: `${locale.status}`,
    width: "80px",
  },
  {
    key: "settlementStatus",
    text: `${locale.settlementStatus} / ${locale.settlementId}`,
    width: "150px",
  },
  {
    key: "action",
    text: `${locale.action}`,
    width: "100px",
  },
];
