import React from "react";
import moment from "moment";

import { BackLink, Intro } from "components/commons";
import { Path, DateTime } from "enums";
import locale from "localization";
import { useApi, useMount, useRouter } from "hooks";

import styles from "./view-premium-payout.module.scss";

import { getPremiumPayoutById, generatePricingReport } from "apis";
import ViewPremiumPayoutTable from "./view-premium-payout-table.module";
import ViewPremiumPayoutForm from "./view-premium-payout-form.module";

const ViewPremiumPayout = () => {
  const {
    history,
    query: { id: premiumPayoutId },
  } = useRouter();

  const { result, request, loading } = useApi({
    api: getPremiumPayoutById,
    params: {
      id: premiumPayoutId,
    },
    pageError: true,
  });

  const generateReport = useApi({
    api: generatePricingReport,
    handleOwnError: {
      badrequest: true,
    },
  });

  useMount(async () => {
    if (!premiumPayoutId) {
      history.push(Path.PremiumPayout);
    } else {
      const resp = await request();
      const periodDate = resp?.periodDate?.split("-");
      if (resp) {
        generateReport.request({
          reportType: "redemptions-settlement",
          startDate: new Date(
            moment(`${periodDate[0]} ${periodDate[1]}`).startOf("month").format(DateTime.C)
          ),
          endDate: new Date(
            moment(`${periodDate[0]} ${periodDate[1]}`).endOf("month").format(DateTime.D)
          ),
          redemptionStationId: resp?.station?.stationId,
          settlementType: "premium-payout",
          fileType: "pdf",
        });
      }
    }
  });

  return (
    <>
      <div className={styles.container}>
        <BackLink text={locale.monthlyPremiumPayment} path={Path.PremiumPayout} />
        <div
          style={{
            display: "flex",
          }}
        >
          <Intro
            style={{
              minWidth: "300px",
            }}
            title={locale.monthlyPremiumPaymentDetails}
          />
        </div>
        <ViewPremiumPayoutForm
          premiumPayoutDetails={{ ...result } || null}
          premiumPayoutLoading={loading}
        />
        <ViewPremiumPayoutTable
          premiumPayoutDetails={{ ...result }}
          downloadUrl={generateReport?.result?.downloadUrl}
        />
      </div>
    </>
  );
};

export default ViewPremiumPayout;
