import React from "react";
import { Image, Title, Text, ActionButton } from "components/commons";
import Header from "components/commons/header/header";
import { CarCrash } from "images";
import locale from "localization";
import styles from "styles/error-page.module.scss";
import { redirectTo } from "utils";

const ErrorPage = () => {
  return (
    <div>
      <Header />
      <div className={styles.content}>
        <Image src={CarCrash} />
        <Title small>{locale.oops}</Title>
        <Text subtitle>{locale.pleaseRefreshYourBrowser}</Text>
        <ActionButton
          center
          items={[
            {
              text: locale.backToHomePage,
              onClick: () => {
                redirectTo("/payment/validate");
              },
            },
            {
              text: locale.okayGotIt,
              onClick: () => {
                window.location.reload();
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default ErrorPage;
