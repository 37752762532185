import {
  prettifyPayWithPesoStatus,
  prettifyProduct,
  prettifySettlementStatus,
} from "utils/pretty.utils";
import { formatAmount, formatDate, formatVolume } from "utils";
import { DateTime, Products, SettlementStatus } from "enums";
import PayWithPesoStatus from "enums/pay-with-peso-status";
import LabelAndCircle from "components/commons/label-and-circle/label-and-circle";
import LabelAndPill from "components/commons/label-and-pill/label-and-pill";
import { locale } from "localization/en";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { PopOverMenu } from "components/commons";

import styles from "./pay-with-peso.module.scss";
import moment from "moment";

const getProductColor = (product) => {
  return (
    {
      [Products.Diesel]: "#ffaa01",
      [Products.Gas91]: "#019f3e",
      [Products.Gas95]: "#ee3b34",
      [Products.Gas97]: "#2e4491",
    }[product] || null
  );
};

export const mapDataToList = ({
  payments,
  handleVoidPayment,
  handleActionsHistory,
  handleReceiptGuide,
}) => {
  const {
    paymentId,
    paidAt,
    productCode,
    amount,
    status = PayWithPesoStatus.Success,
    settlementStatus,
    settlementId,
    voidedAt,
    rebate,
    voidedByEmail,
    finalAmount,
    literVolume,
    totalDiscount,
  } = payments;

  const options = [];

  if (
    status === PayWithPesoStatus.Success &&
    moment().isBefore(moment(paidAt).add(10, "minutes"))
  ) {
    options.push({
      content: locale.voidTransaction,
      onClick: () =>
        handleVoidPayment({
          paymentId,
          amount,
          rebate,
          settlementStatus,
          settlementId,
        }),
    });
  } else if (status === PayWithPesoStatus.Voided) {
    options.push({
      content: locale.actionsHistory,
      onClick: () => handleActionsHistory({ voidedByEmail, status, voidedAt }),
    });
  }

  options.push({
    content: locale.viewReceiptGuide,
    onClick: () => handleReceiptGuide(payments),
  });

  return {
    paymentId: <div>{paymentId}</div>,
    createdAt: formatDate(paidAt, DateTime.J),
    product: (
      <div className="min-70">
        {
          <LabelAndCircle
            label={prettifyProduct(productCode)}
            color={getProductColor(productCode)}
          />
        }
      </div>
    ),
    computedVolume: formatVolume(literVolume),
    amount: formatAmount(amount),
    discount: formatAmount(totalDiscount),
    effectiveDiscount: formatAmount(totalDiscount / literVolume),
    finalAmountPaid: formatAmount(finalAmount),
    status: (
      <LabelAndPill
        label={voidedAt ? formatDate(voidedAt, DateTime.G) : ""}
        pillProps={{
          sky: status === PayWithPesoStatus.Pending,
          deepRed: status === PayWithPesoStatus.Failed,
          grass: status === PayWithPesoStatus.Success,
          cement: status === PayWithPesoStatus.Voided,
        }}
        pillLabel={prettifyPayWithPesoStatus(status)}
      />
    ),
    settlementStatus: (
      <div>
        <LabelAndPill
          label={
            settlementStatus === SettlementStatus.ForProcessing ||
            settlementStatus === SettlementStatus.Processed
              ? settlementId
              : "- -"
          }
          pillProps={{
            cyan: settlementStatus === SettlementStatus.ForSettlement,
            sky: settlementStatus === SettlementStatus.ForProcessing,
            grass: settlementStatus === SettlementStatus.Processed,
            deepRed: settlementStatus === SettlementStatus.Cancelled,
          }}
          pillLabel={prettifySettlementStatus(settlementStatus)}
        />
      </div>
    ),
    action: (
      <PopOverMenu options={options}>
        <MoreVertIcon className={styles.icon} />
      </PopOverMenu>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { productCodes, settlementStatuses, status, ...fs } = filterState;

  return {
    ...fs,
    productCodes: productCodes.length < 4 ? productCodes.join(",") : null,
    settlementStatuses: !settlementStatuses.isSelectedAll
      ? settlementStatuses.value.join(",")
      : null,
    status: !status.isSelectedAll ? status.value.join(",") : null,
  };
};
