import {
  Autocomplete,
  Button,
  DateRange,
  Field,
  Filter,
  SearchField,
  DatePicker,
} from "components/commons";
import React from "react";
import { prettifyOilChangeType } from "utils/pretty.utils";
import styles from "./lubeserv-bookings-filter.module.scss";
import locale from "localization";
import OilChangeType from "enums/oil-change-type";

const LubeServBookingsFilter = ({
  filterState,
  applyFilter,
  applyClearFilter,
  applyResetFilter,
  modifyFilter,
  modifySearch,
  applySearch,
  applyClearSearch,
  searchKey,
  filterCount,
  exportReport,
  exporting,
}) => {
  const searchPlaceholder = [
    locale.bookingID,
    locale.name,
    locale.mobileNumber,
    locale.emailAddress,
  ];

  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <div className="flex items-center">
          <Filter
            submit={applyFilter}
            clear={applyClearFilter}
            close={applyResetFilter}
            filterCount={filterCount}
          >
            <Field
              className={styles.date}
              labelPosition={"left"}
              label={locale.dateCreatedRange}
              childrenClassName={styles.fieldContent}
            >
              <DateRange
                value={{
                  startDate: filterState.startDate,
                  endDate: filterState.endDate,
                }}
                onChange={(_, { value }) => {
                  const { startDate, endDate } = value;
                  modifyFilter({
                    startDate,
                    endDate,
                  });
                }}
              />
            </Field>
            <Field
              label={locale.oilChangePackage}
              labelPosition={"left"}
              className={styles.fieldSelect}
              childrenClassName={styles.fieldContent}
            >
              <Autocomplete
                name="type"
                value={filterState.type}
                hasAll
                multiple
                options={[
                  {
                    label: prettifyOilChangeType(OilChangeType.dieselMonoGrade),
                    value: OilChangeType.dieselMonoGrade,
                  },
                  {
                    label: prettifyOilChangeType(OilChangeType.dieselMultiGrade),
                    value: OilChangeType.dieselMultiGrade,
                  },
                  {
                    label: prettifyOilChangeType(OilChangeType.dieselFullySynthetic),
                    value: OilChangeType.dieselFullySynthetic,
                  },
                  {
                    label: prettifyOilChangeType(OilChangeType.gasolineMonoGrade),
                    value: OilChangeType.gasolineMonoGrade,
                  },
                  {
                    label: prettifyOilChangeType(OilChangeType.gasolineMultiGrade),
                    value: OilChangeType.gasolineMultiGrade,
                  },
                  {
                    label: prettifyOilChangeType(OilChangeType.gasolineFullySynthetic),
                    value: OilChangeType.gasolineFullySynthetic,
                  },
                ]}
                onChange={(name, { value }) => {
                  modifyFilter({ [name]: value });
                }}
              />
            </Field>
            <Field
              className={styles.date}
              labelPosition={"left"}
              label={locale.preferredDate}
              childrenClassName={styles.fieldContent}
            >
              <DatePicker
                name="selectedPreferredDate"
                value={filterState?.selectedPreferredDate}
                placeholder={locale.preferredDate}
                onChange={(name, { value }) => {
                  modifyFilter({ [name]: value });
                }}
              />
            </Field>
            <Field
              label={locale.preferredTime}
              labelPosition={"left"}
              className={styles.fieldSelect}
              childrenClassName={styles.fieldContent}
            >
              <Autocomplete
                name="selectedPreferredTime"
                value={filterState.selectedPreferredTime}
                hasAll
                multiple
                options={[
                  {
                    label: "8:00 AM",
                    value: "08:00:00",
                  },
                  {
                    label: "9:00 AM",
                    value: "09:00:00",
                  },
                  {
                    label: "10:00 AM",
                    value: "10:00:00",
                  },
                  {
                    label: "11:00 AM",
                    value: "11:00:00",
                  },
                  {
                    label: "1:00 PM",
                    value: "13:00:00",
                  },
                  {
                    label: "2:00 PM",
                    value: "14:00:00",
                  },
                  {
                    label: "3:00 PM",
                    value: "15:00:00",
                  },
                  {
                    label: "4:00 PM",
                    value: "16:00:00",
                  },
                ]}
                onChange={(name, { value }) => {
                  modifyFilter({ [name]: value });
                }}
              />
            </Field>
          </Filter>
          <Field className={styles.search}>
            <SearchField
              restrict={false}
              placeholder={searchPlaceholder.join(", ")}
              value={searchKey}
              onChange={(_, { value }) => modifySearch(value)}
              onEnter={applySearch}
              onClear={applyClearSearch}
            />
          </Field>
        </div>
        <Field className={styles.downloadButton}>
          <Button
            startIcon={<span className="icon-download" />}
            exportButton
            onClick={exportReport}
            disabled={exporting}
            loading={exporting}
          >
            {locale.export}
          </Button>
        </Field>
      </div>
    </div>
  );
};

export default LubeServBookingsFilter;
