import Validation from "utils/validation.utils";
import locale from "localization";
import { LitreVolumeState, ActualAmountDispenseState } from "states";
import { DispenseType } from "enums";

export const initialState = (state = {}) => {
  const {
    fuelCode = {
      name: "fuelCode",
      label: locale.fuelCode,
    },
    fleetCardNo = {
      name: "fleetCardNo",
      label: locale.fleetCardNo,
    },
    purchaseOrderNo = {
      name: "purchaseOrderNo",
      label: locale.purchaseOrderNo,
    },
    productType = {
      name: "productType",
      label: locale.productType,
    },
    pumpPrice = {
      name: "pumpPrice",
    },
    computedVolume = {
      name: "computedVolume",
    },
    dispensedTo = {
      name: "dispensedTo",
      required: true,
      value: DispenseType.Vehicle,
      label: locale.dispensedTo,
    },
    literVolume = LitreVolumeState(
      [
        Validation.required,
        Validation.min(1, {
          customMessage: locale.minimumAllowedRedemption1Liter,
        }),
      ],
      { required: false }
    ),
    actualAmountDispense = ActualAmountDispenseState(
      [
        Validation.required,
        Validation.min(50, {
          customMessage: locale.minimumAllowedRedemption50Peso,
        }),
      ],
      { required: false }
    ),
    totalDiscount = {
      name: "totalDiscount",
      value: "",
    },

    totalAmount = {
      name: locale.totalAmountName,
      value: "",
      label: locale.totalAmount,
      validations: [
        Validation.min(30, { customMessage: locale.minimumAllowedRedemption30Peso }),
        Validation.max(50000, { customMessage: locale.maximumAllowedRedemption50Thousand }),
      ],
    },
    remarks = {
      name: "remarks",
      value: "",
      label: locale.remarks,
    },
    discountPerLiter = {
      name: "discountPerLiter",
      value: "",
    },
  } = state;
  return {
    fuelCode,
    fleetCardNo,
    productType,
    pumpPrice,
    computedVolume,
    dispensedTo: {
      validations: [Validation.required],
      ...dispensedTo,
    },
    literVolume,
    actualAmountDispense,
    discountPerLiter,
    totalDiscount,
    purchaseOrderNo,
    totalAmount,
    remarks,
  };
};
