import { ApiPath } from "enums";
import { Get, Post, Put } from "utils";

export const getLoyaltyCardByCardNumber = async ({ cardNumber }) => {
  const res = await Get(`${ApiPath.LoyaltyCard}/loyaltyloyalty-card/card-number/${cardNumber}`);
  return res.data;
};

export const validateVIP = async ({ ...body }) => {
  const res = await Post(`${ApiPath.LoyaltyCard}/loyalty/loyalty-card/admin/validate`, body);
  return res.data;
};

export const getTransactionHistoryAwardPoints = async ({ stationId, ...query }) => {
  const res = await Get(`${ApiPath.LoyaltyCard}/loyalty/history/station/${stationId}`, query);
  return res.data;
};

export const getTransactionHistoryPayWithPoints = async ({ stationId, ...query }) => {
  const res = await Get(`${ApiPath.LoyaltyCard}/loyalty/pay/history/station/${stationId}`, query);
  return res.data;
};

export const getTransactionHistoryRedeemPoints = async ({ stationId, ...query }) => {
  const res = await Get(
    `${ApiPath.LoyaltyCard}/loyalty/conversion/history/station/${stationId}`,
    query
  );
  return res.data;
};

export const validateVIPV2 = async ({ ...body }) => {
  const res = await Post(`${ApiPath.LoyaltyCard}/loyalty/loyalty-card/validate`, body);
  return res.data;
};

export const registerValidateSendOTP = async ({ ...body }) => {
  const res = await Post(`${ApiPath.LoyaltyCard}/loyalty/register/validate`, body);
  return res.message;
};

export const validateMobileNumber = async ({ mobileNumber }) => {
  const res = await Get(`${ApiPath.GetUser}/mobile-number?mobileNumber=0${mobileNumber}`);
  return res.data;
};

export const registerVip = async ({ ...body }) => {
  const res = await Post(`${ApiPath.GetUser}/register/vip`, body);
  return res.data;
};

export const validatePayWithPoints = async ({ ...body }) => {
  const res = await Post(`${ApiPath.LoyaltyCard}/loyalty/points/pay/validate`, body);
  return res.data;
};

export const vipPayWithPoints = async ({ ...body }) => {
  const res = await Post(`${ApiPath.LoyaltyCard}/loyalty/points/pay`, body);
  return res.data;
};

export const awardPoints = async ({ ...body }) => {
  const res = await Post(`${ApiPath.LoyaltyCard}/loyalty/points/award`, body);
  return res.data;
};

export const validateAwardPoints = async ({ ...body }) => {
  const res = await Post(`${ApiPath.LoyaltyCard}/loyalty/points/award/validate`, body);
  return res.data;
};

export const updatePayWithPointsByOrNumber = async ({ pointsHistoryId, body }) => {
  const res = await Put(`${ApiPath.UpdatePayWithPoints(pointsHistoryId)}`, body);
  return res.data;
};

export const createRedemption = async ({ ...body }) => {
  const res = await Post(`${ApiPath.LoyaltyCard}/loyalty/conversion/station`, body);
  return res.data;
};

export const validateRedemption = async ({ ...body }) => {
  const res = await Post(`${ApiPath.LoyaltyCard}/loyalty/conversion/station/validate`, body);
  return res;
};

export const fetchRedemptionCards = async ({ ...query }) => {
  const res = await Get(`${ApiPath.LoyaltyCard}/loyalty/conversion/options/station`, query);
  return res;
};
