import React from "react";
import styles from "./login.module.scss";
import { ErrorCode, Path } from "enums";
import {
  Checkbox,
  Field,
  TextField,
  PasswordField,
  Button,
  Highlight,
  Text,
} from "components/commons";
import locale from "localization";

const LoginForm = ({ ...props }) => {
  const {
    incorrectCreds,
    errorCode,
    loggingIn,
    fields,
    modifyField,
    submitForm,
    handleLogin,
    history,
    isFormSubmittable,
  } = props;

  return (
    <div>
      {!incorrectCreds &&
        errorCode &&
        (errorCode === ErrorCode.InvalidInviteStationToken ||
          errorCode === ErrorCode.ExpiredInviteStationToken) && (
          <Highlight warning>
            <Text>{locale.expiredUsedActivationLinkToken}</Text>
          </Highlight>
        )}
      {incorrectCreds && (
        <Highlight error>
          <Text>{locale.theEnteredEmailPassIncorrect}</Text>
        </Highlight>
      )}
      <Field {...fields.email}>
        <TextField {...fields.email} onChange={modifyField} />
      </Field>
      <Field {...fields.password}>
        <PasswordField
          {...fields.password}
          onChange={modifyField}
          onEnter={() => {
            submitForm(handleLogin);
          }}
        />
      </Field>
      <div className={styles.optionContainer}>
        <div className={styles.remember}>
          <Checkbox {...fields.remember} onChange={modifyField} />
          {fields.remember.label}
        </div>
        <div className={styles.forgotPassword}>
          <span
            onClick={() => {
              history.push(Path.ForgotPassword);
            }}
          >
            {locale.forgotPassword}
          </span>
        </div>
      </div>
      <Button
        primary
        className={styles.loginButton}
        loading={loggingIn}
        onClick={() => {
          submitForm(handleLogin);
        }}
        disabled={!isFormSubmittable}
      >
        {locale.logIn}
      </Button>
    </div>
  );
};

export default LoginForm;
