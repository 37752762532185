import Validation from "utils/validation.utils";
import locale from "localization";

export const initialState = (state = {}) => {
  const {
    transactionId = {
      value: "",
      name: "transactionId",
      disabled: false,
      error: false,
    },
  } = state;
  return {
    transactionId: {
      horizontal: true,
      label: locale.orNumber,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.numeric(),
        Validation.maxlength(15),
      ],
      maxLength: 15,
      upperCase: true,
      required: true,
      ...transactionId,
    },
  };
};
