import {
  prettifyProduct,
  prettifySettlementStatus,
  prettifyRedemptionStatus,
} from "utils/pretty.utils";
import { formatAmount, formatVolume } from "utils";
import { DateTime, SettlementStatus, Products, RedemptionStatus } from "enums";
import { locale } from "localization/en";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { PopOverMenu, PopOver, Text, Pill, Product } from "components/commons";
import * as moment from "moment";
import styles from "./payment-history.module.scss";

export const mapDataToList = ({
  redemptions,
  handleVoidPayment,
  handleReceiptGuide,
  handleEdit,
}) => {
  const {
    redemptionId,
    fuelCode,
    redeemedAt,
    productCode,
    literVolume,
    averageCreditPrice,
    totalCreditPrice,
    redemption,
  } = redemptions;

  const options = [];
  if (
    redemption.status === RedemptionStatus.Success &&
    moment().isBefore(moment(redemption.redeemedAt).add(10, "minutes"))
  ) {
    options.push({
      content: locale.voidTransaction,
      onClick: () => {
        handleVoidPayment({
          redemptions,
        });
      },
    });
  }
  options.push({
    content: locale.viewReceiptGuide,
    onClick: () => {
      handleReceiptGuide({
        redemptions,
      });
    },
  });
  if (redemption.status === RedemptionStatus.Voided) {
    options.push({
      content: locale.editRemarks,
      onClick: () => {
        handleEdit({
          redemptions,
        });
      },
    });
  }

  return {
    redemptionId: <div className="min-60 nowrap">{redemptionId}</div>,
    fuelCode: <div>{fuelCode}</div>,
    redeemedAt: (
      <div className="min-80">
        <Text>{moment(redeemedAt).format(DateTime.A)}</Text>
        <Text>{moment(redeemedAt).format(DateTime.B)}</Text>
      </div>
    ),
    product: (
      <Product
        grass={productCode === Products.Gas91}
        salsa={productCode === Products.Gas95}
        deepBlue={productCode === Products.Gas97}
        cheddar={productCode === Products.Diesel}
      >
        {prettifyProduct(productCode)}
      </Product>
    ),
    volume: <div className="min-50 nowrap">{formatVolume(literVolume)}</div>,
    averageCreditPrice: <div className="min-50 nowrap">{formatAmount(averageCreditPrice)}</div>,
    totalCreditAmount: <div className="min-50 nowrap">{formatAmount(totalCreditPrice)}</div>,
    status: (
      <Pill
        grass={redemption?.status === RedemptionStatus.Success}
        cement={redemption?.status === RedemptionStatus.Voided}
      >
        {prettifyRedemptionStatus(redemption?.status)}
      </Pill>
    ),
    settlementStatus: (
      <>
        <Pill
          grass={redemption.settlementStatus === SettlementStatus.Processed}
          cyan={redemption.settlementStatus === SettlementStatus.ForSettlement}
          sky={redemption.settlementStatus === SettlementStatus.ForProcessing}
        >
          {prettifySettlementStatus(redemption.settlementStatus)}
        </Pill>
        {redemption.settlementStatus === SettlementStatus.ForProcessing ||
        redemption.settlementStatus === SettlementStatus.Processed ? (
          <Text className={styles.subBusinessId}>{redemption.settlementId}</Text>
        ) : (
          <Text className={styles.subBusinessId}>--</Text>
        )}
      </>
    ),
    remarks: redemption?.remarks ? (
      <PopOver content={<div className={styles.remarks}>{redemption?.remarks}</div>}>
        <div className="link">View</div>
      </PopOver>
    ) : (
      ""
    ),
    options: (
      <PopOverMenu options={options}>
        <MoreVertIcon className={options.length > 0 ? styles.icon : styles.iconDisabled} />
      </PopOverMenu>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { productCodes, redemptionStatuses, settlementStatuses, ...fs } = filterState;
  return {
    ...fs,
    productCodes: productCodes.length < 4 ? productCodes.join(",") : null,
    redemptionStatuses: !redemptionStatuses?.isSelectedAll
      ? redemptionStatuses.value.join(",")
      : null,
    settlementStatuses: !settlementStatuses?.isSelectedAll
      ? settlementStatuses.value.join(",")
      : null,
  };
};
