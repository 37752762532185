import Validation from "utils/validation.utils";
import locale from "localization";

export const initialState = (state = {}) => {
  const {
    orNumber = {
      value: "",
      name: "orNumber",
      label: locale.orNumber,
      horizontal: true,
    },
  } = state;
  return {
    orNumber: {
      validations: [
        Validation.required({ noMessage: true }),
        Validation.alphaNumeric({ removeLast: true }),
        Validation.minlength(5),
        Validation.maxlength(20),
      ],
      maxLength: 20,
      upperCase: true,
      required: true,
      ...orNumber,
    },
  };
};
