import { useCallback } from "react";
// useContext
import useApi from "./useApi";
import useModal from "./useModal";

import locale from "localization";
// import { UserContext } from "contexts";

const useExport = ({ api, reportType, mappedFilterState, hasModal }) => {
  const { request, loading } = useApi({ api, params: api.params });
  const successModalComponent = useModal();
  // const { user } = useContext(UserContext);

  const exportReport = useCallback(
    async ({ fileType }) => {
      const filterState = { ...mappedFilterState, ...(fileType ? { fileType } : {}) };
      delete filterState.page;
      delete filterState.perPage;

      const resp = await request({ ...filterState, reportType });
      if (hasModal) {
        //temporary
        successModalComponent.show({
          title: locale.exclamatedSuccess,
          content: locale.reportGenerated,
          // contentHighlight: [
          //   <strong>{user?.email}</strong>,
          //   <strong>business@pricelocq.com</strong>,
          // ],
        });
      } else {
        window.open(resp?.downloadUrl, "_blank");
      }
    },
    //eslint-disable-next-line
    [request, reportType, mappedFilterState, hasModal, successModalComponent]
  );

  return { exportReport, exporting: loading, successModalComponent };
};

export default useExport;
