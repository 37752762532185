import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Path from "./enums/path";
import AuthPage from "./pages/auth.page";
import AuthorizedPage from "./pages/authorized.page";
import AuthRoute from "./routes/auth.route";
import AuthorizedRoute from "./routes/authorized.route";
import { AppContext } from "contexts";
import { ErrorModal } from "components/modals";
import React, { useState, useCallback } from "react";
import { useModal } from "hooks";
import { Image } from "components/commons";
import { CautionSign } from "images";
import CssBaseline from "@material-ui/core/CssBaseline";
import { StylesProvider } from "@material-ui/core/styles";
import ErrorPage from "pages/error.page";
import { CarCrash } from "images";
import ActivateAccountModule from "modules/activate-account/activate-account.module";
import SelectStationModule from "modules/select-station/select-station.module";
import env from "environments/env";
import firebase from "firebase/compat/app";
import useBeforeMount from "hooks/useBeforeMount";
import AppLoader from "components/commons/app-loader/app-loader";
import FeatureFlagPage from "pages/featureflag.page";

function App() {
  const [appState, setAppState] = useState({ loading: false });
  const errorModal = useModal();
  const [pageError, setPageError] = useState(false);
  const [initializing, setInitializing] = useState(true);

  const toggleLoading = useCallback((loading) => {
    setAppState((state) => {
      return { ...state, loading };
    });
  }, []);

  useBeforeMount(async () => {
    await firebase.initializeApp(env.FIREBASE_CONFIG);
    setInitializing(false);
  });

  return (
    <FeatureFlagPage>
      <AppContext.Provider value={{ toggleLoading, errorModal, appState, pageError, setPageError }}>
        <StylesProvider injectFirst>
          {initializing ? (
            <AppLoader />
          ) : (
            <>
              <ErrorModal {...errorModal} />
              <CssBaseline />
              <div className="App">
                {pageError ? (
                  <ErrorPage />
                ) : (
                  <Router>
                    <Switch>
                      <Route path={Path.ActivateAccount} component={ActivateAccountModule} />
                      <Route path={Path.SelectStation} component={SelectStationModule} />
                      <AuthRoute path={Path.Auth} component={AuthPage} />
                      <AuthorizedRoute path={Path.Slash} component={AuthorizedPage} />
                    </Switch>
                  </Router>
                )}
              </div>
            </>
          )}
        </StylesProvider>
        <div className="render-offline-images">
          <Image src={CautionSign} />
          <Image src={CarCrash} />
        </div>
      </AppContext.Provider>
    </FeatureFlagPage>
  );
}

export default App;
