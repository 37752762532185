import { Path } from "enums";
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import GenerateQrModule from "../modules/generate-qr/generate-qr.module";

const GenerateQrPage = () => {
  return (
    <Switch>
      <Route exact path={Path.GenerateQrPreview} component={GenerateQrModule} />
      <Redirect to={Path.GenerateQrPreview} />
    </Switch>
  );
};

export default GenerateQrPage;
