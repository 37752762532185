import React, { useState } from "react";
import Popover from "@material-ui/core/Popover";
import styles from "./pop-over-list.module.scss";

const PopOverList = ({ children, listTitle, list = [], className }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    if (list.length > 0) {
      setAnchorEl(event.currentTarget);
      setOpen((previousOpen) => !previousOpen);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div onClick={handleClick} className={className}>
        {children}
      </div>
      <Popover
        id="pop-over-list"
        open={open}
        anchorEl={anchorEl}
        className={styles.popoverContainer}
        onClose={handleClose}
        keepMounted
      >
        {list.map((item, i) => {
          return (
            <>
              {listTitle && i === 0 && <span className={styles.title}>{listTitle}</span>}
              <div key={i} className={styles.content}>
                {item}
              </div>
            </>
          );
        })}
      </Popover>
    </>
  );
};

export default PopOverList;
