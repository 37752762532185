import MoreVertIcon from "@material-ui/icons/MoreVert";

import {
  PopOverMenu, 
} from "components/commons";
import locale  from "localization";
import { formatAmount, formatDate, formatVolume } from "utils";
import { DateTime, Path } from "enums";

import styles from "./premium-payout.module.scss"

export const mapDataToList = ({ premiumPayouts, history }) => {
  const {
    premiumPayoutId,
    totalLiterRedemption,   
    totalPremiumPayout,
    totalPayWithPeso,
    periodDate
  } = premiumPayouts
  const newPeriodDate = periodDate.split("-")

  return {
    monthAndYear: formatDate(`${newPeriodDate[0]} ${newPeriodDate[1]}`, DateTime.F),
    totalLiters: formatVolume(totalLiterRedemption),
    totalPayPeso: formatAmount(totalPayWithPeso),
    totalPremium: formatAmount(totalPremiumPayout),
    action: <PopOverMenu
      options={[
        {
          content: locale.viewDetails,
          onClick: () => history.push(Path.ViewPremiumPayoutById(premiumPayoutId))
        } 
      ]}
    >
      <MoreVertIcon className={styles.icon} />
    </PopOverMenu>
  }
}

export const mapFilterToRequest = (filterState) => {
  const { ...fs } = filterState
  return {
    ...fs,
  }
}