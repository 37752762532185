import RedemptionStatus from "enums/redemption-status";
import { SettlementStatus } from "enums";

export const initialFilterState = () => ({
  startDate: null,
  endDate: null,
  page: 1,
  perPage: 10,
  status: {
    isSelectedAll: true,
    value: [RedemptionStatus.Success, RedemptionStatus.Voided],
  },
  settlementStatuses: {
    isSelectedAll: true,
    value: [
      SettlementStatus.ForSettlement,
      SettlementStatus.ForProcessing,
      SettlementStatus.Processed,
    ],
  },
  searchKey: "",
});
