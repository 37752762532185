import { StationContext } from "contexts";
import { Path } from "enums";
import ValidatePaymentModule from "modules/payment/validate-payment/validate-payment.module";
import React, { useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

const PaymentPage = () => {
  const { station } = useContext(StationContext);
  const {
    isPlbOnboarded,
  } = station;
  return (
    <Switch>
      {isPlbOnboarded && <Route exact path={Path.ValidatePayment} component={ValidatePaymentModule} />}
      <Redirect to={Path.ValidatePayment} />
    </Switch>
  );
};

export default PaymentPage;
