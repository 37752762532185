import locale from "localization";

export const paymentHistoryColumns = [
  {
    key: "redemptionId",
    text: locale.redemptionId,
    style: {
      minWidth: "200px",
    },
  },
  {
    key: "redeemedAt",
    text: locale.redemptionDate,
    style: {
      minWidth: "350px",
    },
  },
  {
    key: "businessName",
    text: locale.businessName,
    style: {
      minWidth: "400px",
    },
  },
  {
    key: "driverId",
    text: locale.driverId,
    style: {
      minWidth: "200px",
    },
  },
  {
    key: "plateNumber",
    text: locale.plateNumber,
    style: {
      minWidth: "200px",
    },
  },
  {
    key: "fuelCode",
    text: locale.fuelCodeCapitalize,
    style: {
      minWidth: "150px",
    },
  },
  {
    key: "product",
    text: locale.product,
    style: {
      minWidth: "220px",
    },
  },
  {
    key: "volume",
    text: locale.volume,
    style: {
      minWidth: "220px",
    },
  },
  {
    key: "averageCreditPrice",
    text: `${locale.averageCreditPrice}`,
    style: {
      minWidth: "300px",
    },
  },
  {
    key: "totalCreditAmount",
    text: locale.totalCreditAmount,
    style: {
      minWidth: "300px",
    },
  },
  {
    key: "orNumber",
    text: locale.orNumber,
    style: {
      minWidth: "250px",
    },
  },
  {
    key: "status",
    text: `${locale.status}`,
    style: {
      minWidth: "230px",
    },
  },
  {
    key: "remarks",
    text: locale.remarks,
    style: {
      minWidth: "230px",
    },
  },
  {
    key: "settlementStatus",
    text: `${locale.settlementStatus}/${locale.settlementId}`,
    style: {
      minWidth: "350px",
    },
  },
  {
    key: "options",
    text: `${locale.action}`,
    style: {
      width: "100px",
    },
  },
];
