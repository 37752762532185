import React from "react";
import styles from "./group-checkbox.module.scss";
import locale from "localization";
import { Checkbox } from "..";
import classNames from "classnames";

const GroupCheckbox = ({
  title = locale.all,
  options = [],
  onChange,
  value = [],
  name,
  className,
}) => {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={classNames("flex items-center", styles.title, styles.content)}>
        <Checkbox
          className={styles.checkbox}
          value={value.length === options.length}
          onChange={() => {
            onChange(name, {
              value:
                value.length === options.length
                  ? []
                  : options.map((v) => {
                      return v.value;
                    }),
            });
          }}
        />
        {title}
      </div>
      <div className={styles.content}>
        {options.map((option, key) => {
          const val = option.value;
          const isChecked = value.includes(val);
          return (
            <div
              key={key}
              className={classNames("flex items-center", {
                [`${styles.checked}`]: isChecked,
              })}
            >
              <Checkbox
                className={styles.checkbox}
                value={isChecked}
                onChange={() => {
                  onChange(name, {
                    value: isChecked
                      ? value.filter((v) => {
                          return v !== val;
                        })
                      : [...value, option.value],
                  });
                }}
              />
              {option.content}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GroupCheckbox;
