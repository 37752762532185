import { ApiPath } from "enums";
import { Get } from "utils";

export const validateFuelCode = async ({ fuelCode, ...query }) => {
  const res = await Get(`${ApiPath.FuelCode}/code/${fuelCode}`, query);
  return res.data;
};

export const getFuelCode = async ({ fuelCode }) => {
  const res = await Get(`${ApiPath.FuelCode}/code/v2`, { fuelCode });
  return res.data;
};
