import React from "react";

import { BackLink, Intro } from "components/commons";
import { Path } from "enums";
import locale from "localization";
import { useApi, useMount, useRouter } from "hooks";

import styles from "./view-settlement-history.module.scss";

import ViewSettlementHistoryForm from "./view-settlement-history-form.module";
import ViewSettlementHistoryTable from "./view-settlement-history-table.module";
import { getSettlementHistoryById } from "apis";

const ViewSettlementHistory = () => {
  const {
    history,
    query: { id: settlementId },
  } = useRouter();

  const { result, request, loading } = useApi({
    api: getSettlementHistoryById,
    params: {
      id: settlementId,
    },
    pageError: true,
  });


  useMount(() => {
    if (!settlementId) {
      history.push(Path.SettlementHistory);
    } else {
      request();
    }
  });

  return (
    <>
      <div className={styles.container}>
        <BackLink text={locale.settlementHistory} path={Path.SettlementHistory} />
        <div
          style={{
            display: "flex",
          }}
        >
          <Intro
            style={{
              minWidth: "300px",
            }}
            title={locale.settlementDetails}
          />
        </div>
        <ViewSettlementHistoryForm
          settlementDetails={{ ...result } || null}
          settlementLoading={loading}
        />
        <ViewSettlementHistoryTable settlementDetails={{ ...result }} settlementId={settlementId} />
      </div>
    </>
  );
};

export default ViewSettlementHistory;
