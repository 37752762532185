import Validation from "utils/validation.utils";

export const initialState = (state = {}) => {
  const {
    mobileNumber = {
      value: "",
      disabled: false,
    },
  } = state;

  return {
    mobileNumber: {
      name: "mobileNumber",
      placeholder: "9xxxxxxxxx",
      maxLength: 10,
      minLength: 10,
      required: true,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.numeric(),
        Validation.mobileNumberv2(),
      ],
      ...mobileNumber,
    },
  };
};
