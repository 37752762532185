import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import classNames from "classnames";
import React from "react";
import styles from "./product.module.scss";

const Product = ({ children, grass, cheddar, salsa, deepBlue, purple }) => {
  return (
    <div className="min-70">
      <span className={styles.product}>
        <FiberManualRecordIcon
          className={classNames(styles.circle, {
            [`${styles.grass}`]: grass,
            [`${styles.cheddar}`]: cheddar,
            [`${styles.salsa}`]: salsa,
            [`${styles.deepBlue}`]: deepBlue,
            [`${styles.purple}`]: purple,
          })}
        />
        {children}
      </span>
    </div>
  );
};

export default Product;
