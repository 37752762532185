import { ActionButton, Image, Modal, Text, Title } from "../../../components/commons";
import styles from "./proceed-to-gas-modal.module.scss";
import { ProceedToGas } from "../../../images";
import locale from "../../../localization";
import React from "react";

const ZeroBalanceModal = ({ isBalance, ...modal }) => {
  return (
    <Modal {...modal}>
      <div className={styles.failed}>
        <Image src={ProceedToGas} className={styles.failedIcon} />
        <Title className={styles.title}>{isBalance ? locale.notEnoughBalance : locale.transactionLimitReached}</Title>
        <Text className={`${styles.text} ${styles.paddedText}`}>{isBalance ? locale.yourFleetDoesNotHaveEnoughBalance : locale.youAlreadyUsedUpYourFleetCardTransactions}</Text>
        <ActionButton
          center
          items={[
            {
              text: locale.gotIt,
              primary: true,
              onClick: () => {
                modal.close();
              },
            },
          ]}
        />
      </div>
    </Modal>
  );
};

export default ZeroBalanceModal;