import React from "react";
import { Document, Image, Page, PDFDownloadLink, StyleSheet, Text, View } from "@react-pdf/renderer";

import Button from "../button/button";
import Icon, { Icons } from "../icon/icon";
import { Size } from "../../../enums/size";
import QRCode from "qrcode"
import { qrDiesel, qrGas91, qrGas95, qrGas97, qrLubes } from "../../../images";

const styledQr = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    padding: 10
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  nameTagTable: {
    width: '100%',
  },
  nameTagTableRow: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  nameTagTableCell: {
    border: '1px solid #000000',
    padding: '8px',
    flex: 1,
    justifyContent: 'center',
    textAlign: 'center',
  },
  qrTable: {
    color: '#767171',
    marginTop: 16,
  },
  qrTableRow: {
    flexDirection: 'row',
  },
  qrTableCell: {
    border: '1px solid black',
    alignItems: 'center'
  },
  qrCode: {
    width: '1.56in',
    height: '1.56in',
  },
  qrLabel: {
    fontSize: 12,
  }
});
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    padding: 10
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  longText: {
    width: 400,
    position: 'absolute',
    fontSize: 24,
    justifyContent: 'center',
    bottom: 16,
    textAlign: 'center',
    fontWeight: "bold",
  },
  shortText: {
    width: 400,
    position: 'absolute',
    fontSize: 24,
    justifyContent: 'center',
    bottom: 28,
    textAlign: 'center',
    fontWeight: "bold",
  },
  longLubeText: {
    position: 'absolute',
    justifyContent: 'center',
    width: 400,
    fontSize: 24,
    bottom: 16,
    textAlign: 'center',
    fontWeight: "bold",
  },
  shortLubeText: {
    position: 'absolute',
    justifyContent: 'center',
    textAlign: 'center',
    width: 400,
    bottom: 32,
    fontSize: 24,
    fontWeight: "bold",
  },
  image: {
    position: 'absolute',
    height: 9 * 65,
    width: 7 * 65,
    margin: '0 auto',
  },
  qrCode: {
    width: '1.56in',
    height: '1.56in',
    marginTop: 160,
  },
  qrCodeLube: {
    width: '1.56in',
    height: '1.56in',
    marginTop: 160,
  },
});

const bg = {
  "diesel": qrDiesel,
  "gas91": qrGas91,
  "gas95": qrGas95,
  "gas97": qrGas97,
  "lubes": qrLubes,
}

function QrDocumentGenerator({qrDataList, description, useTemplate}) {
  const PdfDocumentData = () => {
    if (useTemplate) {

      const content = qrDataList.map((data, index) => {
        const isLubes = (data.qr.productType ?? data.qr.fuelType) === "lubes";
        let style = isLubes ? styles.longLubeText :styles.longText;
        if (description.length <= 29) {
          style = isLubes ? styles.shortLubeText : styles.shortText;
        }

        return (
          <Page key={index} size="A4" orientation="landscape">
            <View style={styles.section}>
              <Image style={styles.image} src={bg[data.qr.fuelType || data.qr.productType]} />
              <Image style={isLubes ? styles.qrCodeLube : styles.qrCode} src={QRCode.toDataURL(JSON.stringify(data.qr), { errorCorrectionLevel: "H" })} />
              <Text style={style}>{description}</Text>
            </View>
          </Page>
        )
      });
      return (
        <Document>
          <>
            {content}
          </>
        </Document>
      )
    } else {
      const page1 = qrDataList.slice(0, 3);
      const page2 = qrDataList.slice(3);
      const descriptionBuilder = () => {
        const tableRows = [];
        for (let i = 0; i < 5; i++) {
          tableRows.push(
            <View key={`description-row-${i}`} style={styledQr.nameTagTableRow}>
              <Text style={styledQr.nameTagTableCell}>{description}</Text>
              <Text style={styledQr.nameTagTableCell}>{description}</Text>
            </View>
          );
        }
        return (
          <View style={styledQr.nameTagTable}>
          {tableRows}
          </View>
        )
      }
      const qrRowBuilder = (qrData) => {
        const generateQrCodeString = (data) => {
          return QRCode.toDataURL(data, { errorCorrectionLevel: "H"  });
        };
        return (
          <View style={styledQr.qrTable}>
            {qrData.map((data, index) => (
              <View key={data + index + "qrdata"} style={styledQr.qrTableRow}>
                {[0, 1, 2].map((columnIndex) => {
                  const qrCodeData = generateQrCodeString(JSON.stringify(data.qr));
                  return (
                    <View key={columnIndex + "-qrRaw"} style={styledQr.qrTableCell}>
                      <Image style={styledQr.qrCode} src={qrCodeData} />
                      <Text style={styledQr.qrLabel}>{data.description}</Text>
                    </View>
                  );
                })}
              </View>
            ))}
          </View>
        );
      }
      return (
        <Document>
          {qrDataList.length > 3 ? (
            <>
              <Page size="A4" style={styledQr.page}>
                <View style={styledQr.section}>
                  <View>{descriptionBuilder()}</View>
                  <View>{qrRowBuilder(page1)}</View>
                </View>
              </Page>
              <Page size="A4" style={styledQr.page}>
                <View style={styledQr.section}>
                  <View>{qrRowBuilder(page2)}</View>
                </View>
              </Page>
            </>
          ) : (
            <Page size="A4" style={styledQr.page}>
              <View style={styledQr.section}>
                <View>{descriptionBuilder()}</View>
                <View>{qrRowBuilder(qrDataList)}</View>
              </View>
            </Page>
          )}
        </Document>
      );
    }
  };


  return (
    <div>
      <PDFDownloadLink document={<PdfDocumentData/>} fileName={`QR Code - ${description}.pdf`}>
        <Button primary={true} startIcon={<Icon icon={Icons.Upload} size={Size.Large} />}>
          Export
        </Button>
      </PDFDownloadLink>
    </div>
  );
}

export default QrDocumentGenerator