import locale from "localization";

export const redeemHistoryColumns = [
  { key: "vipTransactionId", text: locale.vipTransactionId, style: { minWidth: "8%" } },
  { key: "vipCardNumber", text: locale.vipCardNumber, style: { minWidth: "10%" } },
  { key: "mobileWithHash", text: locale.mobileWithHash, style: { minWidth: "6%" } },
  { key: "pointsRedeemed", text: locale.pointsRedeemed, style: { minWidth: "6%" } },
  {
    key: "dateOfTransaction",
    text: locale.dateOfTransaction,
    style: { minWidth: "12%" },
  },
  { key: "rewardsRedeemed", text: locale.rewardsRedeemed, style: { minWidth: "6%" } },
];
