import React from "react";

import { Intro, Text } from "components/commons";
import useDataTable from "hooks/useDataTable";
import locale from "localization";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import { getStationPayments, voidPayment } from "apis/payment.api";
import { payWithPesoFilterState } from "./pay-with-peso-filter.state";
import { mapDataToList, mapFilterToRequest } from "./pay-with-peso.mapper";
import { payWithPesoColumns } from "./pay-with-peso-columns";
import PayWithPesoFilter from "./pay-with-peso-filter";
import { useContext } from "react";
import { StationContext } from "contexts";
import { useApi, useExport, useModal } from "hooks";
import { generateStationReport } from "apis/generate-report.api";
import { SuccessModal } from "components/modals";
import { SettlementStatus } from "enums";
import { formatAmount } from "utils";
import { useCallback } from "react";
import { useState } from "react";
import VoidPaymentModal from "./void-payment-modal";
import ActionsHistoryModal from "./actions-history-modal";
import PayWithPesoReceiptGuideModal from "./pay-with-peso-receipt-guide-modal";

const PayWithPesoModule = () => {
  const voidPaymentModal = useModal();
  const actionsHistoryModal = useModal();
  const confirmModal = useModal();
  const receiptGuideModal = useModal();

  const { station } = useContext(StationContext);
  const [remarks, setRemarks] = useState("");
  const [value, setValue] = useState("");

  const { request: voidRequest, loading: voidingRequest } = useApi({
    api: voidPayment,
    pageError: false,
    modalError: true,
  });

  const handleVoidPayment = ({ paymentId, amount, rebate, settlementId, settlementStatus }) => {
    setRemarks("");
    voidPaymentModal.show({
      title: `${locale.voidTransaction}?`,
      description: (
        <Text align="center">
          <locale.Populate
            text={
              settlementId
                ? settlementStatus === SettlementStatus.ForProcessing
                  ? locale.voidingThisTransactionSettlementProcessing
                  : locale.voidingThisTransactionSettlementProcessed
                : locale.voidingThisPayment
            }
            items={[<b>{formatAmount(amount)}</b>, <b>{formatAmount(rebate)}</b>]}
          />
        </Text>
      ),
      label: locale.reasonRemarks,
      actionText: locale.voidTransaction,
      submit: (value) => {
        onVoidPaymentCb({
          paymentId,
          value,
          amount,
        });
      },
    });
  };

  const handleReceiptGuide = (payments) => {
    receiptGuideModal.show({
      redemptionItem: payments,
      title: `${locale.receiptGuide}`,
      description: "",
      label: `${locale.reason}/${locale.remarks}`,
    });
  };

  const handleActionsHistory = ({ voidedByEmail, status, voidedAt }) => {
    actionsHistoryModal.show({
      title: locale.actionsHistory,
      voidedByEmail,
      status,
      voidedAt,
    });
  };

  const onVoidPaymentCb = useCallback(
    async ({ paymentId, value, amount }) => {
      await voidRequest({ paymentId, remarks: value });
      voidPaymentModal.close();
      table.fetch({});

      confirmModal.show({
        title: locale.transactionVoided,
        content: (
          <locale.Populate
            text={locale.thisPaymentHasBeenVoided}
            items={[<b>{formatAmount(amount)}</b>]}
          />
        ),
        loading: voidingRequest,
        primary: {
          text: locale.gotIt,
          onClick: () => {
            confirmModal.close();
          },
        },
      });
      setRemarks("");
    },
    //eslint-disable-next-line
    [voidRequest, voidPaymentModal, confirmModal, voidingRequest]
  );

  const { filter, search, table } = useDataTable({
    api: { api: getStationPayments, params: { stationId: station.stationId } },
    filter: { initialState: payWithPesoFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "payments",
      mapper: (payments) =>
        mapDataToList({ payments, handleVoidPayment, handleActionsHistory, handleReceiptGuide }),
      columns: payWithPesoColumns,
    },
  });

  const exports = useExport({
    api: generateStationReport,
    hasModal: true,
    reportType: "payment",
    mappedFilterState: {
      ...filter?.mappedFilterState,
      searchKey: search?.searchKey,
      stationIds: station.stationId.toString(),
      redemptionStationId: station.stationId,
    },
  });

  return (
    <>
      <SuccessModal {...exports?.successModalComponent} />
      <div>
        <Intro title={locale.plcLocqpay} subtitle={locale.viewAndTrackScanToPayViaLocqpay} />
      </div>
      <PayWithPesoFilter {...filter} {...search} {...exports} />
      <DataTableV2 {...table} />
      <ActionsHistoryModal {...actionsHistoryModal} />
      <VoidPaymentModal
        {...voidPaymentModal}
        remarks={remarks}
        onChangeRemarks={(v) => {
          setRemarks(v);
        }}
        loading={voidingRequest}
        onSubmit={voidPaymentModal.submit}
        value={value}
        setValue={setValue}
      />

      <PayWithPesoReceiptGuideModal
        {...receiptGuideModal}
        remarks={remarks}
        value={value}
        setValue={setValue}
      />
    </>
  );
};

export default PayWithPesoModule;
